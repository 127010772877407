import React from 'react';
import FooterC from "../../../hoc/FooterC";
import hero2 from '../../../assets/hero5.jpg'
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import pimg from '../../../assets/calm-waters-clouds-cold-910310.jpg'
import FaqSection from "../home/Faq-section";
import HomeNavigation from "../../../components/HomeNavigation";
import AboutHome from "../home/HomeAbout";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";

const PurposePage = () => {
    useScrollToTop();
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
              <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`}}>

                  <div className=" width35 text-center mg2">
                      <h4 className="h1 light_color">Our Purpose</h4>
                  </div>
              </header>
            <div className="mgT8">
                <div className={'container grid'}>
                    <div className="grid-item2_4">
                        <h1 className="h2">Our Purpose</h1>
                        <p className="h5 mgT4 " style={{color:'rgba(113,113,113,1)'}}>G7 Capital has always been a purposeful business. We began with the aim of making a positive difference to both our clients and the country in which we started and since ’91 have expanded our scope and ambition globally.</p>
                        <p className="h5 mgT4 " style={{color:'rgba(113,113,113,1)'}}><span className="bold">‘Better firm. Better investing. Better world.’</span> is how we articulate our purpose. It guides our strategic decision making, culture and our daily endeavours.</p>
                    </div>
                    <div className="grid-item2_4">
                        <img src={pimg} className={'fluid_img'} alt={''}/>
                        </div>
                </div>
            </div>
           <AboutHome/>
            <div className="mgV4 container">
                <h4 className="h4">Frequently Asked Questions</h4>
                <p className="h6 mgT1">Have any Questions?</p>
            <FaqSection/>
            </div>
             <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default PurposePage;