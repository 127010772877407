import React, {useCallback} from 'react';
import axios from "../../helpers/axiosInstance";
import {useParams,useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../components/loader";
const ResetPasswordRe = () => {
    const [password,setPass] = React.useState('');
    const [retypePass,setRePass] = React.useState('');
    const [loading,setLoading] = React.useState(false);
    const params = useParams();

  const navigate = useNavigate();

  const verifyID =  useCallback(()=> {
        axios.get(`/auth/reset/verify/${params.id}`)
            .then(res=>{

            })
            .catch(err=>{
                navigate('/404Errorr');
            })
    },[params.id,navigate])
    React.useEffect(()=>{
      verifyID()
        return ()=>{}
    },[verifyID])


    function resetPush(e) {
        e.preventDefault();
       if (retypePass===password){
           let data = {
               password
           }
           setLoading(true)
           axios.post(`/auth/reset/verify/${params.id}`,data)
               .then(res=>{
                   console.log(res.data)
                   setLoading(false)
                   toast.success(res.data.msg)
                   setTimeout(()=>{
                       navigate('/login')
                   },1500)
               })
               .catch(err=>{
                   setLoading(false)
                   toast.error(err.response.data.msg)
               })
       }else{
           toast.error("Please Passwords should match")
       }
    }

       return (
           <div className='authBox'>
               {
                   loading?<Loader/>:null
               }
               <div className="authBox-card">
                  <ToastContainer/>
                   <form onSubmit={resetPush} className="form">
                       <div className="disp_flex align_center">
                           <h2 className='h4'>G7 <span className={'green'}>Capital</span></h2>
                       </div>
                       <h4 className="h4 mgT2">Reset Password</h4>
                       <div className="mgT4">

                           <div className="form-input-box">
                               <input  value={password} className='form-input' onChange={e=>setPass(e.target.value)} placeholder='Password'/>
                           </div>

                           <div className="form-input-box">
                               <input  value={retypePass} className='form-input' onChange={e=>setRePass(e.target.value)} placeholder='Re-type Password'/>
                           </div>

                       </div>
                       <button className="btn btn-blue mgT2" style={{width:"100%"}}>Reset</button>
                         </form>
               </div>
           </div>
       );
   // }
};

export default ResetPasswordRe;