import React from 'react';
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import ScrollToTop from "../../../hoc/scrollToTop";
import HomeNavigation from "../../../components/HomeNavigation";
import hero2 from "../../../assets/hero5.jpg";
import TopBar from "../../../components/topBar";

const PrivacyPolicy = () => {

    ScrollToTop();
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header  className='altHero' style={{backgroundImage:`linear-gradient(#0006,#0008),url(${hero2})`,height:"70vh"}}>

                <h5 className="h4 light_color mgT4">Privacy Policy</h5>
            </header>
            <div className="center-container mgT4 mgB4">
                <h4 className="h4">Privacy Policy</h4>
                <div className="greyCard mgT2">
                    <p className="h6">G7 Capital respects your privacy and is committed to protecting your personal data. This Privacy Policy aims to give you information on how we collect and process any personal data and how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you. i.e. information about a Client (as defined below) who is a natural person on the basis of which that Client can be identified (hereinafter the data) in accordance with the applicable data protection legislation and best practice. This version of Privacy Policy takes/took effect on 03 July, 2015. We strive to create the most secure infrastructure of any broker in United state and the world at large. In this privacy policy we would like to tell why you can trust us with your data and rest assured that your data is safe. It is important that you read this privacy policy together with any other privacy policy we may provide on specific occasions when we are collecting or processing your data about you so that you are fully aware of how and why we are using your data.</p>
                    <h6 className="h5 mgT2">Who we are</h6>
                    <p className="h6">G7 capital is not just an investment,crypto or financial institution but a philanthropic movement with its simple aim,to help people reach financial freedom. The idea was first begotten in the late 19’s by two realtors who bought and sold properties across Europe Due to some bad financial decisions ran out of cash and when taxes came were bankrupt and had to improvise,acting as a broker and getting paid depending on the profits made from the market

                    </p>
                    <p className="h6 mgT2">    “Risk is well managed when it’s other people’s money” As clients increased the business had a body but was not yet a company until some crypto whales and financial analysts bought the idea in 2008 And the name G7 capital was first mentioned. Our Aim G capital starting first in United States before spreading across Europe down to Africa(south Africa) aims at making a positive difference to both our clients and countries since 2008 have expanded our scope and ambition globally.</p>
                    <p className="h6 mgT2">
                        G7 Capital is a Leading Cryptocurrency (BTC, ETH, USDT,BNB,XRP) Mining/Investment and Exchange platform, offering a wide array of tools to invest in the cryptocurrency markets.
                        The mission of  G7 Capital is to make mining and earning cryptocurrrencies easy & possible for anyone, anywhere, and reduce dependency on traditional financial institutions.
                    </p>
                    <p className="h6 mgT2">
                        G7 Capital Limited is in control and is responsible for the data of her Client disclosed, to register for an Investment Account and/or to make use of any other services offered by the G7 Capital through the (hereinafter “Website”) (this term shall at all times include Website’s desktop and mobile versions). Our Company exercises control over the processing of data in undertakings affiliated to it and which belong to G7 Capital.
                    </p>
                    <p className="h6 mgT2">
                        This Privacy Policy addresses the privacy issues of the G7 Capital so when we mention “G7 Capital Company”, “Company”, “we”, “us” or “our” in this Privacy Policy, we are referring to the relevant company G7 Capital responsible for collecting and/or processing your data when you use the Trading Platform through the Website, either for a Trading Account or more(or their mobile/desktop versions) (hereinafter the service(s)). In G7 Capital, we respect your privacy and therefore all companies within the G7 Capital is committed to protect your data, which it collects, uses and/or has access to.
                    </p>
                    <p className="h6 mgT2">The Company takes measures to implement advanced data protection policies and procedures and to update them from time to time for the purpose of safeguarding its Client’s data and the Client’s account. Your data is protected by the legal, administrative and technical measures that we take to ensure the privacy, integrity and accessibility of data. To prevent security incidents with your data, we use a mixed organizational and technical approach based on the results of annual risk assessment.</p>
                    <p className="h6 mgT2">The Company shall not divulge any private information of its Clients and former Clients unless the Client approves in writing such disclosure or unless such disclosure is required under applicable law or is required in order to verify the Client’s identity or it is required for G7 Capital to perform its contractual obligations under any agreement concluded with the Client. The Clients’ information is processed only by the employees of the Company and/or employees of the G7 Capital and/or trusted third parties which provide specific support services, dealing with the specific Client’s Accounts and necessary for the provision of our services. All such information shall be stored on electronic and physical storage media according to applicable law here in the United state of America.</p>
                </div>
                <div className="h5 mgT4">Data Usage</div>
                <div className="mgT2 greyCard">
                    <h6 className="h6">We may collect, use, store and transfer different kinds of data about you which we have grouped together as follows:</h6>
                    <ul>
                        <li className="h6">Identity Data includes first name, last name, and patronymic (if available)’s number, and copy of photo.</li>
                        <li className="h6">Contact Data includes billing address, email address and telephone numbers.
                           </li>
                        <li className="h6"> Financial Data includes bitcoin addresss or any other related address.</li>
                        <li className="h6">
                            Transaction Data includes details about the transactions performed by you, details about payments, withdrawals, exchanges, trading history, profit, balance, deposited and withdrawal amount methods, and any other details in relation to the services you have made use of through our Website.</li>
                        <li className="h6">
                            Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in type and versions, operating system and platform, and other technologies on the devices you use to access the Website and use of cookies stored on your device.
                        </li>
                        <li className="h6">
                            Profile Data includes your Client’s account details, username and password, transactions made by you, your interests, preferences, feedback and/or information received through your interaction with us within the course of providing our services and survey responses.</li>
                        <li className="h6">
                            Usage Data includes information about how you use the Website, products and services, registration date, account category, trading cluster, number of complaints, number of requests filed and IP history.</li>
                        <li className="h6">Marketing and Communication Data includes your preferences in receiving marketing from us and your communication preferences.
                        </li>
                        <li className="h6">
                            Special Categories of Data/ ‘Sensitive’ Data includes details about your religious belief and/or criminal convictions and offences.</li>
                        <li className="h6">  Conformity Data includes details about your education, employment status, trading experience, self-assessment test.
                           </li>
                        <li className="h6"> Banking Data includes details of number of payment wallets and/or information of your bank card, including information of the issuing bank, card number, cardholder name, card expiration date, payment system, card validation code (CVV2 / CVC2), and photos of both sides of the bank card.</li>
                        <li className="h6">
                            Data in KYC (Know your customer) includes identity document information, including copies of recent dated Utility Bills, Identity Card, Passport, and/or Driver’s License.</li>
                        <li className="h6">   Economic Profile Data includes details on annual income, net profit, expected annual amount of investment, sources of funds.</li>
                        <li className="h6"> Location Data includes details on your actual location when interacting with our Website (for example, a set of parameters that determine regional settings of your interface, namely residency country, time zone, and the interface language).</li>
                    </ul>

                </div>
            </div>
            <FooterC/>
            <InfiniteTestimony/>

        </div>
    );
};

export default PrivacyPolicy;