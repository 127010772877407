import React, {useState} from 'react';
import DashboardLayout from "../../../layout/dashboardLayout";
import Modal from "../../../components/Modal";

const ManageCards = () => {
    const [showModal,setShowModal ] = useState(false);
    return (

        <DashboardLayout>
            <h5 className="h5">Manage Cards</h5>
            <div className="grid mgT2">
                <div className="grid-item1_4 altCard">
                    <p className="h6 light_color">Title</p>
                    <h6 className="h5 mgT2 light_color">52742 3573 53573</h6>
                    <p className="h7 mgT4 light_color"><span>Expiry </span>02/2022</p>
                </div>
            </div>

            <hr className="hr mgT4"/>

            <div className="mgT4">
                {
                    showModal?<Modal closeModal={setShowModal}>
                        <h5 className="h5 mgB2">New Card</h5>
                        <label className={'h7 grey-text'}>PAN</label>
                        <div className="form-input-box">
                            <input type="text" placeholder={'PAN'}/>
                        </div>
                        <label className={'h7 grey-text'}>Expiry</label>
                        <div className="form-input-box">
                            <input type="text" placeholder={'Expiry'}/>
                        </div>
                        <button style={{width:"100%"}} className="btn btn-blue mgT2">Add</button>
                    </Modal>:null
                }
                <button className="btn btn-blue" onClick={()=>setShowModal(true)}>Add New Card</button>
            </div>
        </DashboardLayout>
    );
};

export default ManageCards;