import React, {useState,useContext} from 'react';
import DashboardLayout from "../../../layout/dashboardLayout";
import {CurrencyCards} from "../clientHome";
import AxiosInstance from "../../../helpers/axiosInstance";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../../components/loader";
import {AuthContext} from "../../../hoc/AuthContext";

const ExchangeCoins = () => {
    const [toCrypto,setToCrypto] = useState('');
    const [fromCrypto,setFromCrypto] = useState('');
    const [amount,setAmount] = useState('');
    const [loading,setLoading] = useState(false);
    const userInfo = useContext(AuthContext);
    const user = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
    function exchangeCoins(e) {
        e.preventDefault();
        setLoading(true)
        AxiosInstance.post('/p/swap',{
            meta: JSON.stringify({fromCrypto,
                toCrypto,}),
            amount,
            owner: user._id,
            walletId:user.wallet
        },{
            headers:{
                authorization: localStorage.getItem('G7_TK')
            }})
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg)
            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })

    }
    return (

        <DashboardLayout>
            <h5 className="h5">Exchange Coins</h5>
            <CurrencyCards/>
            <ToastContainer/>
            {
                loading?<Loader/>:null
            }
            <hr className="hr mgT4"/>
            <div className="mgT4">
               <div className="grid">
                   <form onSubmit={exchangeCoins} className="grid-item2_4">
                       <label className={'h7 grey-text'}>Exchange</label>
                       <div className="form-input-box">
                           <select  name="" id="" value={fromCrypto} onChange={e=>setFromCrypto(e.target.value)}>
                               <option>BTC</option>
                               <option>ETH</option>
                               <option>XRP</option>
                               <option>USDT</option>
                               <option>BNB</option>
                           </select>
                       </div>
                       <label className={'h7 grey-text'}>Exchange For</label>
                       <div className="form-input-box">
                           <select  name="" id="" value={toCrypto} onChange={e=>setToCrypto(e.target.value)}>
                               <option>BTC</option>
                               <option>ETH</option>
                               <option>XRP</option>
                               <option>USDT</option>
                               <option>BNB</option>
                           </select>
                       </div>
                       <h6 className="h7 mgV1">Exchange charge is ${
                          amount.length>0?parseFloat(amount) - 1:1
                       } </h6>
                       <label className={'h7 grey-text'}>Amount</label>
                       <div className="form-input-box">
                           <input type="text" value={amount} onChange={e=>setAmount(e.target.value)}  placeholder={'Amount'}/>
                       </div>
                       <button className="btn btn-green mgT2" style={{width:'100%'}}>Swap</button>
                   </form>
               </div>
            </div>
        </DashboardLayout>
    );
};

export default ExchangeCoins;