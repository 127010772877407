import React, {useCallback, useEffect, useState} from 'react';
import AdminLayout from "../../layout/AdminLayout";
import axiosInstance from "../../helpers/axiosInstance";
import {AuthContext} from "../../hoc/AuthContext";
import {toast, ToastContainer} from "react-toastify";
import AxiosInstance from "../../helpers/axiosInstance";
import Modal from "../../components/Modal";
import Loader from "../../components/loader";

const WalletAddress = () => {
    const [walletAddress,setWallets] = useState([]);
    const [loading,setLoading] = useState(false)
    const [delModal,setDel] = useState(false)
    const [updateModal,setUpdate] = useState(false)
    const [showWalletMod,setShowWalletMod] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [wallAdd,setWallAdd] = useState('');
    const [wallType,setWallType] = useState('BTC');
    const [current,setCurrent] = useState({
        currency:"",
        address:""
    });

    const userInfo = React.useContext(AuthContext);

    const getWalletAddresses = useCallback(()=>{
         axiosInstance.get('/sup/acc/all/wallets',{
             headers:{
                 authorization: userInfo.token
             }
         })
             .then(res=>{
                 setWallets(res.data.wallets)
             })
             .catch(err=>{
                 toast.error(err.response.data.msg)
             })
    },[userInfo.token])

    function createNewAddress() {
        setLoading(true)
        AxiosInstance.post('/sup/misc/create/walletAddress',{wallAdd,wallType})
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setWallAdd('');
                setRefresh(!refresh)
                setTimeout(()=>{
                    setShowWalletMod(false)
                },1000)


            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data)
            })
    }
    function deleteAddress() {
        setLoading(true)
        AxiosInstance.delete(`/sup/misc/walletAddress/${current?._id}`,)
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
                setTimeout(()=>{
                    setDel(false)
                },1000)


            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data)
            })
    }
    function updateAddress() {
        setLoading(true)
        AxiosInstance.post(`/sup/misc/update/wall/`,current,)
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
                setTimeout(()=>{
                    setUpdate(false)
                },1000)


            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data)
            })
    }
    useEffect(()=>{
        getWalletAddresses();
        return ()=>{}
    },[getWalletAddresses,refresh])
    return (
        <AdminLayout>
            <h5 className="h5">All wallet addresses</h5>
            <button className="btn btn-blue mgT2" onClick={()=>setShowWalletMod(true)}>Add Wallet Address</button>
            {
                loading?<Loader/>:null
            }
            {
                delModal?
                    <Modal closeModal={setDel}>
                        <h5 className="h5">Are you sure you want to delete?</h5>
                        <button className="btn btn-red mgT2" onClick={deleteAddress} >Delete</button>
                    </Modal>
                    :null
            }
            {
                updateModal?
                    <Modal closeModal={setUpdate}>
                        <h5 className="h5">Update Wallet</h5>
                        <div className="mgT2">
                            <div className="form-input-box">
                                <input type="text" placeholder={'address'} value={current.address||""} onChange={e=>{
                                    let curr_ = {...current};
                                    curr_.address = e.target.value;
                                    console.log(e.target.value,curr_)
                                    setCurrent(curr_);
                                }
                                }/>
                            </div>
                            <div className="form-input-box">
                                <select value={current.currency} onChange={e=>{
                                   let curr_ = {...current};
                                   curr_.currency = e.target.value;
                                    console.log(e.target.value,curr_)
                                   setCurrent(curr_);
                                }
                                }>
                                    <option >BTC</option>
                                    <option >ETH</option>
                                    <option >XRP</option>
                                    <option >USDT</option>
                                </select>
                            </div>
                        </div>
                        <button className="btn btn-blue mgT2" onClick={updateAddress} >Update</button>
                    </Modal>
                    :null
            }
            <ToastContainer/>
            <div className="grid">
             <div className="grid-item2_4">
                 {
                     showWalletMod?<Modal closeModal={setShowWalletMod}>

                         <h5 className="h5">Create new Wallet address</h5>

                         <label  className="h7 grey-text">Wallet Address</label>
                         <div className="form-input-box">
                             <input value={wallAdd} onChange={e=>setWallAdd(e.target.value)} type="text" placeholder={'wallet address'}/>
                         </div>
                         <label  className="h7 grey-text">Wallet Type</label>
                         <div className="form-input-box">
                             <select value={wallType} onChange={e=>setWallType(e.target.value)}>
                                 <option >BTC</option>
                                 <option >ETH</option>
                                 <option >XRP</option>
                                 <option >USDT</option>
                             </select>
                         </div>
                         <button onClick={createNewAddress} className="btn btn-blue">Add new</button>
                     </Modal>:null
                 }
                 {
                     walletAddress.length>0?
                         walletAddress.map(e=><div key={e._id} className={'walletCard'}>
                         <div className="grid-item2_4">
                             <p className={'h7 grey-text'}>Currency</p>
                             <h6 className={'h6'}>{e.currency}</h6>
                         </div>
                            <div className="grid-item2_4">
                                <p className={'h7 grey-text'}>address</p>
                                <h6 className={'h6'}>{e.address}</h6>
                            </div>
                             <div className="display_flex mgL2">
                                 <button className="btn btn-blue" onClick={()=>{
                                     setCurrent(e);
                                     setUpdate(true)
                                 }}>Edit</button>
                                 <button className="btn btn-red mgL2" onClick={()=>{
                                     setCurrent(e);
                                     setDel(true)
                                 }}>Delete</button>
                             </div>
                         </div>)
                         :<div className={'empty h6'}>No Wallet addresses available</div>
                 }

             </div>
         </div>
        </AdminLayout>
    );
};

export default WalletAddress;