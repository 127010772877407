import React from 'react';
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import ScrollToTop from "../../../hoc/scrollToTop";
import hero2 from "../../../assets/hero2.jpg";
import osha from "../../../assets/OSHA.png";
import lost from "../../../assets/Risk.png";
import cost from "../../../assets/Cost-Savings.png";
import {Link} from "react-router-dom";
import FrequentQ from "../home/FrequentQ";
import TopBar from "../../../components/topBar";
import HomeNavigation from "../../../components/HomeNavigation";
// import HomeNavigation from "../../../components/HomeNavigation";

const Insurance = () => {
    ScrollToTop();
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`,height:"50vh"}}>

                <p className="h7 green">G7 Capital</p>
                <h5 className="h3 light_color">Insurance</h5>
            </header>
            <div className="container">
                <div className="grid">  
                    <div className="grid-item2_4">
                        <h2 className="h5">G7 <span className="green">Capital</span></h2>
                        <p className="h2">Insurance</p>
                        <button className="btn btn-blue mgT4">Contact an Agent </button>
                    </div>
                    <div className="grid-item2_4 greyCard">
                        <h5 className="h5">IMPROVE SAFETY, ENHANCE PROFITABILITY</h5>
                        <p className="h6 mgT2">Conducting a thorough evaluation of business operations to identify areas of concern and enhance your safety culture is one way to help your business operate at its best. Take a proactive approach to understanding your loss potential with our in-house loss control services where our certified safety consultant will assist in creating customized plans to:</p>
                        <ul className="mgT2">
                            <li className="h6">Minimize risk
                             </li>
                            <li className="h6">Improve profitability</li>
                            <li className="h6">   Maintain compliance</li>
                        </ul>
                    </div>
                    
                    <div className=" grid mgT4">
                        <div className="grid-item1_3 outlined-card centerText">
                            <img src={cost} alt={"cost savings"}/>
                           <h6 className="h5"> COST SAVINGS</h6>
                           <p className="h6 mgT2"> Prevent accidents from occurring and minimize claim costs associated with increased premiums, restricted duty, lost work days, and equipment or property damage.</p>
                        </div>
                        <div className="grid-item1_3 outlined-card centerText">
                            <img src={lost} alt={"cost savings"}/>
                           <h6 className="h5"> LOST REDUCTION
                              </h6>
                           <p className="h6 mgT2"> Serves as a Stop Loss for invested capital in declined trade. Security on capital for investors.</p>
                        </div>
                        <div className="grid-item1_3 outlined-card centerText">
                            <img src={osha} alt={"cost savings"}/>
                           <h6 className="h5"> OSHA COMPLIANCE
                               </h6>
                           <p className="h6 mgT2"> Receive notification of any changes or updates to safety standards and regulations that pertain to your business.</p>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div>
                        <div className="grid mgT4">
                            <div className="grid-item2_4">
                                <div className="frequently-section ">
                                    <FrequentQ question='How do I get started? '
                                               answer='Navigate to our registration page and provide us with  accurate information about you, our system will automatically send you an email to verify your emails viability as it’s the main means of contacting you about transaction. Follow the link to complete your
                                      registration and log in after wards. Be sure to check if our email arrived in your junk box.'/>
                                    <FrequentQ question='How do I open a new contract? ' answer='In your private office, click on the open new contract button, copy our central portfolios wallet address, proceed to your wallet and deposit $50 worth of bitcoin, copy the transaction hash and paste it in the proof of payment box provided. We will verify your payment and confirm your account within 2 hours to enable you open a new contract.'/>

                                    <FrequentQ question='How much initial capital do I need to get started? ' answer='At cedefima, only a one time initial capital of $150 BTC is all you will ever need to acquire as much crypto as you wish.  Get started and we will secure and grow it for you.'/>





                                </div>
                            </div>
                            <div className="grid-item2_4">
                                <div className="frequently-section  ">
                                    <FrequentQ question='What’s my insurance trading here? '
                                               answer='Your initial capital is insured in three indemnification stages that utilize a stable central portfolio to protect your trading capital till you have successfully transacted enough to replace your capital with your return on investment.'/>
                                    <FrequentQ question='Does my insurance expire? '
                                               answer='Yes, once we have successfully protected a new client initial capital to the tertiary stage of the insurance program, his initial investors’ protection protocol expires. This allows us to reduce our insurance liabilities and deduct our commission.   '/>
                                    <FrequentQ question='Won’t I incur loss afterwards? ' answer='No you won’t, our insurance program is an extra layer of protection for our new investors’ funds, however our investors’ compliance policy in the form of a non refundable upfront contract margin fee ensures that all contracts are dully honored.'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="greyCard container">
                <h6 className="h7">Insurance products are offered through G7 Capital and are obligations of the issuing insurance companies, are not obligations or deposits of or guaranteed by any bank and are not insured by the FDIC or any other agency of the United States. The purchase of insurance products are not a condition to any bank loan, product or service. G7 Capital Insurance, LLC. is a licensed subsidiary of G7 Capital Financial Corporation.</h6>
            </div>
            <div className="altCard">
                <div className="container">
                    <div className="centerText"><button className="btn-outline btn-outline-green">Contact an agent</button></div>
                    <div className="grid- mgT4">
                        <Link to={'/privacy_policy'} className={'no-deco link'}>Privacy Policy</Link>
                        <Link to={'/terms'} className={'no-deco link'}>Terms & Conditions</Link>
                        <span className={'light_color h7'}>ABA Routing Number: 031913438 NMLS# 415882
Member FDIC  Equal Housing, Opportunity, and SBA Preferred Lender</span>
                    </div>
                    <p className="h7 light_color mgT2">Copyright © G7capital 2022 </p>
                </div>
            </div>
            <InfiniteTestimony/>

        </div>
    );
};

export default Insurance;