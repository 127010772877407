import React from 'react';
import {Link} from "react-router-dom";
import TopBar from "./topBar";
const Navigation = () => {
    const [scrollHeight,setHeight] = React.useState(0);
    const [scrollPosY,setScrollY] = React.useState(0);
    // const [dropdown,setDropdown] = React.useState(false);
    const [currentColor,setCurrentColor] = React.useState('transparent');
    const topBar = React.useRef(null);
    React.useEffect(()=>{
        setHeight(topBar.current.scrollHeight);
       function scrollChange (){
            setScrollY(window.scrollY);
            if (scrollPosY >= scrollHeight) {
                setCurrentColor('#1b1b1b');
            }
            else {
                setCurrentColor('transparent')
            }
        }
        window.addEventListener('scroll',scrollChange);
     return ()=>{
         window.removeEventListener('scroll',scrollChange)
     }
    },[setHeight,setCurrentColor,scrollPosY,scrollHeight,currentColor])

    return (
       <>
           {/*<TopBar/>*/}
           <nav ref={topBar} style={{backgroundColor: currentColor}} className='navigation'>
               <figure >
                   <Link to='/' className='no-deco disp_flex align_center'>
                       <h4 className="h4 light_color">G7 <span className={'green'}>CAPITAL</span></h4>
                   </Link>

               </figure>
               <input hidden type="checkbox" id="menu"/>
               <label className="menu" htmlFor="menu">
                   <span className="menu-bars">&nbsp;</span>
               </label>
               <ul className="nav-list">

                   <li className="nav-list-item" >
                       <Link to='/insurance' className='nav-list-item-link'>
                           Insurance
                       </Link>



                   </li>
                   <li className="nav-list-item">
                       <Link className='nav-list-item-link' to='/blog'>
                           Blog
                       </Link>
                   </li>
                   <li className="nav-list-item">
                       <Link className='nav-list-item-link' to='/faqs'>
                           FAQs
                       </Link>
                   </li>
                   <li className="nav-list-item">
                       <Link className='nav-list-item-link' to='/support'>
                           Get Support
                       </Link>
                   </li>
               </ul>

           </nav>
       </>
    );
};

export default React.memo(Navigation);