import React from 'react';

const Card = (props) => {
    return (
        <div className={`card ${props.className?props.className:null}` } >
            {props.children}
        </div>
    );
};

export default Card;