import React from 'react';
import HomeNavigation from "../../../components/HomeNavigation";
import FooterC from "../../../hoc/FooterC";
import btc from "../../../assets/tk1.jpg";
import btc2 from "../../../assets/tk2.jpg";
import btc3 from "../../../assets/tk00.jpg";
import hero2 from "../../../assets/g72.jpg";
import {Link} from "react-router-dom";

const TokenizedInvestments = () => {
    return (
        <div>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`,height:"40vh"}} >

                <div className=" width35 text-center mg2">
                    <h4 className="h3 light_color">Tokenized Investments Programs</h4>
                </div>
            </header>
            <div className="container grid">
                <div className="grid-item2_4">
                    <h5 className="h3">ICO PRESALES AND DEGEN</h5>
                    <h6 className="h6 mgT2">
                        Investing In Enriching Initial Coin Offerings</h6>
                    <p className="h6 mgT2">
                        One of our aims is to identify new worthy and beneficial cryptocurrencies and invest in them during their Initial Coin Offering period. Tying-up with various cryptocurrency exchanges gives us the benefits of investing in newly introduced coins.
                    </p>
                    <Link to={'/register'} className={'btn btn-blue mgT2'}>Sign up</Link>
                </div>
                <div className="grid-item2_4">
                    <img src={btc} alt="" className={'fluid_img'}/>
                </div>
            </div>
            <div className="container  greyCard">
               <div className="grid">
                   <div className="grid-item1_4">
                       <h5 className="h4">Verified ICOs Fully Researched</h5>
                       <p className="h6 mgT2">
                           Conventional research and analysis of a particular ICO's whitepaper
                       </p>
                       <h5 className="h4 mgT4">
                           Designed for business teams like yours
                          </h5>
                       <p className="h6 mgT2">
                           Here at G7CAPITAL we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.


                           Opportunity to yield massive returns G7CAPITAL investors.


                           Invested ICO coins are stored in G7 ‘s cold wallet for more security.


                           G7's ERC20 compatible cold wallets allows ICO companies to store their token securely.
                       </p>
                   </div>
                   <div className="grid-item1_4">
                       <h5 className="h4">   A World of ICOs</h5>
                       <p className="h6 mgT2">

                           We at G7CAPITAL use a popular fundraising method used primarily by startups wishing to offer products and services, only related to the cryptocurrency and blockchain space.
                           <span className="greenText">  Private ICOs 90%,
                           Public ICOs 10%</span>.



                       </p>
                       <p className="h6 mgT1">  ICOs have made many investors into millionaires. In 2017, there were 435 successful ICOs, each raising an average of $12.7 million. So, the total amount raised for 2017 was $5.6 billion, with the 10 largest projects raising 25% of this total. </p>
                       <p className="h6 mgT1">Furthermore, tokens purchased in ICOs returned an average of 12.8 times on the initial investment in dollar terms. Therefore, we at G7CAPITAL also invest sliced parts from company reserves so as to get the maximum profits to distribute to our investors.</p>
                   </div>
                   <div className="grid-item1_4">
                       <h5 className="h4"> Enriching Initial Coin Offerings</h5>
                       <p className="h6 mgT2">
                           Thanks to our dedicated team who are responsible for the research and analysis of token’s whitepapers to determine its future growth at a certain point of time. G7CAPITAL invests only in coins or tokens that have some utility in using the product or service a particular company is offering.
                       </p>
                   </div>
                   <div className="grid-item1_4">
                       <h5 className="h4">ECO-FRIENDLY</h5>
                       <p className="h6 mgT2">
                           G7 CAPITAL invests only on ICOs that have utility for a software service or product offered.
                       </p>
                   </div>
               </div>
            </div>
            <div className="container ">
                <h5 className="h3">
                    DEFI/NFT/WEB 3.0
                </h5>
                <div className="grid mgT2">
                    <div className="grid-item2_4">
                        <h6 className="h5">Bringing Value to Millions of Investors Worldwide

                        </h6>
                        <p className="h6 mgT1">  Transparency and Knowledge you need to make an impact in a decentralized ecosystem.</p>
                        <img src={btc2} alt="" className="fluid_img mgT2"/>

                        <h6 className="h5 mgT2">
                            Delegators contribute to the security
                        </h6>
                        <p className="h6 mgT1">

                            The right to validate and add blocks, hence, to secure the validity of the blockchain, is attached to every PoS token, Token holders who do not want to act as a validator, but still want to contribute to the blockchain’s security and earn rewards, can delegate the rights contained in their tokens to a validator of their choice. These token holders are called delegators and can be considered as a validators customer</p>
                    </div>
                    <div className="grid-item3_4 grid">
                        <div className="grid-item1_3">
                            <h6 className="h5">   Bringing Value to Millions of Investors Worldwide

                            </h6>
                            <p className="h6 mgT1"> Transparency and Knowledge you need to make an impact in a decentralized ecosystem.</p>

                            <h6 className="h5 mgT1">   Adoption, growth and long term success of the Web 3 ecosystem.</h6>
                            <p className="h6 mgT1">
                                G7CAPITAL has been providing secure blockchain investment across leading Proof-of-Stake and Proof-of-Work protocols. The company has secured more than $350m USD worth in assets under its secure validation infrastructure for both retail and institutional clients</p>
                            <h6 className="h5 mgT1">
                                Validators earn staking rewards & fees
                            </h6>
                            <p className="h6 mgT1">
                                For their work, validators are rewarded in the form of block, rewards & transaction fees, In order to participate in securing the network, and to be paid for this service, validators are required to lock up collateral “stake* which can be forfeited (i.e. “slashed”) programmatically if their actions break the programmatic rules that define the blockchain protocol which they secure.</p>

                        </div>
                        <div className="grid-item1_3">
                            <h6 className="h5">    Validators run the infrastructure
                            </h6>
                            <p className="h6 mgT1">
                                Validators, like G7, are special nodes responsible for the provision of infrastructure as well as proposing and validating new blocks and appending them to the blockchain. Hence, together with other validators, they ensure the blockchains’ security by monitoring its accuracy, establishing validity, guaranteeing availability, and provisioning the infrastructure for it to run on.</p>



                            <h6 className="h5 mgT1">
                                Delegators earn a return on their assets
                            </h6>
                            <p className="h6 mgT1">
                                The amount of rewards a delegator can earn through the services of a validator is a function of the validators’ total rewards and the ratio between the size of the holder’s own stake and the total stake of the validator.</p>
                        </div>
                        <div className="grid-item1_3">

                            <h6 className="h5">
                                Trusted
                            </h6>
                            <p className="h6 mgT1">
                                We are one of the few proof-of-stake, DEFI, NFT infrastructure to be backed by a government</p>
                            <h6 className="h5">
                                Always Devoted
                            </h6>
                            <p className="h6 mgT1">

                                G7CAPITAL operates at a 99.982% Guaranteed Availability. Our Datacenter has extensive fire prevention and protection equipment and procedures.</p>

                            <h6 className="h5">

                                Always Secure
                            </h6>
                            <p className="h6 mgT1">

                                We operate at optimum levels of security and enterprise grade protection against layer 3 & 4 DDoS Attacks.
                            </p>

                            <h6 className="h5">

                                Team
                            </h6>
                            <p className="h6 mgT1">


                                We have diverse cultural and national backgrounds. Our Diversity is our biggest strength. It provides us with different prespectives.
                                Learn About DEFI's and NFT's
                            </p>


                        </div>
                    </div>
                </div>
            </div>
            <div className="greyCard container">
                <div className="grid">
                    <div className="grid-item1_4">
                        <h6 className="h5">What is DeFi?</h6>
                        <p className="h6 mgT1"> DeFi refers to Decentralized Finance – the ecosystem of blockchain-based, digital financial tools. DeFi also refers to a variety of peer-to-peer financial services that permit crypto trading, loans, interest accounts, algorithm-driven cross platform trading and other services. DeFi empowers everyday investors to access new asset types, earn yield, reduce their fees, improve their rates, and all around take more control over crafting their financial future.</p>


                    </div>
                    <div className="grid-item1_4">

                        <h6 className="h5 mgT2">  NFT</h6>
                        <p className="h6 mgT1">
                            Non Fungible Token The global market for non-fungible tokens hits $22bn in 2021 as the craze for collections such as Crypto Punks, Bored Ape Yacht Club turned digital images into major investment assets.</p>
                    </div>
                    <div className="grid-item1_4">


                        <h6 className="h5 mgT2">  Metaverse Play to Earn Games</h6>
                        <p className="h6 mgT1">  NFTs are a type of cryptocurrency that can be used to purchase and exchange in game items in blockchain-based games. The NFT-based video games could very well be the future of NFT technology. Gamers are already known to spend fortunes on virtual items. World of Warcraft gold, Call of Duty loot boxes or Counter Strike skins–all of these markets are already worth billions. We participate in these games to generate tokens and unlimited rewards.</p>
                    </div>
                    <div className="grid-item1_4">

                        <h6 className="h5 mgT2">  What is Staking?</h6>
                        <p className="h6 mgT1">  Staking is a way of earning interest on your cryptocurrency by depositing it for a fixed period of time. Staking works in a similar way to interest accounts with traditional banks.
                            Traditional banks pay interest because the bank uses your funds for things like loans and other investments. In staking, your cryptocurrency is put to use as well. The term staking refers to the Proof of Stake or “PoS” protocol, in which deposited coins are used to verify transactions on the blockchain.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="grid ">
                    <div className="grid-item2_4">
                        <h6 className="h4 mgT2">   NFTs</h6>
                        <p className="h6 mgT1">  G7CAPITAL have got a special team of artists, developers, NFT experts, investors and traders who have been constantly exploring and leveraging the NFT ecosystem to the advantage of all our investors from the onset and have an outstanding record of profiting immensely, in our NFT portfolios are Cryptopunks, BAYC among other notable NFTs, we have all it takes to harness the ecosystem. In NFT investing and trading we focus on quality over quantity.
                        </p>
                        <p className="h6 mgT1">   Another way we generate returns is by STAKING NFTs.
                            One of the benefits of the similarities between NFTs and decentralised finance (DeFi) protocols is the possibility of staking NFTs. Staking refers to the process of depositing, of locking away" digital assets into a Defi protocol smart contract to generate a yield.
                        </p>
                        <p className="h6 mgT1">     We generate returns through NFT ROYALTIES.
                            The Underlying technology powering NFTs allows our creators and Artists to set smart contracts that impose royalty fees whenever our NFTs change hands on the secondary market.
                        </p>
                        <p className="h6 mgT1">      We generate returns by TRADING NFTs.
                            Apart from staking NFTs, we also trade them. We treat NFTs like stocks, purchasing and selling them to make money. When it comes to NFT trading, knowing when to sell is crucial.
                        </p>
                        <p className="h6 mgT1">         We also generate returns through our NFT & METAVERSE PLAY TO EARN GAMES.
                            NFTs are type of cryptocurrency that can be used to purchase and exchange in-game items in blockchain-based games.
                        </p>
                        <p className="h6 mgT1">  The NFT-based video games could very well be the future of NFT technology. Gamers are already known to spend fortunes on virtual items. World of Warcraft gold, Call of Duty loot boxes or counter strike skins- all of these markets are already worth billions. We participate in these games to generate tokens and unlimited rewards.
                        </p>
                        <p className="h6 mgT1">
                            In our NFT METAVERSE PLAY TO EARN portfolio are 1. Axie Infinity • 2. AlienWorlds • 3. Battle Infinity • 4. Gods Unchained • 5. Splinterlands • 6. Farmers World • 7. Plants Vs Undead • 8. Gala Games, among others, we have a special team of chattered professionals with great expertise in harnessing this ecosystem to generate insane yields on a daily basis for our customers.
                            With the expertise of our team of gamers in the NFT ecosystem, we are proud about our results so far, generating up to 75% returns for our customers monthly. This gives our customers the average to earn a daily reward from 1.2%-2.5% on their principal for a period of 30 days.

                        </p>
                    </div>
                    <div className="grid-item2_4">
                        <img src={btc3} alt="" className={'fluid_img'}/>
                    </div>
                </div>
                <div className="greyCard_ mgT4">
                    <h4 className="h3"> How To Invest In Our NFTs & Metaverse Play To Earn/Web3.0:</h4>
                    <p className="h6 mgT1">A Step-by-Step Guide</p>
                    <p className="h7 mgT1">
                        At G7CAPITAL, we also leverage the NFT ecosystem to generate yield for our investors while eliminating the hard part, which is finding the right NFT to buy into. In NFT investing, we believe in quality over quantity</p>

                    <ol className={'list'}>
                        <li className={'h6'}>Create a Wallet
                            <p className="h6"> Your crypto wallet is a digital purse where you’ll store the crypto that you’ll use to participate in NFT investing. Choose any wallet you like. Trust wallet, Exodus and MetaMask is a popular choice and we’d also recommend those.</p>
                           </li>
                        <li className={'h6'}>Purchase Crypto
                            <p className="h6"> Just like you need cash to invest in real estate, you need crypto to participate in NFT, Web 3.0 and Metaverse P2E games investing. After purchase send the crypto to your G7CAPITAL wallet or purchase cryptocurrency directly from the platform.</p>
                           </li>
                        <li className={'h6'}>Invest in NFT/Web 3.0
                            <p className="h6">  Create an account/wallet with us and fund this wallet with your preferred crypto and start investing.</p>
                           </li>
                    </ol>
                     <div className="grid mgT2">
                         <div className="grid-item2_4">

                             <h5 className="h4"> TOKENIZED REAL ESTATE</h5>
                             <p className="h6">  Introducing Tokenized Real Estate</p>
                             <p className="h6 mgT2">
                                 Tokenization is an advancement of Real Estate Investment Trust(REIT) and is aimed at eliminating most of the problems encountered with this(REIT) model by utilizing blockchain technology.


                                 Real Estate has always been a valuable means generating passive income and accumulating equity from times past.
                                 At G7CAPITAL we understand this traditional means of investment and we leverage on it to maximize returns for our investors. With the use of blockchain technology, smart contracts and the G7 token we present to our investors a recession proof form of investment – Tokenized Real Estate

                             </p>
                         </div>
                         <div className="grid-item2_4">
                             <p className="h6">  Tokenization of real estate is a contemporary extension of conventional fractional real estate ownership
                                 It can be thought of as a crowd-funding tactic, which breaks up the value of a real estate investment into smaller chunks to overcome most of the investment barriers. An attempt was made in the early 1960s to attract investors and change the illiquid asset status of real estate with the establishment of the REITs (Real Estate Investment Trusts).
                                 A single property was split into multiple parts
                                 Each being owned by the multiple investors who pooled in the capital, an act that can be considered a precedent for tokenization in real estate. Tokenization is an advancement of Real Estate Investment Trust(REIT) and is aimed at eliminating most of the problems encountered with this(REIT) model by utilizing blockchain technology.</p>
                         </div>
                     </div>
                    <div className=" mgT2">
                       <h4 className="h5"> How Our Investors Earn?</h4>
                    <p className="h6 mgT1">    Tokenization is an advancement of Real Estate Investment Trust(REIT) and is aimed at eliminating most of the problems encountered with this(REIT) model by utilizing blockchain technology
                        It is backed up by real properties all around the globe and returns are distributed amongst our investors swiftly with the G7 Token.
                        It is 100% secured through the blockchain network and the use of smart contracts</p>
                    </div>
                </div>
            </div>
            <FooterC/>

        </div>
    );
};

export default TokenizedInvestments;