import React, {useContext, useState} from 'react';
import authImg from "../../assets/auth.jpg";
import Loader from "../../components/loader";
import {Link} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import {AuthContext} from "../../hoc/AuthContext";
import axios from "../../helpers/axiosInstance";

const OtpRoute = () => {

    const auth = useContext(AuthContext);
    const [otp,setOtp] = useState('');

    const [loading,setLoading] = useState(false);

    function authenticateOtp(e) {
        e.preventDefault();
        axios.post('/auth/otp', {otp:otp})
            .then( res => {
                toast.success("login successful")
                setTimeout(async()=>{
                    await auth.login(res.data.user,res.data.token);
                },1500)
            })

            .catch(err=> {
                setLoading(false);
                toast.error(err.response.data.msg);
            })
    }


    return (
        <div className='authBox' style={{backgroundImage:`linear-gradient(#0003,#0006),url(${authImg})`}}>
            {
                loading?<Loader/>:null
            }
            <div className="authBox-card">

                <form onSubmit={authenticateOtp} className="form">
                    <Link to='/' className='no-deco'>
                        <div className="centerText">
                            <h2 className='h4'>G7 <span className={'green'}>Capital</span></h2>
                        </div>
                    </Link>
                    <ToastContainer/>
                    <hr className="hr mgT2"/>
                    <h4 className="h5 mgT2">An OTP has been sent to your email, enter it to login</h4>
                    <div className="mgT2">
                        <div className="form-input-box">
                            <input  value={otp} className='form-input' onChange={e=>setOtp(e.target.value)} placeholder='_ _ _ _'/>
                        </div>

                    </div>
                    <button className="btn btn-blue mgT2" style={{width:"100%"}}>Authenticate &rarr;</button>


                </form>
            </div>
        </div>
    );
};

export default OtpRoute;