import React,{useContext,useState} from 'react';
import axios from "../../helpers/axiosInstance";
import {AuthContext} from "../../hoc/AuthContext";
import {Link, useNavigate} from 'react-router-dom';
import authImg from '../../assets/auth.jpg'
import Loader from "../../components/loader";
import {toast, ToastContainer} from "react-toastify";
const Login = () => {
    const navigate = useNavigate()
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [loading,setLoading] = useState(false);
   function loginUser(e){
        e.preventDefault();
        setLoading(true);
         let data = {
             email,password
         }
         axios.post('/auth/login',data)
             .then(async res => {

                toast.success(res.data.msg);
                setTimeout(()=>{
                    navigate('/otp');
                },1500)
             })

             .catch(err=> {
                 setLoading(false);
                 toast.error(err.response.data.msg);
             })
         }

       return (
           <div className='authBox' style={{backgroundImage:`linear-gradient(#0003,#0006),url(${authImg})`}}>
               {
                   loading?<Loader/>:null
               }
               <div className="authBox-card">

                   <form onSubmit={loginUser} className="form">
                       <Link to='/' className='no-deco'>
                           <div className="centerText">
                               <h2 className='h4'>G7 <span className={'green'}>Capital</span></h2>
                           </div>
                       </Link>
                        <ToastContainer/>
                       <hr className="hr mgT2"/>
                       <h4 className="h5 mgT2">Welcome Back</h4>
                       <div className="mgT2">
                           <div className="form-input-box">
                               <input  value={email} className='form-input' onChange={e=>setEmail(e.target.value)} placeholder='email'/>
                           </div>
                           <div className="form-input-box">
                               <input className='form-input' value={password} onChange={e=>setPassword(e.target.value)} placeholder='password'/>
                           </div>
                       </div>
                       <button className="btn btn-blue mgT2" style={{width:"100%"}}>Login</button>

                       <p className="mgT2 h7 grey-text">Forgot Password? <Link className='link' to='/resetPassword'>Reset Password</Link></p>
                       <p className='h7 mgT2 grey-text'>Don't got an account? <Link className='link' to='/register'>Register</Link></p>
                   </form>
               </div>
           </div>
       );
   // }
};

export default Login;