import React from 'react';
// import {FaCheckDouble} from "react-icons/fa";
import testImg from '../../../assets/hero_img.jpg'

const Advantage = () => {
    return (
        <div className='advantage-section pdv4'>
           <div className="container">
               <div className="grid">
                   <div className="grid-item2_4">
                       <h3 className="h4 light_color">Why Choose G7 Capital</h3>
                       <p className="h6 mgT1 grey-text">We’ve created an investment culture that insists on excellence and gives every investment team the resources they need and the freedom to create, continually developing their expertise.</p>
                   <h3 className="h4 light_color mgT4">Our Purpose</h3>
                       <p className="h6 mgT1 grey-text">
                           ‘Better firm. Better investing. Better world.’ is how we articulate our purpose. It guides our strategic decision making, culture, and our daily endeavours.</p>
                   </div>
                   <div className="grid-item2_4">
                       <img src={testImg} alt='' className="fluid_img" />
                   </div>


               </div>
           </div>
        </div>
    );
};

export default Advantage;