import React, {useState} from 'react';
import {BiEnvelopeOpen, BiTime} from "react-icons/bi";
import {Link} from "react-router-dom";
import {FiFacebook, FiLinkedin, FiTwitter} from "react-icons/fi";
import Modal from "./Modal";

const TopBar = () => {
    const [bookApp,showBookApp] = useState(false);
    return (
        <div className={'topBar'}>
            <div className="topBarContainer">
                <div className=" topBarContainer-item">
                    <span>
                        <BiEnvelopeOpen size={18}/>
                        <span className={'h7 mgL1'}>support@g7capital.org</span>
                    </span>
                    <span className={'mgL2'}>
                        <BiTime size={18}/>
                        <span className={'h7 mgL1'}>Mon-Fri: 9:00am - 8:00pm</span>
                    </span>
                </div>
                <div className="topBarContainer-item">

                    <Link to={'/'}><FiTwitter className={'topBarContainer-item-socials'}/></Link>
                    <Link to={'/'}><FiFacebook className={'topBarContainer-item-socials'}/></Link>
                    <Link to={'/'}><FiLinkedin className={'topBarContainer-item-socials'}/></Link>
                    <button className={'btn btn-blue'} onClick={()=>showBookApp(true)}>APPOINTMENT</button>
                </div>
            </div>
            {
                bookApp?<Modal closeModal={showBookApp}>
                    <div className="centerText">
                        <BiEnvelopeOpen size={64}/>
                        <h6 className="h5">Send an email to <span className="link h4">support@g7capital.org</span></h6>
                        <p className="h7">We will reach out to you</p>
                    </div>
                    </Modal>:null
            }
        </div>
    );
};

export default TopBar;