import React from 'react';
import hero2 from "../../../assets/relations.jpg";
import HomeNavigation from "../../../components/HomeNavigation";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import {Link} from "react-router-dom";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";

const InvestorRelations = () => {
    useScrollToTop();
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`,height:'70vh'}}>

                <div className=" width35 text-center mg2">
                    <p className="h5"><span className="green">G7 Capital</span> </p>
                    <h1 className="h2 light_color">
                        Investor Relations</h1>

                </div>
            </header>
            <div className="container grid">
                <div className="dark--grid-item grid-item1_3 ">
                    <h3 className="h4 green">Shareholder Information</h3>
                    <p className="h5 mgT2 light_color">latest information relating to G7Capital Investor share price...</p>
                    <Link to={'/'} className={'btn btn-green mgT4'}>Discover More</Link>
                </div>
                <div className="dark--grid-item grid-item1_3 ">
                    <h3 className="h4 green">Corporate Governance</h3>
                    <p className="h5 mgT2 light_color">While our history has taught us to think differently...</p>
                    <Link to={'/'} className={'btn btn-green mgT4'}>Discover More</Link>
                </div>
                <div className="dark--grid-item grid-item1_3 ">
                    <h3 className="h4 green">News & Announcements</h3>
                    <p className="h5 mgT2 light_color">Keep up with the latest information on our platform...</p>
                    <Link to={'/'} className={'btn btn-green mgT4'}>Discover More</Link>
                </div>
            </div>
  <div className="container grid-">
                <div className="dark--grid-item grid-item1_3 ">
                    <h3 className="h4 green">Contact Us</h3>
                    <p className="h5 mgT2 light_color">Get in touch with us. We take all issues seriously.</p>
                    <Link to={'/'} className={'btn btn-green mgT4'}>Discover More</Link>
                </div>
                <div className="dark--grid-item grid-item1_3 ">
                    <h3 className="h4 green">Frequently Asked Questions</h3>
                    <p className="h5 mgT2 light_color">Here are some frequently asked questions for you</p>
                    <Link to={'/faqs'} className={'btn btn-green mgT4'}>Discover More</Link>
                </div>
                <div className="dark--grid-item grid-item1_3 ">
                    <h3 className="h4 green">Become a representative</h3>
                    <p className="h5 mgT2 light_color">Become a representative and earn $1000 - $5000 monthly</p>
                    <Link to={'/register'} className={'btn btn-green mgT4'}>Register</Link>
                </div>
            </div>


            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default InvestorRelations;