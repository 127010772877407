import React,{useState,useEffect,useCallback} from 'react';
import AxiosInstance from "../../../helpers/axiosInstance";
import {Link} from "react-router-dom";
const NewsAndMarket = () => {

    const [loading,setLoading] = useState(false)
    const [limit] = useState(4);
    const [page] =useState(1);
    const [posts,setPosts] = useState([]);
    const getAllPosts = useCallback(()=>{
        setLoading(true)
        AxiosInstance.get(`/sup/blog/get/all?offset=${page}&limit=${limit}`)
            .then(res=>{
                setPosts(res.data.data.docs)
                setLoading(false)
            })
            .catch(err=>{

                setLoading(false)
            })
    },[setPosts,page,limit]);
    useEffect(()=>{
        getAllPosts();
    },[getAllPosts]);   useEffect(()=>{
        getAllPosts();
    },[getAllPosts]);
    return (
        <div className={''} style={{backgroundColor:'#f7f7f7',paddingTop:'4rem'}}>
           <div className="container">
               <div className="centerText">
                   <h3 className="h3 green">News & Insights</h3>
                   <p className="h5 grey-text">Keep up with the latest information on our platform...</p>
               </div>
               <div className="mgT8 grid">
                   {
                       posts.length>0? posts.map(e=> <div key={e._id} className="lightCard grid-item1_4">
                         <Link to={`/blog/${e._id}`} className={' no-deco'}>
                             <img alt={e?.title} src={e?.image} className="fluid_img"/>
                             <div className=" mgT4" style={{padding:'2rem'}}>
                                 <h6 className="h4">{e?.title}</h6>
                                 <hr className="hr mgV2"/>
                                 <p className="h6">View Points</p>
                             </div>
                         </Link>
                       </div>):null
                   }
               </div>
           </div>
        </div>
    );
};

export default NewsAndMarket;