import React, {useRef} from 'react';
import {BiSearch} from "react-icons/bi";
import { writeFileXLSX,utils } from "xlsx";
import '../sass/components/table.scss'
import jsPDF from "jspdf";
import  "jspdf-autotable";
const Table = ({type,headers,body,filters,data,title,searchQuery,searchText,onTextChange}) => {
    const tableRef = useRef(null);
    function downloadExcel() {
        let table_elt = tableRef.current;
        let workbook = utils.table_to_book(table_elt);
       if (title){
           writeFileXLSX(workbook, `${title}.xlsx`);
       }
        writeFileXLSX(workbook, `g7-doc.xlsx`);
    }
    function exportPDF() {
        const doc = new jsPDF()
        doc.autoTable({ html: '#table--' })
        // doc.save('table.pdf')
        if (title){
            doc.save(`g7-${title}.pdf`)
        }else{
            doc.save(`g7-file.pdf`)
        }

    }
    return (
        <div className='table-holder'>
            {
               filters? <div className={'table-filter'}>
                    <div className="table-filter-list">
                         <span className="table-filter-list-item">
                        Copy
                    </span>
                        <span onClick={downloadExcel} className="table-filter-list-item">
                        Excel
                    </span>
                        <span onClick={exportPDF} className="table-filter-list-item">
                        PDF
                    </span>
                    </div>
                    <form onSubmit={searchQuery} className="table-filter-search">
                        <input value={searchText} onChange={(e)=>onTextChange(e.target.value)} type="text" placeholder={'Search'}/>
                      <button className="" style={{border:'none',backgroundColor:'inherit'}}>
                          <BiSearch size={24}/>
                      </button>
                    </form>
                </div>:null
            }
             <table className={type} ref={tableRef} id={'table--'}>
                 <thead>
                    <tr>{
                        headers.map((e,i)=>{
                            return (
                                <th key={i} >
                                    {e}
                                </th>
                            )
                        })
                    }</tr>
                 </thead>
                 <tbody>
                 {body}
                 </tbody>
             </table>
        </div>
    );
};

export default Table;