import React, {useState} from 'react';
import DashboardLayout from "../../../layout/dashboardLayout";
import {CurrencyCards} from "../clientHome";
import Table from "../../../hoc/Table";
import Modal from "../../../components/Modal";

const InvestNow = () => {
    return (
        <DashboardLayout>
            <h5 className="h5">Investment</h5>

                <CurrencyCards/>
            <h6 className="h6 mgT2">Available Investment Types</h6>
            <div className="mgT2 outlined-card">

                <Table type={'dark-table'} headers={['Name','Amount','Percentage','Duration','']} body={<>
                  <InvestmentCard/>
                </>}/>
            </div>
        </DashboardLayout>
    );
};

function InvestmentCard() {
    const [show,setShow] = useState(false);
    return <tr>
        <td>TRUST FUNDS</td>
        <td>$100 - $50000</td>
        <td>2%</td>
        <td>1year</td>
        <td>

            <button className="btn btn-green" onClick={()=>setShow(true)}>Invest now</button>
            {
                show?<Modal closeModal={setShow}>
                    <h5 className="h5 mgB2">Invest</h5>
                    <label className={'h7 grey-text'}>Investment Type</label>
                        <div className="form-input-box">
                            <input type="text" placeholder={'Investment Type'}/>
                        </div>
                    <label className={'h7 grey-text'}>wallet Type</label>
                        <div className="form-input-box">
                            <select name="" id="">
                                <option value="">BTC</option>
                            </select>
                        </div>
                    <label className={'h7 grey-text'}>Amount to invest ($100 - $5000)</label>
                        <div className="form-input-box">
                            <input type="text" placeholder={'amount'}/>
                        </div>
                    <button className="btn btn-blue mgT2" style={{width:"100%"}}>Invest</button>
                </Modal>:null
            }
        </td>

    </tr>
}

export default InvestNow;