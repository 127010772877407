import React from 'react';
import Load from "../assets/loading.gif";
import {Link} from "react-router-dom";

const ErrorPage = () => {
    return (
        <div className='error_page text-center'>
            <img className='loader-gif' src={Load} alt='loader' />
            <h2 className="h2 mgT4">Error Page Doesn't Exist</h2>
            <p className="h7"> Go to <Link to={'/'} className={'link'}>Home</Link></p>
        </div>
    );
};

export default ErrorPage;