import React from 'react';
import Navigation from "../../../components/navigation";
import FooterC from "../../../hoc/FooterC";
import ScrollToTop from "../../../hoc/scrollToTop";

const TradeSafety = () => {

    ScrollToTop();
    return (
        <div>
            <Navigation/>

            <div className="container">
                <div className="card mgT4">
                    <h4 className="h4 text-center">Trade safety guide</h4>
                    <div className="pre mgT2  align_center">
                        <p className="h5">
                            This digital exchange measures are structured to foster a hospitable environment for cedefimas digital exchange contracts to thrive. It should be adopted as a regulative guide in dealing with exchange endeavors to ensure fair and successful transactions with us and amongst our clients.    </p>
                    </div>
                    <div className="mgV2">
                        <h5 className="h5">Regarding payments </h5>
                        <p className="h5 mgT1">All contract payments are time sensitive and required to be completed before expiration time to avoid suspension of defaulters trade account.
                            Transaction margin of $50 insurance desposit must be made to the central account and approved before opening any new contract trade exchange.
                            This fee is nonrefundable as it serves as insurance against trade default, contract disenfranchisement and enables indemnification.
                            All payments must be made directly into central wallet account or sellers blockchain wallet account and payment hash embedded in the proof of payment space provided to enable verification/confirmation.
                            All payments must be made in bitcoin equivalent of stipulated dollar amount weather for insurance or trade contract.
                            All payment must be exactly $50 worth of bitcoin at the time of transfer to cedefimas central portfolio for trade margin and insurance deposit.
                            All payments must be exactly $100 worth of bitcoin at the time of transfer to a seller for a single trade contract exchange.
                            All contract payments remain $50 and $100 respectively, at all times, regardless of bitcoins actual market price.
                        </p>
                        <h5 className="h5">Regarding reception of funds</h5>
                        <p className="h5 mgT1">All funds reception must be confirmed within 48 hours of proof of payment provision, failure to confirm fund reception will be regarded as disenfranchisement.
                            Transfer of exchange trade funds are directly client to client and cannot be reversed by our team so be careful not to make any mistakes during payments.
                            All funds reception must be verified by receiver through confirmation of funds existence in private wallet account before confirming buyers’ payment on our platform.
                            Cedefimas will not indemnify or be held liable for mistakenly confirming fund reception without proper verification, or over payment for any contract

                        </p>
                        <h5 className="h5">Regarding disenfranchisement</h5>
                        <p className="h5 mgT1">All defaults in contract will be translated as disenfranchisement.
                            All defaults are punishable by suspension of defaulters account.
                            Refusal to honor contract mandate is interpreted as defaulting.
                            Refusal to complete any initiated payment process is seen as defaulting.
                            Refusal to confirm funds reception is seen as disenfranchisement.
                            Refusal to accept payment is seen as disenfranchisement.
                            Refusal to transact with a provided trader is seen as disenfranchisement.
                            All default or disenfranchisement must be immediately reported to our support team.
                            All reports must be made after timeout of contract delivery time and date.

                        </p>
                        <h5 className="h5">Regarding insurance</h5>
                        <p className="h5 mgT1">All insurance claims will be verified and reimbursement will be received within 72 hours of complain.
                            Only claims within the insurance perimeters will be indemnified.
                            All insurance claims must be reported to our support team after 48 hours of contract default.
                            All insurance claims will be reimbursed in bitcoin value of the insurance entitlement.
                            An insurance reimbursement mandates the client to repeat the insurance stage to recoup profit before proceeding to the next insurance stage.
                            This repetition of insurance stage will effect automatically team after any insurance payout is completed.


                        </p> <h5 className="h5">Regarding suspended account </h5>
                        <p className="h5 mgT1">Suspended account holder must contact our support team for rectification.
                            Suspended accounts can be reinstated through due process provided by our support team.
                            Suspended account will lose insurance fee deposited as consequences of disenfranchisement.
                        </p>
                    </div>
                </div>
            </div>
            <FooterC/>
        </div>
    );
};

export default TradeSafety;