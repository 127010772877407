import React from 'react';
import hero2 from "../../../assets/tesla1.avif";
import tesla2 from "../../../assets/tesla2.jpg";
import tesla1 from "../../../assets/tesla1.jpg";
import HomeNavigation from "../../../components/HomeNavigation";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";

const Tesla = () => {
    useScrollToTop()
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`,height:'50vh'}}>
               
                <div className=" width35 text-center mg2">
                    <p className="h5"><span className="green">G7 Capital</span> </p>
                    <h1 className="h2 light_color">Tesla</h1>
                </div>
            </header>

            <div className="container ">
                <div className="grid">
                    <div className="grid-item2_4">
                        <h4 className="h3">Introduction</h4>
                        <p className="mgT2 grey-text h5">a tesla supercharger is a 480-volt direct current fast-charging technology built by american vehicle manufacturer tesla, inc. for electric cars. the supercharger network was introduced on september 24, 2012, with six supercharger stations.[1] as of february 18, 2021, tesla operates over 30,000 superchargers in over 2,564 stations worldwide[2] (an average of 9 chargers per station). there are 1,101 stations in north america, 592 in europe, and 498 in the asia/pacific region.[3] supercharger stalls have a connector to supply electrical power at maximums of 72 kw, 150 kw or 250 kw.[4]</p>
                        <p className="mgT2 h5 grey-text">The tesla model x costs about $15.29 to fully charge, which comes out to about 4.5 cents per mile. it will cost around $7.65 to charge a tesla model 3. depending on the variant, this is between 3 and 4 cents per mile. if you own a tesla model s, you can expect to pay about 3.7 per mile.</p>
                    </div>
                    <div className="grid-item2_4">
                        <img alt={''} src={tesla2} className="fluid_img"/>
                    </div>
                </div>
            </div>
            <div className="container ">
                <h6 className="h4">The Cost</h6>
                <p className="h5 grey-text mgT4">The cost for tesla supercharger is between $100,000 and $175,000 depending on the station, and a lot of those come from the permanent modifications needed at the site to support the supercharger tesla superchargers are gaining momentum as many people are now using tesla cars for their day to day activities and shariafinance is here to walk you down the steps of benefiting from this particular type of investment, the purchase of tesla superchargers, installation and maintenance will be overseen by shariafinance investment company</p>
                <div className="grid mgT2">
                    <div className="grid-item-f">
                        <img alt={''} src={tesla1} className="fluid_img"/>
                    </div>
                </div>
            </div>

            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default Tesla;