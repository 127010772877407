import React from 'react';
import hero2 from "../../../assets/hero2.jpg";
import Navigation from "../../../components/navigation";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";

const Services = () => {
    return (
        <div>
            <header className='altHero' style={{backgroundImage:`url(${hero2})`}}>
                <Navigation/>
                <div className="overlayCard width35 text-center mg2">
                    <h4 className="h4">Services</h4>
                    {/*<p className="h5 mgT2">Get to Know Us Better</p>*/}
                </div>
            </header>

            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default Services;