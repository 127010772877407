import React,{useEffect} from 'react';
import {Link} from "react-router-dom";
// import {Carousel} from "react-responsive-carousel";
import HomeNavigation from "../../../components/HomeNavigation";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import 'react-awesome-slider/dist/styles.css';
import hero from '../../../assets/auth.jpg'
import hero2 from '../../../assets/hero.jpg'
import hero3 from '../../../assets/hero3.jpg'
import {useAnimation,motion,useTransform,useViewportScroll} from "framer-motion";
import {useInView} from "react-intersection-observer";
import TopBar from "../../../components/topBar";
const Hero = () => {
    // const [loading,setLoading] = React.useState(true);


    const controls = useAnimation();
    const { ref, inView } = useInView();
    useEffect(() => {
        if (inView) {
            controls.start('visible').then(()=>{

                console.log('starting')
            });
        }
        if (!inView) {
            controls.start('hidden').then();
        }
    }, [controls, inView]);

    const {scrollYProgress} = useViewportScroll();
    const opacity= useTransform(scrollYProgress,[0,1],[0.8,1.1])
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    return (
        <>
            <TopBar/>
            <HomeNavigation/>
            <motion.div className='hero-section'>

                <AutoplaySlider  animation="foldOutAnimation"
                                 cssModule={[CoreStyles, AnimationStyles]}
                                 fillParent={true}
                                 play={true}
                                 cancelOnInteraction={false} // should stop playing on user interaction
                                 interval={50000}

                >
                    <div className=' hero-section-slide-item' style={{backgroundColor:'transparent',backgroundImage:`linear-gradient(#0009 0%,#0009),url(${hero})`}}>
                        <h1 className="h2  light_color  mgT8">The Best Crypto Investment Decision you will ever make </h1>
                        <p className=' h5 mgT1 grey-text'>Contracts powered by G7 Capital to seamlessly earn crypto, you’re probably losing money elsewhere. Our futures exchange system security and unrivaled flexibility that ensures maximum profit with zero loss  </p>
                        <div className='mgT2 pdV2'>
                            <Link className='hero-btn' to='/register'>
                                Create Account
                            </Link>
                            <Link className=' btn-outline btn-outline-green mgL2' to='/login'>
                                Login
                            </Link>
                        </div>

                    </div>

                    <div className='hero-section-slide-item width50'  style={{backgroundColor:'transparent',backgroundImage:`linear-gradient(#0008,#0009),url(${hero2})`}}>
                        <h1 className="h3 mgT2 light_color">Choosing the right investment company is the deference between profit and loss</h1>

                        <p className="h5 mgT1 light_color">  With contract protection policies to structure a world class pinnacle of investments that is perfectly balanced for a profitable outcome for all clients at all times.</p>

                        <div className='mgT2 pdV2'>
                            <Link className='hero-btn' to='/insurance'>
                                Insurance
                            </Link>

                        </div>

                    </div>

                    <div className='   hero-section-slide-item width50'  style={{backgroundColor:'transparent',backgroundImage:`linear-gradient(#0008,#0009),url(${hero3})`}}>
                        <h1 className="h3 mgT2 light_color">Futures exchange contract is the Best trading  </h1>


                        <p className="h5 mgT1 light_color">our protection policies ensures you never incur a single loss. We have gone a step further to encompass a user interface that reduces futures exchange contract
                            complexity making it easy, simple and short for you. </p>
                        <div className='mgT2 pdV2'>
                            <Link className='hero-btn' to='/about'>
                                About Us
                            </Link>
                            <Link className=' btn-outline btn-outline-blue mgL2' to='/faqs'>
                                FAQs
                            </Link>
                        </div>


                    </div>

                    <div className='   hero-section-slide-item width50' style={{backgroundColor:'transparent'}} >
                        <h1 className="h3 mgT2 light_color">We grow your investment with no access to it, trading elsewhere is settling for less</h1>
                        {/*<p className=' h5'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias cum debitis eaque enim.</p>*/}

                        <p className="h5 mgT1 grey-text">We only provide the necessary conditions and nutrient to grow and nurture it to maturity and fruitfulness. You’re the farmer, your initial capital the crop and we are the best condition necessary for germination. Together we achieve great harvests.  </p>

                        <div className='mgT2 pdV2'>


                        </div>

                    </div>
                </AutoplaySlider>


            </motion.div></>
    );
};

export default Hero;