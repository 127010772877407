import React, {useState} from 'react';
import hero2 from "../../../assets/cash.jpg";
import HomeNavigation from "../../../components/HomeNavigation";
import {Link} from "react-router-dom";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import Tab from "../../../components/tab";
import Table from "../../../hoc/Table";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";

const CashManagement = () => {
    useScrollToTop()
    const [tabs] = useState([<UKOEIC/>,<SAUnit/>,<GSLUX/>,<Others/>]);
    const [index,setIndex] = useState(0)
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`}}>
        
                <div className=" width35 text-center mg2">
                    <h1 className="h6 light_color">G7 <span className="green">Capital</span></h1>
                    <h1 className="h2 light_color">Funds Prices & Performance</h1>

                </div>

            </header>
            <div className="mgT2 center-container">
                <Tab tabs={['UK OEIC','SA UNIT TRUST','GSF LUX SICAV ','OTHERS']} activeIndex={index} onChange={setIndex} tabsBody={
                    tabs[index]
                } />
            </div>
            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

function UKOEIC() {
    return <div>
       <CashCard title={'Diversified  Income'} defaultValue={true} subtitle={'UK OEIC | Featured | Multi-Asset'}/>
       <CashCard title={'Diversified  Income'} defaultValue={false} subtitle={'UK OEIC | Featured | Multi-Asset'}/>
       <CashCard title={'Diversified  Income'} defaultValue={false} subtitle={'UK OEIC | Featured | Multi-Asset'}/>
       <CashCard title={'Diversified  Income'} defaultValue={false} subtitle={'UK OEIC | Featured | Multi-Asset'}/>
       <CashCard title={'Diversified  Income'} defaultValue={false} subtitle={'UK OEIC | Featured | Multi-Asset'}/>

    </div>
}

function SAUnit() {
    return <div>
       <CashCard title={'G7 Capital Diversified Income Fund'} defaultValue={true} subtitle={'SA Unit Trust | Featured'}/>
       <CashCard title={'G7 Capital Cautious Managed Fund'} defaultValue={false} subtitle={'SA Unit Trust | Featured'}/>
       <CashCard title={'G7 Capital  Investor Opportunity Fund'} defaultValue={false} subtitle={'SA Unit Trust | Featured'}/>
       <CashCard title={'G7 Capital Investor Worldwide Flexible Fund'} defaultValue={false} subtitle={'SA Unit Trust | Featured'}/>
       <CashCard title={'G7 Capital Investor Equity Fund'} defaultValue={false} subtitle={'SA Unit Trust | Featured'}/>

    </div>
}
function GSLUX() {
    return <div>
       <CashCard title={'All China Bond'} defaultValue={true} subtitle={'GSF Lux SICAV | Bond'}/>
       <CashCard title={'All China Equity'} defaultValue={false} subtitle={'GSF Lux SICAV | Equity'}/>
       <CashCard title={'Asia Pacific Equity Opportunities'} defaultValue={false} subtitle={'GSF Lux SICAV | Bond'}/>
       <CashCard title={'Asia Pacific Franchise'} defaultValue={false} subtitle={'GSF Lux SICAV | Equity'}/>
       <CashCard title={'Asian Equity'} defaultValue={false} subtitle={'GSF Lux SICAV | Bond'}/>
       <CashCard title={'Emerging Markets Blended Debt'} defaultValue={false} subtitle={'GSF Lux SICAV | Bond'}/>

    </div>
}
function Others() {
    return <div>
       <CashCard title={'NFM Vitality Multi-Asset Balanced'} defaultValue={true} subtitle={'Other | Multi-Asset'}/>
       <CashCard title={'NFM Vitality Multi-Asset Growth'} defaultValue={false} subtitle={'Other | Multi-Asset'}/>
       <CashCard title={'NFM Vitality Multi-Asset Moderate'} defaultValue={false} subtitle={'Other | Multi-Asset'}/>
       <CashCard title={'NFM Vitality Targeted Absolute Return Bond'} defaultValue={false} subtitle={'Other | Multi-Asset'}/>

    </div>
}

function CashCard({title,subtitle,data,defaultValue}) {
    const [show,setShow] = useState(defaultValue)
    return <div className={"mgv4"}>
        <h5 className="h4 mgT4">{title}</h5>
        <p className="greyText h6 mgV2">{subtitle}</p>
        <div className="mgV2" onClick={()=>setShow(!show)}>
            <p className="green h6">Share Class</p>
        </div>
        {
            show? <Table headers={["Share Class","ISIN","Change %","NAV(December 2021)","12 month low","12 month high"]} type={'dark-table'}

                         body={<>
                             <tr>
                                 <td>A Acc GBP</td>
                                 <td>GB00B4158F27</td>
                                 <td>^1.38</td>
                                 <td>121.0600</td>
                                 <td>106.3000</td>
                                 <td>134.5300</td>
                             </tr>
                             <tr>
                                 <td>A Acc GBP</td>
                                 <td>GB00B4158F27</td>
                                 <td>^1.38</td>
                                 <td>121.0600</td>
                                 <td>106.3000</td>
                                 <td>134.5300</td>
                             </tr>
                             <tr>
                                 <td>A Acc GBP</td>
                                 <td>GB00B4158F27</td>
                                 <td>^1.38</td>
                                 <td>121.0600</td>
                                 <td>106.3000</td>
                                 <td>134.5300</td>
                             </tr>
                             <tr>
                                 <td>A Acc GBP</td>
                                 <td>GB00B4158F27</td>
                                 <td>^1.38</td>
                                 <td>121.0600</td>
                                 <td>106.3000</td>
                                 <td>134.5300</td>
                             </tr>
                         </>}/>:null
        }
        <hr className="hr"/>
        <button className="btn btn-dark mgT4">View Fund</button>
    </div>
}

export default CashManagement;