import React,{useState,useCallback} from "react";
import './sass/app.scss';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/home/home/Home";
import Insurance from "./pages/home/pages/insurance";
import Faqs from "./pages/home/home/Faqs";
import Support from "./pages/home/home/support";
import AboutPage from "./pages/home/about/aboutPage";
import PrivacyPolicy from "./pages/home/pages/privacy_policy";
import TradeSafety from "./pages/home/pages/tradeSafety";
import Terms from "./pages/home/pages/terms";
import ReferralPage from "./pages/home/pages/referral";
import Register from "./pages/auth/register";
import Login from "./pages/auth/login";
import ResetPassword from "./pages/auth/reset";
import ResetPasswordRe from "./pages/auth/reset_re";
import PurposePage from "./pages/home/about/purposePage";
import CulturePage from "./pages/home/about/culturePage";
import TrustFund from "./pages/home/pages/trustFund";
import Offshore from "./pages/home/pages/offshore";
import HowToInvest from "./pages/home/pages/howToInvest";
import CashManagement from "./pages/home/pages/cashManagement";
import Crypto from "./pages/home/pages/crypto";
import Stocks from "./pages/home/pages/stocks";
import OptionsPage from "./pages/home/pages/optionsPage";
import GoldPage from "./pages/home/pages/goldPage";
import TaxFree from "./pages/home/pages/taxFree";
import InvestorRelations from "./pages/home/pages/investorRelations";
import Tesla from "./pages/home/pages/tesla";
import Swap from "./pages/home/pages/swap";
import Atm from "./pages/home/pages/atm";
import Services from "./pages/home/home/services";
import AdminHome from "./pages/admin/adminHome";
import Users from "./pages/admin/users/users";
import AdminSettings from "./pages/admin/AdminSettings";
import CardManagement from "./pages/admin/cards/cardManagement";
import LoanManagement from "./pages/admin/loan/loanManagment";
import NewsManagement from "./pages/admin/news/newsManagement";
import MobileDeposit from "./pages/admin/mobile/mobileDeposit";
import Messages from "./pages/admin/message/messages";
import AllTransactions from "./pages/admin/AllTransactions";
import ClientHome from "./pages/client/clientHome";
import InvestNow from "./pages/client/subPages/investNow";
import Referral from "./pages/client/subPages/Referral";
import Settings from "./pages/client/subPages/settings";
import DepositPage from "./pages/client/subPages/depositPage";
import ExchangeCoins from "./pages/client/subPages/exchangeCoins";
import Withdrawal from "./pages/client/subPages/withdrawal";
import ManageCards from "./pages/client/subPages/manageCards";
import Wallet from "./pages/client/subPages/wallet";
import Statement from "./pages/client/subPages/statement";
import AllUserTransaction from "./pages/client/subPages/Transaction";
import {AuthContext} from "./hoc/AuthContext";
import {useNavigate} from "react-router-dom";
import Kyc from "./pages/client/subPages/kyc";
import PrivateRoute from "./hoc/privateRoute";
import WalletAddress from "./pages/admin/wallet Address";
import AdminStatement from "./pages/admin/users/AdmiinStatement";
import ErrorPage from "./pages/errorPage";
import AddArticle from "./pages/admin/blog/addArticle";
import Article from "./pages/blog/articles/article";
import BlogHome from "./pages/blog/articles/blogHome";
import TokenizedInvestments from "./pages/home/pages/TokenizedInvestments";
import BuyToken from "./pages/home/pages/buyToken";
import {AnimatePresence} from "framer-motion";
import AllArticles from "./pages/admin/blog/AllArticles";
import OtpRoute from "./pages/auth/OtpRoute";

function App() {
    const [user,setUser] = useState(localStorage.getItem('G7_li'));
    const [token,setToken] = useState(localStorage.getItem('G7_TK'));
    const navigate = useNavigate();
    const logOut = useCallback (()=> {
        localStorage.clear();
        navigate('/login',{replace:true});
    },[navigate])
    function login(data,token) {
        setUser(JSON.stringify(data));
        setToken(token);
        localStorage.setItem('G7_TK',token)
        localStorage.setItem('G7_li',JSON.stringify(data));
        // //navigate to dashboard
        console.log(data)
        if(data.role===0){
            navigate('/client',{replace:true});
        }else{
            navigate('/admin',{replace:true});
        }
    }
  return (
      <AuthContext.Provider value={{token,user,logOut,login}}>
        <AnimatePresence>
            <Routes>
                <Route exact path='/' element={<Home/>}/>
                <Route exact path='/services' element={<Services/>}/>
                <Route exact path='/insurance' element={<Insurance/>}/>
                <Route exact path='/faqs' element={<Faqs/>}/>
                <Route exact path='/support' element={<Support/>}/>
                <Route exact path='/about' element={<AboutPage/>}/>
                <Route exact path='/our-purpose' element={<PurposePage/>}/>
                <Route exact path='/our-culture' element={<CulturePage/>}/>
                <Route exact path='/trust-fund' element={<TrustFund/>}/>
                <Route exact path='/offshore-investment' element={<Offshore/>}/>
                <Route exact path='/atm' element={<Atm/>}/>
                <Route exact path='/iswap' element={<Swap/>}/>
                <Route exact path='/tesla' element={<Tesla/>}/>
                <Route exact path='/investor-relations' element={<InvestorRelations/>}/>
                <Route exact path='/tax-free' element={<TaxFree/>}/>
                <Route exact path='/cash-management' element={<CashManagement/>}/>
                <Route exact path='/crypto' element={<Crypto/>}/>
                <Route exact path='/stocks' element={<Stocks/>}/>
                <Route exact path='/options' element={<OptionsPage/>}/>
                <Route exact path='/gold' element={<GoldPage/>}/>
                <Route exact path='/tokenized-investments' element={<TokenizedInvestments/>}/>
                <Route exact path='/buy-token' element={<BuyToken/>}/>
                {/*Blog*/}
                <Route path={'/blog/'} element={<BlogHome/>}/>
                <Route path={'/blog/:id'} element={<Article/>}/>
                {/*Blog ends*/}
                <Route exact path='/how-to-invest' element={<HowToInvest/>}/>
                <Route exact path='/privacy_policy' element={<PrivacyPolicy/>}/>
                <Route exact path='/trade_safety' element={<TradeSafety/>}/>
                <Route exact path='/terms_conditions' element={<Terms/>}/>
                <Route exact path='/referrals' element={<ReferralPage/>}/>
                <Route exact path='/register/' element={<Register/>}/>
                <Route exact path='/otp/' element={<OtpRoute/>}/>
                <Route exact path='/login' element={<Login/>}/>
                <Route exact path='/resetPassword' element={<ResetPassword/>}/>
                <Route exact path='/reset/verify/:id' element={<ResetPasswordRe/>}/>
                {/*CLIENT ROUTES*/}

                <Route path={'/client'} element={<PrivateRoute><ClientHome/></PrivateRoute>}/>
                <Route path={'/client/kyc'} element={<PrivateRoute><Kyc/></PrivateRoute>}/>
                <Route path={'/client/invest'} element={<PrivateRoute><InvestNow/></PrivateRoute>}/>
                <Route path={'/client/referral'} element={<PrivateRoute><Referral/></PrivateRoute>}/>
                <Route path={'/client/card-management'} element={<PrivateRoute><ManageCards/></PrivateRoute>}/>
                <Route path={'/client/settings'} element={<PrivateRoute><Settings/></PrivateRoute>}/>
                <Route path={'/client/deposit'} element={<PrivateRoute><DepositPage/></PrivateRoute>}/>
                <Route path={'/client/exchange-coins'} element={<PrivateRoute><ExchangeCoins/></PrivateRoute>}/>
                <Route path={'/client/withdrawal'} element={<PrivateRoute><Withdrawal/></PrivateRoute>}/>
                <Route path={'/client/wallet'} element={<PrivateRoute><Wallet/></PrivateRoute>}/>
                <Route path={'/client/statement'} element={<PrivateRoute><Statement/></PrivateRoute>}/>
                <Route path={'/client/referral'} element={<PrivateRoute><ReferralPage/></PrivateRoute>}/>
                <Route path={'/client/transactions'} element={<PrivateRoute><AllUserTransaction/></PrivateRoute>}/>

                {/*ADMIN ROUTES*/}
                <Route path={'/admin'} element={<PrivateRoute><AdminHome/></PrivateRoute>}/>
                <Route path={'/admin/users'} element={<PrivateRoute><Users/></PrivateRoute>}/>
                <Route path={'/admin/blog'} element={<PrivateRoute><AddArticle/></PrivateRoute>}/>
                <Route path={'/admin/blog/all'} element={<PrivateRoute><AllArticles/></PrivateRoute>}/>
                <Route path={'/admin/settings'} element={<PrivateRoute><AdminSettings/></PrivateRoute>}/>
                <Route path={'/admin/wallet-addresses'} element={<PrivateRoute><WalletAddress/></PrivateRoute>}/>
                <Route path={'/admin/acc/statement/:id'} element={<PrivateRoute><AdminStatement/></PrivateRoute>}/>
                <Route path={'/admin/card-management'} element={<PrivateRoute><CardManagement/></PrivateRoute>}/>
                <Route path={'/admin/loan-management'} element={<PrivateRoute><LoanManagement/></PrivateRoute>}/>
                <Route path={'/admin/news-management'} element={<PrivateRoute><NewsManagement/></PrivateRoute>}/>
                <Route path={'/admin/reset-password'} element={<PrivateRoute><AdminSettings/></PrivateRoute>}/>
                <Route path={'/admin/mobile-deposit'} element={<PrivateRoute><MobileDeposit/></PrivateRoute>}/>
                <Route path={'/admin/messages'} element={<PrivateRoute><Messages/></PrivateRoute>}/>
                <Route path={'/admin/allTransactions'} element={<PrivateRoute><AllTransactions/></PrivateRoute>}/>
                <Route path={'/*'} element={
                    <>
                        <ErrorPage/>
                    </>}/>
            </Routes>
        </AnimatePresence>
      </AuthContext.Provider>


  );
}

export default App;
