import React from 'react';
import moment from "moment";
import {AuthContext} from "../../../hoc/AuthContext";

const TransactionCard = ({transaction}) => {


    const userInfo = React.useContext(AuthContext);
    function formatDate(value) {
        return  moment(value).format('MM DD YYYY')
    }

    return (
        <div className='transactionCard'>
            <div className="">
                <h2 className="h5">{transaction.trxType}</h2>
                <p className="h5">{transaction.amount}</p>
            </div>
            <p className="h5">{formatDate(transaction?.createdAt)}</p>
            <h2 className="h5">{transaction?.btcAddress}</h2>
            <p className="h5">{transaction?.status}</p>
        </div>
    );
};

export default TransactionCard;