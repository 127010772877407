import React, {useCallback, useEffect, useState} from 'react';
import AdminLayout from "../../../layout/AdminLayout";
import Table from "../../../hoc/Table";
import AxiosInstance from "../../../helpers/axiosInstance";
import Moment from "react-moment";
import {AuthContext} from "../../../hoc/AuthContext";

const CardManagement = () => {
 const [data,setData] = useState([])

    const userInfo = React.useContext(AuthContext);


    const  getCards = useCallback(()=>{
        AxiosInstance.get('/p/dep/c/get',{
            headers:{
                authorization:userInfo.token
            }
        })
            .then(res=>setData(res.data.data))
            .catch(err=>{})
    },[userInfo.token]);
 useEffect(()=>{
     getCards();
 },[getCards])

    return (
        <AdminLayout>
            <h4 className="h4">Card Application</h4>
            <div className="mgT4">
                <div className="mgT2">
                    <Table
                        filters={true}
                        type='dark-table' headers={['Account Name','Email','Card Number',
                        'Reg Date','Cvv','Exp']}
                        body={
                            <React.Fragment>
                                {
                                    data.length>0? data.map((e)=>  <tr >

                                        <td>
                                            {
                                                e.owner.fullName
                                            }
                                        </td>

                                        <td>
                                            {e.owner.email}
                                        </td>

                                        <td>
                                            {e.number}
                                        </td>

                                        <td>

                                            <Moment format="MM/DD/YYYY">{e.createdAt}</Moment>
                                        </td>
                                        <td>
                                            {e.cvv}
                                        </td>
                                        <td>
                                            {e.exp}
                                        </td>


                                    </tr>): <tr></tr>
                                }
                            </React.Fragment>
                        }



                    />
                </div>
            </div>
        </AdminLayout>
    );
};

export default CardManagement;
