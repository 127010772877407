import React, {useContext, useEffect, useState} from 'react';
import {
    BiHomeAlt,
    BiLogOut, BiLock, BiWallet, BiNews
} from "react-icons/bi";
import {FiUserPlus, FiUsers} from "react-icons/fi";
import {Link} from "react-router-dom";
// import avatar from '../assets/avatar.png'
import {AuthContext} from "../hoc/AuthContext";
import {BsCurrencyExchange} from "react-icons/bs";
import {motion,AnimatePresence} from "framer-motion";
import Logo from "../logo.svg";

const AdminLayout = (props) => {
    const userInfo = useContext(AuthContext);
    const [toggle,setToggle] = useState(false);
    const [checked,setChecked] = useState(true);
    //check windows width & decide what
    useEffect(()=>{
        window.addEventListener('resize',()=>{
            if(window.innerWidth<=600){
                setChecked(false)
            }
        })
        return ()=>{
            window.removeEventListener('resize',()=>{})
        }
    },[setChecked])
    return (
        <div className='dashBoardLayout'>

            {checked?
                <AnimatePresence>
                    <motion.aside exit={{
                        opacity: 0,
                        x: -1000,
                    }} initial={{x:-1000}} animate={{x:0}} transition={{type:"spring", duration:.35}} className="dashBoardLayout_sidebar">

                        <figure className='disp_flex align_center'>
                            <Link to={'/'} className={'no-deco display_flex align-center'}>
                                <img src={Logo} className={'logo'} alt=""/>
                                <span className=" mgL2 h5 " style={{color:"#262626"}}>G7 <span className={'green'}>Capital</span></span>
                            </Link>
                        </figure>

                        <ul className="dashBoardLayout_sidebar-list">
                            <li className='dashBoardLayout_sidebar-list-item'>
                                <Link to='/admin'>

                                    <BiHomeAlt className='dashBoardLayout_sidebar-list-item-icon'/>  <span className={'mgL2'}>Home</span>
                                </Link>
                            </li>
                            <li className='dashBoardLayout_sidebar-list-item'>
                        <span onClick={()=>setToggle(!toggle)} >

                            <FiUserPlus  className='dashBoardLayout_sidebar-list-item-icon'/> <span className={'mgL2'}>User Accounts</span>
                            {/*<FiChevronDown onClick={()=>setToggle(!toggle)} className={'mgL2'} size={24}/>*/}
                        </span>
                                {toggle?<ul className="nav_sub-list">
                                    <li><Link to={'/register'}>Add New Account</Link></li>
                                </ul>:null}
                            </li>
                            <li className='dashBoardLayout_sidebar-list-item'>
                                <Link to={'/admin/users'}>
                                    <FiUsers className='dashBoardLayout_sidebar-list-item-icon'/>
                                    <span className={'mgL2'}>Users</span>
                                </Link>

                            </li>
                            <li className='dashBoardLayout_sidebar-list-item'>
                                <Link to='/admin/blog/all'>
                                    <BiNews className='dashBoardLayout_sidebar-list-item-icon'/>
                                    <span className={'mgL2'}>All Post</span>
                                </Link>
                            </li>
                            <li className='dashBoardLayout_sidebar-list-item'>
                                <Link to='/admin/blog'>
                                    <BiNews className='dashBoardLayout_sidebar-list-item-icon'/>
                                    <span className={'mgL2'}>Create Post</span>
                                </Link>
                            </li>

                            <li>
                                <ul className="dashBoardLayout_sidebar-list-bottom">


                                    {/*<li className='dashBoardLayout_sidebar-list-item'>*/}
                                    {/*    <Link to='/admin/card-management'>*/}
                                    {/*        <FiCreditCard className='dashBoardLayout_sidebar-list-item-icon'/>*/}
                                    {/*        <span className={'mgL2'}>Card Management</span>*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className='dashBoardLayout_sidebar-list-item'>*/}
                                    {/*    <Link to='/admin/loan-management'>*/}
                                    {/*        <BiMoney className='dashBoardLayout_sidebar-list-item-icon'/>*/}
                                    {/*        <span className={'mgL2'}>Loan Management</span>*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    <li className='dashBoardLayout_sidebar-list-item'>
                                        <Link to={'/admin/wallet-addresses'}>
                                            <BiWallet className='dashBoardLayout_sidebar-list-item-icon'/>
                                            <span className={'mgL2'}>Wallet Addresses</span>
                                        </Link>
                                        {/*<Link to='/admin/messages'>*/}
                                        {/*    <BiChat className='dashBoardLayout_sidebar-list-item-icon'/>*/}
                                        {/*    <span className={'mgL2'}>Message</span>*/}
                                        {/*</Link>*/}
                                    </li>
                                    <li className='dashBoardLayout_sidebar-list-item'>
                                        <Link to='/admin/allTransactions'>
                                            <BsCurrencyExchange className='dashBoardLayout_sidebar-list-item-icon'/>
                                            <span className={'mgL2'}>Transactions</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <ul className="dashBoardLayout_sidebar-list-bottom">
                                    <li className='dashBoardLayout_sidebar-list-item'>
                                        <Link to='/admin/reset-password'>
                                            <BiLock className='dashBoardLayout_sidebar-list-item-icon'/>
                                            <span className={'mgL2'}>Change Password</span>
                                        </Link>
                                    </li>
                                    {/*<li className='dashBoardLayout_sidebar-list-item'>*/}
                                    {/*    <Link to='/admin/settings'>*/}

                                    {/*        <BiCog className='dashBoardLayout_sidebar-list-item-icon'/>   <span>Settings</span>*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}

                                    <li className='dashBoardLayout_sidebar-list-item'>
                                <span onClick={userInfo.logOut}>

                                    <BiLogOut className='dashBoardLayout_sidebar-list-item-icon'/>
                                    <span>Log Out</span>
                                </span>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                    </motion.aside>

                </AnimatePresence>
                :null}
            <main className="dashBoardLayout_main">
                <SubNav checked={checked} setChecked={setChecked}/>
                <div className="dashBoardLayout_main-section">
                    {props.children}
                </div>
            </main>
        </div>
    );
};
function SubNav({checked,setChecked}) {
    const userInfo = React.useContext(AuthContext);
    const user = JSON.parse(userInfo.user);
   return <nav className="dashBoardLayout_main-topNav">
       <input checked={checked} onChange={()=>setChecked(!checked)} type="checkbox"  id={'dash-menu'}/>
       <label htmlFor="dash-menu" className={'dash-menu-holder'}>
           <span className="dash-menu-bars">&nbsp;</span>
       </label>

       {/*<Link to={'/'} className={'no-deco mob-logo'}>*/}
       {/*    <h4 className="h5">G7 <span className={'green'}>Capital</span></h4>*/}
       {/*</Link>*/}
       <ul className='dashBoardLayout_main-topNav-notify'>

           <li className='dashBoardLayout_main-topNav-notify-item'>
               <h3 className="light_color h5 text-center">
                   Hi, {user.firstName}
               </h3>
           </li>
           <li className='dashBoardLayout_main-topNav-notify-item pos_rel'>
               <h6 className="h6 redText" onClick={userInfo.logOut}>Log Out</h6>
           </li>
       </ul>
   </nav>
}
export default AdminLayout;