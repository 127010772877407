import React from 'react';
import HomeNavigation from "../../../components/HomeNavigation";
import token1 from "../../../assets/token2.gif";
import hero from "../../../assets/g7 house.jpg";
import token2 from "../../../assets/token3.jpg";
import {Link} from "react-router-dom";
import {CountDown} from "../../../components/countDown";
import FooterC from "../../../hoc/FooterC";

const BuyToken = () => {
    return (
        <div>
            <HomeNavigation/>
            {/*<header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0001),url(${hero2})`,height:'50vh'}}>*/}
<div className={'container'}>

    <div className="grid mgT2">
        {/*<p className="h5"><span className="green"></span> </p>*/}
     <div className="grid-item2_4 width80">
         <h1 className="h2 ">The G7 Capital Token</h1>
         <p className="h4 mgT2 shadow-Texts greyText">From G7 Token is a cryptocurrency token that powers all G7 protocols. It serves as a utility token</p>
         <Link to={'/register'} className={'btn btn-blue mgT2'}>Sign up &rarr;</Link>
     </div>
        <div className="grid-item2_4">
            <img src={hero} alt="" className={'fluid_img'}/>
        </div>
    </div>
</div>

            {/*</header>*/}

            <div className="container mgB4">
                <h3 className="h2 centerText">{CountDown({time:"Feb 25 2023"})}</h3>
                <p className="h5 greyText centerText">Counting down to launch</p>
            </div>
            <div className="container">
                <div className="grid">
                    <div className="grid-item2_4">
                       <h4 className="h3"> Invest in G7 Token The Blockchain Blueprint</h4>
                        <p className="h5 mgT2">G7 Token is a cryptocurrency token that powers all G7 protocols. It serves as a utility token, like any other cryptocurrency you can sell and buy on open markets. G7 Token comes with a wide range of benefits that help various investors looking to capitalize on the Crypto Market and Real Estate Market</p>
                    </div>
                    <div className="grid-item2_4 display_flex align-center">
                        <img src={token1} alt="" className={'fluid_img'}/>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="grid">
                    <div className="grid-item2_4 display_flex align-center">
                    <img src={token2} alt="" className={'fluid_img'}/>
                </div>
                    <div className="grid-item2_4">
                       <h4 className="h3">Allocation Division</h4>
                        <p className="h5 mgT2">G7 Token Trade At 0.01 (G7Token) To $1</p>
                        <p className="h5 mgT2">5% Sale</p>
                        <p className="h5 mgT2">4% Reserve Fund</p>
                        <p className="h5 mgT2">1% Marketing and Bounty</p>
                    </div>

                </div>
            </div>

<FooterC/>
        </div>
    );
};

export default BuyToken;