import React from 'react';
import AdminLayout from "../../../layout/AdminLayout";
import Table from "../../../hoc/Table";
import {AuthContext} from "../../../hoc/AuthContext";
import axiosInstance from "../../../helpers/axiosInstance";
import moment from "moment";

const Messages = () => {
    const userInfo = React.useContext(AuthContext);
    const [page,setPage] = React.useState(0)
    const [transactions,setTransactions] = React.useState([]);
    const getAll = React.useCallback(function () {
        axiosInstance.get(`/admin/allTransactions/${page}`,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                console.log(res.data.transactions)
                setTransactions(res.data.transactions);
            })
            .catch(err=>{
                console.log(err)
            })


    },[userInfo,setTransactions,page])

    function changePage(e) {

        if(e<0){
            setPage(0);
        }
        setPage(e);
    }

    function formatDate(value) {
        return  moment(value).format('MM DD YYYY')
    }
    React.useEffect(()=>{
        getAll();
        return ()=>{}
    },[getAll])
    return (
        <AdminLayout>
            <h3 className="h4 mgT4">MESSAGE MANAGEMENT</h3>

            <div className="mgT2">
                <Table
                    filters={true}
                    type='dark-table'
                    headers={['Account','Message','Admin Response',
                    'Status','Date','Action']}
                    body={
                        <>

                                        <tr key={Math.random()}>

                                                <td>1234567890</td>
                                                <td>Hello</td>
                                                <td>
                                                    <input type="text" placeholder={'response'}/>
                                                </td>
                                                <td>Delivered</td>
                                                <td>31th Aug 2022</td>
                                                <td className={'display_flex'}>
                                                    <button className="btn btn-green">Reply</button>
                                                    <button className="btn-red btn mgL2">Delete</button>
                                                </td>
                                        </tr>
                        </>
                    }



                />
            </div>
            <div className="paginator">
                <ul>
                    <li onClick={page!==0?()=>changePage((page - 1)):null} className={page===0?'inactiveP':null}>Prev</li>
                    <li>&nbsp;</li>
                    <li onClick={transactions.length!==0?()=>changePage((page + 1)):null} className={transactions.length === 0|| transactions.length<4?'inactiveP':null} >Next</li>
                </ul>
            </div>
        </AdminLayout>
    );
};

export default Messages;