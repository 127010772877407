import React from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
const SupportMsg = () => {
    // const tawkMessengerRef = useRef();

    // const handleMinimize =  () => {
    //     tawkMessengerRef.current.minimize();

    /*
    *
    * <!--Start of Tawk.to Script-->
<script type="text/javascript">
var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/6431d0544247f20fefea92fd/1gth9bkaf';

    * */
    // };
    return (
        <div>
            <TawkMessengerReact
                propertyId="6431d0544247f20fefea92fd"
                // ref={tawkMessengerRef}
                widgetId="1gth9bkaf"/>
        </div>
    );
};

export default SupportMsg;