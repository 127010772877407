import React from 'react';
import {Link} from "react-router-dom";
const WhyHome = () => {
    return (
        <div className='why-section mgT8 pd2'>

            <div className="container">
                <h4 className="h3 text-center light_color">What We Offer</h4>
                <p className="h5 grey-text-alt mgT2 centerText width-60" style={{margin:"2rem auto"}}>We are deeply committed to investing with our clients’ best interests at heart. We work hard every day to build and maintain our clients’ trust and confidence in us.</p>
                <div className="mgT4 grid flexWrap ">
                    <div className="grid-item1_3 card">
                        <div className="text-center">

                        </div>
                        <div className="flex_1_2 ">
                            <h3 className="h4 mgT1 grey-text">G7 capital Unit Trust Fund</h3>
                            <p className='h5 mgT2 grey-text'>Start a basic unit trust investment. You can invest in unit trusts for most of your financial goals, from saving for longer-term needs to meeting your shorter-term objectives. </p>
                            <Link to={'/trust-fund'} className="btn btn-green mgT2">Discover More</Link>
                        </div>
                    </div>
                    <div className="grid-item1_3 text-center card">
                        <div className="text-center">

                        </div>
                        <div className="flex_1_2 ">
                            <h3 className="h4 mgT1 grey-text">G7 capital Tax Free Investment</h3>
                            <p className='h5 mgT2 grey-text'>Tax-Free Investment Account allows you to earn a return from the unit trusts of your choice without being taxed on the income or capital gain. You can invest up to $30,000 per year and $5,000,000 over your lifetime.</p>
                            <Link to={'/tax-free'} className="btn btn-green mgT2">Discover More</Link>
                        </div>
                    </div>
                    <div className="grid-item1_3 text-right card">
                        <div className="text-center">
                        </div>
                        <div className="flex_1_2 mgLn2">
                            <h3 className="h4 mgT1 grey-text">Offshore Investment </h3>
                            <p className='h5 mgT2 grey-text'>Investing offshore allows you to spread your investment risk across different economies and regions. It also gives you access to industries and companies that may not be available locally.</p>
                            <Link to={'/offshore-investment'} className="btn btn-green mgT2">Discover More</Link>
                        </div>
                    </div>



                </div>

            </div>
        </div>
    );
};

export default WhyHome;