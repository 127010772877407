import React from 'react';

const InfiniteTestimony = () => {
    

    return (
        <div id='infiniteTest'  className='infiniteTestimony' >
             
        </div>
    );
};

export default InfiniteTestimony;