import React, {useEffect, useState} from 'react';
import Logo from '../logo.svg'
import {Link} from 'react-router-dom'
import broker from '../assets/aic-footer-bcheck.png';
import {motion} from "framer-motion";
import { FaInstagram, FaLinkedinIn} from "react-icons/fa";
const FooterC = () => {
    const [index,setIndex] = useState(0)
    useEffect(()=>{
       let time = setTimeout(()=>{
           if (index===1){
               setIndex(0)
           }else{
               setIndex(1)
           }
       },10000)

        return ()=> clearTimeout(time)
    },[index])
    return (
        <div className='footer'>
            <div className="container foot_news">
                <h2 className="h2 light_color centerText">Sign up for our newsletter</h2>
                <p className="h5 mgT2 grey-text centerText mgB2">Stay up to date with the roadmap progress, announcements and exclusive discounts feel free to sign up with your email.</p>
                <div className="display_flex align-center " >
                    <div className="form-input-box">
                        <input type="text" className={'form-input'} placeholder={"Enter your email"}/>
                    </div>
                    <button className="btn btn-green">Join</button>
                </div>
            </div>
          <div className="container grid">
              <Link to={'/'} className={'no-deco display_flex align-center'}>
                  <img src={Logo} className={'logo'} alt=""/>
              </Link>
              <div className="grid-item1_4">
                  <h5 className="h5 green">Company</h5>
                    <ul className="footer-list mgT4">
                        <li className="footer-list-item"><Link to={'/about'} className={'no-deco'}>Who we are</Link></li>
                        <li className="footer-list-item"><Link to={'/our-purpose'} className={'no-deco'}>How we Think</Link></li>
                        <li className="footer-list-item"><Link to={'/cash-management'} className={'no-deco'}>Cash Management</Link></li>
                        <li className="footer-list-item"><Link to={'/about'} className={'no-deco'}>Investor Relations</Link></li>

                    </ul>
              </div>
              <div className="grid-item1_4">
                  <h5 className="h5 green">Resources</h5>
                    <ul className="footer-list mgT4">
                        <li className="footer-list-item"><Link to={'/tokenized-investments'} className={'no-deco'}>G7 Token</Link></li>
                        <li className="footer-list-item"><Link to={'/'} className={'no-deco'}>Contact & Support</Link></li>
                        <li className="footer-list-item"><Link to={'/privacy_policy'} className={'no-deco'}>Privacy Policy</Link></li>
                        <li className="footer-list-item"><Link to={'/terms_conditions'} className={'no-deco'}>Terms & conditions</Link></li>
                    </ul>
              </div>
              <div className="grid-item1_4">
                  <h5 className="h5 green">Documentation</h5>
                    <ul className="footer-list mgT4">
                        {/* <li className="footer-list-item"><a target={"_blank"} rel={"noreferrer"} href={'https://brokercheck.finra.org/search/genericsearch/grid'} className={'no-deco'}>Member: FINRA / SPIC</a></li>
                        <li className="footer-list-item"><Link to={'/'} className={'no-deco'}>Brochure</Link></li> */}
                        <li className="footer-list-item"><Link to={'/'} className={'no-deco'}>CBCA (Canada Business Corporations Act)</Link></li>
                        <li className="footer-list-item"><Link to={'/'} className={'no-deco'}>ACORD COLI</Link></li>
                        <li className="footer-list-item"><Link to={'/'} className={'no-deco'}>Certificate of Incorporation</Link></li>
                        <li className="footer-list-item"><Link to={'/'} className={'no-deco'}>G7 Capital LITEPAPER</Link></li>
                        <li className="footer-list-item"><Link to={'/'} className={'no-deco'}>G7 Business plan</Link></li>

                    </ul>
              </div>

              <div className="grid-item1_4 ">
                 <h6 className="h5 green">Get in Touch</h6>
                  <motion.div className="footer_address">
                      {
                          index===0?<>
                              <p className="h6 mgT2">Kansas - PO Box 419200, MO 64141 - 6200 Kansas City.</p>
                              <p className="h6 mgT2">Corporate Headquarters:
                                  Idaho - 46 Eagle River St, #100 Eagle , 83616 Idaho</p></>:<>
                              <p className="h6 mgT2">11650 131st Street North Largo, FL 33774.</p>
                              <p className="h6 mgT2">235 Hood Road, Markham ON
L3R 4N3, Canada.</p>
                          </>
                      }
                      <p className="h6 mgT2">Cell: +1 (510) 603-4385</p>

                  </motion.div>

              </div>
          </div>
         {/*<div className="container">*/}
             <hr className="hr_g"/>
         {/*</div>*/}
            <div className="foot_bottom">
                {/* <div className="">
                    <a href={'https://brokercheck.finra.org/search/genericsearch/grid'} target={"_blank"} rel={"noreferrer"} className="mgT4">
                        <img alt={''} src={broker} className="width25"/>
                    </a>
                </div> */}
                <div className=" foot_social">

                    <a href="https://search.sunbiz.org/Inquiry/corporationsearch/SearchResultDetail?inquirytype=EntityName&directionType=Initial&searchNameOrder=G7CAPITAL%20L160002088100&aggregateId=flal-l16000208810-ea2097c0-d17e-4231-80c3-aa625faeeb58&searchTerm=G7%20CAPITAL%2C%20LLC&listNameOrder=G7CAPITAL%20L160002088100" target={"_blank"} rel={"noreferrer"} className={'no-deco foot_social-item h7'}>G7 Capital</a>
                    {/*<a href="/" target={"_blank"} rel={"noreferrer"} className={'no-deco foot_social-item'}><FaFacebookF/></a>*/}
                    {/*<a href="/" target={"_blank"} rel={"noreferrer"} className={'no-deco foot_social-item'}><FaTwitter/></a>*/}
                    <a href="https://www.instagram.com/invites/contact/?i=okye2egohvpk&utm_content=40tlivj" target={"_blank"} rel={"noreferrer"} className={'no-deco foot_social-item'}><FaInstagram/></a>
                    <a href="https://www.linkedin.com/company/g7capital-org/" target={"_blank"} rel={"noreferrer"} className={'no-deco foot_social-item'}><FaLinkedinIn/></a>
                </div>
            </div>
        </div>
    );
};

export default FooterC;