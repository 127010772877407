import React from 'react';
import FooterC from "../../../hoc/FooterC";
import hero2 from '../../../assets/culture.jpg'
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
// import pimg from '../../../assets/calm-waters-clouds-cold-910310.jpg'
import pimg2 from '../../../assets/architectural-design-architecture-buildings-936722.jpg'
import library from '../../../assets/library.jpg'
import culture__ from '../../../assets/culture__.jpg'
import gender_ from '../../../assets/gender.jpg'
import sa from '../../../assets/sa.jpg'
import FaqSection from "../home/Faq-section";
import HomeNavigation from "../../../components/HomeNavigation";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";

const CulturePage = () => {
    useScrollToTop();
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
              <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`}}>

                  <div className=" width35 text-center mg2">
                      <h4 className="h1 light_color">Our Culture</h4>
                  </div>
              </header>
            <div className="mgT8">
                <div className={'container grid'}>
                    <div className="grid-item2_4">
                        <h1 className="h2">Our Culture</h1>
                        <p className="h5 mgT4 " style={{color:'rgba(113,113,113,1)'}}>  The culture of G7 Capital reflects who we are as people. It is our shared values and norms that make us. We believe the strength of our culture is a source of competitive advantage, ensuring a healthy environment for debate and exchange of views. This is the foundation for our pursuit of enduring investment performance and outstanding client service. Above all, our culture embodies our overriding value – to do the right thing.</p>

                        <div className="mgT4">
                           <div className="grid">

                               <div className="grid-item2_4">
                                   <h6 className="h5 ">Our Philosophy</h6>
                                   <p className={' h6 mgT2'}>
                                       Every person’s freedom to create is a defining element of the philosophy which drives our strong culture.

                                       We steadfastly believe in giving individuals the freedom to be themselves. We are creating a culture where we can collectively achieve together without sacrificing our individual selves.
                                   </p>
                               </div>
                               <p className={'grid-item2_4 h6'}>
                                   Our people perform best when they are liberated to pursue their passions and interests. We strive to give people the freedom to express their strengths, skills and talents. Freedom to create is a crucial driver of diversity in our business as it is only through the expression of individuality and one’s potential that we can be truly diverse.
                               </p>
                           </div>
                        </div>
                    </div>
                    <div className="grid-item2_4">
                        <img src={library} className={'fluid_img'} alt={''}/>
                        </div>
                </div>
            </div>
            <div className="mgT8 " style={{backgroundColor:'#f7f7f7'}}>
                <div className="container grid">
                    <div className="grid-item2_4">
                        <img src={pimg2} className={'fluid_img'} alt={''}/>
                    </div>
                    <div className="grid-item2_4">
                    <h5 className="h2 width50">Results and relationships</h5>
                        <p className="h6 mgT4">If freedom to create sits at the core of our culture, valuing strong relationships encircles that core. Strong relationships breed diversity in our business and an environment where all people feel welcome, respected and know they have a fair opportunity to develop and contribute. We expect people to perform both on the results they deliver and the quality of their relationships. These are important measures of our culture.</p>
                    </div>


                </div>
            </div>
            <div className="mgT8 ">
                <div className="container grid">

                    <div className="grid-item2_4">
                    <h5 className="h2 width50">Diversity & inclusion</h5>
                        <p className="h6 mgT4">Encouraging diversity and inclusion forms an integral part of our firm’s recruitment, development and retention strategy. A diverse team of people ensures we approach all decision-making with a set of different perspectives. We believe this can be a competitive advantage, helping us achieve the best outcomes for our clients, our communities, our colleagues and the wider world in which we work.

                            We are creating an environment where everyone can be themselves and have the opportunity to build a successful career, regardless of who they are, or their background.</p>
                    </div>
                    <div className="grid-item2_4">
                        <img src={culture__} className={'fluid_img'} alt={''}/>
                    </div>


                </div>
            </div>
            <div className="mgT8 " style={{backgroundColor:'#f7f7f7'}}>
                <div className="container grid">
                    <div className="grid-item2_4">
                        <img src={sa} className={'fluid_img'} alt={''}/>
                    </div>
                    <div className="grid-item2_4">
                        <h5 className="h2 width50">Transformation in South Africa</h5>
                        <p className="h6 mgT4">We are determined to play our part in building a South Africa in which all South Africans have a fair chance to succeed. We also believe diversity is essential to our firm’s ability to compete, adapt and remain relevant. Our approach is to focus on building talent organically, by recruiting and developing investment professionals early in their careers.</p>
                    </div>


                </div>
            </div>
            <div className="mgT8 ">
                <div className="container grid">

                    <div className="grid-item2_4">
                        <h5 className="h2">Gender diversity</h5>
                        <p className="h6 mgT4">We are committed to attracting, developing and retaining a gender diverse team. Ensuring our workforce reflects the clients we work with and the societies we operate in helps us approach decision-making with diverse perspectives.</p>
                    </div>
                    <div className="grid-item2_4">
                        <img src={gender_} className={'fluid_img'} alt={''}/>
                    </div>


                </div>
            </div>
            <div className="mgV4 container">
                <h4 className="h4">Frequently Asked Questions</h4>
                <p className="h6 mgT1">Have any Questions?</p>
            <FaqSection/>
            </div>
             <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default CulturePage;