import React from 'react';
import hero2 from "../../../assets/skyc.jpg";
import old from "../../../assets/olf_people.jpg";
import HomeNavigation from "../../../components/HomeNavigation";
import {Link} from "react-router-dom";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import TopBar from "../../../components/topBar";

const TaxFree = () => {
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`}}>

                <div className=" width35 text-center mg2">
                    <h1 className="h2 light_color">G7 <span className="green">Capital</span> Tax Free Investment</h1>

                </div>
            </header>
            <div className="mgT4">
                <div className={'container'}>
                   <h6 className="h4 centerText">G7capital Retirement Plan</h6>
                   <div className="greyCard mgT2 grid">
                     <div className="grid-item2_4">
                         <p className="h5">
                             We offer solutions, resources, tools, and guidance to help you develop and manage your retirement strategy. And our knowledgeable representatives will be here every step of the way to help you plan your journey towards your financial future, so you can relax and focus on today. Connect with us today let’s talk about your financial goals.
                         </p>
                         <h6 className="h5 green mgT2">Find the IRA that's right for you</h6>
                         <p className="h6 mgT2">We can help you invest for retirement with a wide range of choices. Whether you need a Traditional or Roth IRA, we have the solution for you.
                         </p>
                         <h6 className="h5 green mgT2">Keep track of your accounts with managed portfolios</h6>
                         <p className="h6 mgT2">From automated investing to customized portfolio management, we'll help find a level of involvement that's right for you.</p>

                         <h6 className="h5 green mgT2">Explore retirement income solutions</h6>
                         <p className="h6 mgT2">Generate ideas to help make your retirement last for the essential and Not-so-essential needs in life. The idea is to build a retirement portfolio with a mix of these products. </p>

                         <h6 className="h5 green mgT2">Savings</h6>
                         <p className="h6 mgT2">
                             Money to fund a comfortable retirement is perhaps the biggest reason people invest. As such, finding the right balance between risk and investment return is key to a successful retirement savings strategy.
                         </p>
                         <p className="h6 mgT2">
                             Here is a suggestion for ensuring you make the smartest possible decisions with your retirement savings:
                         </p>
                         <p className="h6 mgT1">One common way to create retirement income is to create a managed portfolio with G7capital.
                             The portfolio is designed to achieve a respectable long-term rate of return. G7capital creates an excellent platform for retirees.
                         </p>
                     </div>
                       <div className="grid-item2_4">
                           <img src={old} alt="" className={'fluid_img'}/>
                       </div>
                   </div>
                </div>
                <div className={'container centerText'}>
                    <h1 className="h3">G7 <span className="green">Capital</span>  Investor Tax Free Investment</h1>
                    <p className="h5 center-container-60 mgT4">Start a basic Plan, You can invest in unit trusts for most of your financial goals, from saving for longer-term needs to meeting your shorter-term objectives.</p>

                    <Link to={'/login'} className={'btn btn-green mgT4'}>Get Started</Link>
                </div>
            </div>

            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default TaxFree;