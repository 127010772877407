import React from 'react';
import DashboardLayout from "../../../layout/dashboardLayout";
import {DepositToWallet} from "./wallet";
// import {CurrencyCards} from "../clientHome";

const DepositPage = () => {

    //deposit will have an option to use card or wire transfer
    return (

        <DashboardLayout>
            {/*<CurrencyCards/>*/}
          <div className="outlined-card mgT4">
              <DepositToWallet/>
          </div>
        </DashboardLayout>
    );
};

export default DepositPage;