import React from 'react';
import hero2 from "../../../assets/crypto.jpg";
import HomeNavigation from "../../../components/HomeNavigation";
import pimg from "../../../assets/tk00.jpg";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";

const Stocks = () => {
    useScrollToTop();
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`,height:'70vh'}}>

                <div className=" width35 text-center mg2">
                    <h1 className="h2 light_color">G7 <span className="green">Capital</span> Stocks</h1>



                </div>
            </header>
            <div className="mgT8 " >
                <div className="container grid">

                    <div className="grid-item2_4">
                        <h5 className="h4 ">What is the Stock Market?</h5>
                        <p className="h5 mgT4">The stock market is where buyers and sellers come together to trade shares in eligible companies.</p>

                        <hr className={'hr mgV2'}/>
                        <h6 className="h5 mgT4">Understanding the stock market</h6>
                        <p className="h5 mgT2">Stocks are bought and sold on stock markets, which bring together buyers and sellers of shares in publicly traded companies. Stock markets operate kind of like auctions, with potential buyers naming the highest price they’re willing to pay (“the bid”) and potential sellers naming the lowest price they’re willing to accept (“the ask”). The actual execution of a trade price will be somewhere at or between the bid and the ask. Trades can be placed by stockbrokers, usually on behalf of portfolio managers or individual investors like you. In the US, the stock market is made up of 13 exchanges—the best known are the New York Stock Exchange and the Nasdaq.</p>

                    </div>
                    <div className="grid-item2_4">
                        <img src={pimg} className={'fluid_img'} alt={''}/>
                    </div>


                </div>
            </div>

            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default Stocks;