import React, {useState,useContext,useEffect} from 'react';
import {
    BiHomeAlt,
    BiLogOut,
    // BiCog,
    // BiNews,
    // BiMoney,
    // BiMobile,
    // BiLock,
    // BiChat,
    // BiCreditCard,
    BiArrowToLeft,
    // BiArrowFromRight,
    BiArrowToRight,
    BiFile,
    // BiShare,
    BiWallet, BiShield,
} from "react-icons/bi";
// import {FiUsers,  FiCreditCard} from "react-icons/fi";
import {Link} from "react-router-dom";
// import avatar from '../assets/avatar.png'
import {AuthContext} from "../hoc/AuthContext";
import {BsCurrencyExchange} from "react-icons/bs";
import {motion,AnimatePresence} from "framer-motion";
import {BiUser} from "react-icons/bi";
import Logo from "../logo.svg";

const ClientLayout = (props) => {

    const [checked,setChecked] = useState(true);

    const userInfo = useContext(AuthContext);
    useEffect(()=>{
        if(window.innerWidth<=600){
            setChecked(false)
        }
        window.addEventListener('resize',()=>{
            if(window.innerWidth<=768){
                setChecked(false)
            }else{
                setChecked(true)
            }
        })
        return ()=>{
            window.removeEventListener('resize',()=>{})
        }
    },[setChecked])
    return (
        <div className='dashBoardLayout'>

            {checked?
                <AnimatePresence>
                    <motion.aside exit={{
                        opacity: 0,
                        x: -1000,
                    }} initial={{x:-1000}} animate={{x:0}} transition={{type:"spring", duration:.35}} className="dashBoardLayout_sidebar">

                        <figure className='disp_flex align_center'>
                            <Link to={'/'} className={'no-deco display_flex align-center'}>
                                <img src={Logo} className={'logo'} alt=""/>
                                <span className=" mgL2 h5  " style={{color:"#262626"}}>G7 <span className={'green'}>Capital</span></span>
                            </Link>
                        </figure>

                        <ul className="dashBoardLayout_sidebar-list">
                            <li className='dashBoardLayout_sidebar-list-item'>
                                <Link to='/client'>

                                    <BiHomeAlt className='dashBoardLayout_sidebar-list-item-icon'/>
                                    <span className={'mgL2'}>Overview</span>
                                </Link>
                            </li>

                            {/*<li className='dashBoardLayout_sidebar-list-item'>*/}
                            {/*    <Link to='/client/invest'>*/}
                            {/*        <BiMoney className='dashBoardLayout_sidebar-list-item-icon'/>*/}
                            {/*        <span className={'mgL2'}>Invest now</span>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li className='dashBoardLayout_sidebar-list-item'>
                                <Link to='/client/wallet'>
                                    <BiWallet className='dashBoardLayout_sidebar-list-item-icon'/>
                                    <span className={'mgL2'}>Wallet</span>
                                </Link>
                            </li>
                            <li className='dashBoardLayout_sidebar-list-item'>
                                <Link to='/client/transactions'>
                                    <BsCurrencyExchange className='dashBoardLayout_sidebar-list-item-icon'/>
                                    <span className={'mgL2'}>Transactions</span>
                                </Link>
                            </li>
                            <li>
                                <ul className="dashBoardLayout_sidebar-list-bottom">

                                    <li className='dashBoardLayout_sidebar-list-item'>
                                        <Link to='/client/deposit'>

                                            <BiArrowToRight className='dashBoardLayout_sidebar-list-item-icon'/>   <span>Deposit</span>
                                        </Link>
                                    </li>

                                    <li className='dashBoardLayout_sidebar-list-item'>
                                        <Link to='/client/withdrawal'>

                                            <BiArrowToLeft className='dashBoardLayout_sidebar-list-item-icon'/>   <span>Withdrawal</span>
                                        </Link>
                                    </li>

                                    <li className='dashBoardLayout_sidebar-list-item'>
                                         <Link to={'/insurance'}>

                                         <BiShield className='dashBoardLayout_sidebar-list-item-icon'/>
                                         <span>Insurance</span>
                                        </Link>
                                    </li>

                                    <li className='dashBoardLayout_sidebar-list-item'>
                                         <Link to={'/client/exchange-coins'}>

                                         <BsCurrencyExchange className='dashBoardLayout_sidebar-list-item-icon'/>
                                         <span>Exchange coins</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <ul className="dashBoardLayout_sidebar-list-bottom">

                                    <li className='dashBoardLayout_sidebar-list-item'>
                                        <Link to='/client/statement'>

                                            <BiFile className='dashBoardLayout_sidebar-list-item-icon'/>   <span>Statement</span>
                                        </Link>
                                    </li>
                                    <li className='dashBoardLayout_sidebar-list-item'>
                                        <Link to='/client/referral'>

                                            <BiFile className='dashBoardLayout_sidebar-list-item-icon'/>   <span>Referral</span>
                                        </Link>
                                    </li>

                                    {/*<li className='dashBoardLayout_sidebar-list-item'>*/}
                                    {/*    <Link to='/client/referral'>*/}

                                    {/*        <BiShare className='dashBoardLayout_sidebar-list-item-icon'/>   <span>Referral</span>*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}

                                    {/*<li className='dashBoardLayout_sidebar-list-item'>*/}
                                    {/*    <Link to='/client/settings'>*/}

                                    {/*        <BiCog className='dashBoardLayout_sidebar-list-item-icon'/>   <span>Settings</span>*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}

                                    <li className='dashBoardLayout_sidebar-list-item'>
                                <span onClick={userInfo.logOut}>

                                    <BiLogOut className='dashBoardLayout_sidebar-list-item-icon'/>
                                    <span>Log Out</span>
                                </span>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                    </motion.aside>

                </AnimatePresence>
                :null}
            <main className="dashBoardLayout_main">
                <SubNav checked={checked} setChecked={setChecked}/>
                <div className="dashBoardLayout_main-section">
                    {props.children}
                </div>
            </main>
        </div>
    );
};
function SubNav({checked,setChecked}) {
    const [subNav,showSubNav] = useState(false)
    const userInfo = useContext(AuthContext);
    const user = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
    return <nav className="dashBoardLayout_main-topNav">
        <input checked={checked} onChange={()=>setChecked(!checked)} type="checkbox"  id={'dash-menu'}/>
        <label htmlFor="dash-menu" className={'dash-menu-holder'}>
            <span className="dash-menu-bars">&nbsp;</span>
        </label>
        <ul className='dashBoardLayout_main-topNav-notify'>

            <li className='dashBoardLayout_main-topNav-notify-item'>
                <h3 className="light_color h5 text-center">
                    Hi, {user.firstName}
                </h3>
            </li>

            <li className='dashBoardLayout_main-topNav-notify-item pos_rel'>
                <div className="avatar-box" onClick={()=>showSubNav(!subNav)}>
                    <BiUser/>
                </div>
                {
                    subNav?  <ul className={'subNav'}>
                        <li><Link to={'/client/kyc'} className="h6 grey-text" style={{textDecoration:"none"}}>Edit Profile</Link></li>
                        <li><Link to={'/client/kyc'} className="h6 grey-text" style={{textDecoration:"none"}}>Identity / KYC</Link></li>

                        <li><h6 className="h6 redText" onClick={userInfo.logOut}>Log Out</h6></li>
                    </ul>:null
                }
            </li>
        </ul>
    </nav>
}
export default ClientLayout;