import React,{Component} from 'react'

class CryptoChart extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        const script = document.createElement('script');
        script.src = 'https://widget.nomics.com/embed.js'
        script.async = false;
        this.myRef.current.appendChild(script);
    }

    render() {
        return(
     
            <div ref={this.myRef} className=' container'>
               <div className="content-center width100">
                   <div className="nomics-ticker-widget "  style={{
                       backgroundColor:'#000000'
                   }} data-name="TokenizedInvestments" data-base="BTC" data-quote="USD"></div>
               </div>
            </div>
           
        );
    }
}

export default CryptoChart;