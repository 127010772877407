import React from 'react';
import hero2 from "../../../assets/gold.jpg";
import HomeNavigation from "../../../components/HomeNavigation";
import pimg2 from "../../../assets/architectural-design-architecture-buildings-936722.jpg";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";

const GoldPage = () => {
    useScrollToTop()
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`,height:'50vh'}}>

                <div className=" width35 text-center mg2">
                    <p className="h5"><span className="light_color">OUR PRODUCTS</span> </p>
                    <h1 className="h2 light_color">
                        Gold</h1>

                </div>
            </header>
            <div className="mgT8 container " >
                <div className=" grid">

                    <div className="grid-item2_4">
                        <h5 className="h4 ">Supercharge your Investing</h5>
                        <p className="h5 mgT4">Access research reports, trade on margin at a 12.5% annual rate, and make bigger deposits with quicker access to funds—all starting $500 per month.</p>

                    </div>
                </div>
                <hr className={'hr mgV2'}/>
            </div>
            <div className=" mgB2" >
                <div className="center-container grid">

                    <div className="grid-item2_4">
                        <h5 className="h4 ">Bigger Instant Deposits</h5>
                        <p className="h5 mgT4">With GreatAmerican Investor Gold, you can get instant access to $5k – $50k when you make a deposit, depending on your account balance.</p>

                        <hr className={'hr mgV2'}/>
                        <h5 className="h4 ">Professional Research</h5>
                        <p className="h5 mgT4">We’ve worked with Morningstar to provide unlimited access to in-depth stock research reports on around 1700 stocks.</p>
                        <hr className={'hr mgV2'}/>
                        <h5 className="h4 ">Access to investing on margin</h5>
                        <p className="h5 mgT4">Investing on Margin gives you more flexibility, extra buying power, and less time waiting to access your deposited funds</p>

                    </div>
                    <div className="grid-item2_4">
                        <img src={pimg2} className={'fluid_img'} alt={''}/>
                    </div>


                </div>
            </div>

            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default GoldPage;