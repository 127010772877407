import React, {useState, useContext, useCallback, useEffect, useRef} from 'react';
import DashboardLayout from "../../../layout/dashboardLayout";
import Table from "../../../hoc/Table";
import axiosInstance from "../../../helpers/axiosInstance";

import {AuthContext} from "../../../hoc/AuthContext";
import {toast, ToastContainer} from "react-toastify";
import Moment from "react-moment";
import {numberWithCommas} from "../../../helpers/helpers";
import {clipboard} from "../../../helpers/clipboard";
import {Link} from "react-router-dom";
// import {AuthContext} from "../../../hoc/AuthContext";

const Referral = () => {
    const [code,setCode] = useState('')
    const [ref,setRef] = useState({refs:[]})
    const [wall,setWall] = useState({})
    const userInfo = useContext(AuthContext);
    const user = JSON.parse(userInfo.user)
    const copyText = useRef(null)
   async  function copy2Clipboard() {

        // Copy the text inside the text field
       await clipboard(`https://g7capital.org/register/?referral=${code}`);

        // Alert the copied text
        toast.success(`Copied https://g7capital.org/register/?referral=${code}` );
    }
   function getRef(){
      axiosInstance.post('/ref/create',
          {owner: user._id},{
          headers:{
              'authorization': userInfo.token
          }
      })
           .then((res)=>{
               setCode(res.data.code)
               copy2Clipboard()
           })
           .catch((err)=>{
               toast.error(err.response.data.msg)
           })
   }

   const getDefaultRefs = useCallback(()=>{
       axiosInstance.get(`/ref/get/${user._id}`,
          {
               headers:{
                   'authorization': userInfo.token
               }
           })
           .then((res)=>{
               setRef(res.data.data);
               setWall(res.data.wallet);
               setCode(res.data.data.code)
               console.log(res.data)
           })
           .catch((err)=>{
               toast.error(err.response.data.msg)
           })
   },[ user._id, userInfo.token])
    useEffect(()=>{
        getDefaultRefs();
        return ()=>{}
    },[getDefaultRefs])
    return (
        <DashboardLayout>
            <ToastContainer/>
            <h2 className="h4">Referral Code & Coupons</h2>
            <p className="h5 mgT1">Refer a friend and get g7 token</p>
            <div className="altCard mgT2 grid">
              <div className="grid-item2_4">
                  <div className="form-input-box">
                      <input ref={copyText} value={`https://g7capital.com/register/?referral=${code}`} className="h5 light_color form-input"/>
                  </div>
                  <button onClick={getRef} className="btn btn-green mgT2">Get Referral code</button>
                  {
                      code.length>0?
                          <Link target={"_blank"} rel={'noreferrer'} to={`/register/?referral=${code}`} className="btn btn-blue mgT2 mgL2">Referral link</Link>

                          :null
                  }
              </div>
                <div className="grid-item2_4">
                    <p className="h6 light_color">Total G7 Token Earned</p>
                    <h4 className="h3 light_color mgT2">{numberWithCommas(wall?.g7Token)} Tokens</h4>
                </div>
            </div>

            <h6 className="h6 mgT4">My referrals</h6>
            <div className="mgT2 outlined-card">

                <Table type={'dark-table'} headers={['Name','Email','Date','Status']} body={ref?.refs.length>0? ref.refs.map(e=><React.Fragment key={e._id}>
<tr >
    <td>{e.firstName}</td>
    <td>{e.email}</td>
    <td><Moment format={"MM DD YYYY, HH:mm"}>{ref.createdAt}</Moment></td>
    <td>Active</td>
</tr>
                    </React.Fragment>):
                    <tr>
                        <td className={'emptyTable'}>No Referrals</td>
                    </tr>}/>
            </div>

        </DashboardLayout>
    );
};

export default Referral;