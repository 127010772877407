import React, {useEffect, useState,useContext} from 'react';
import AdminLayout from "../../../layout/AdminLayout";
import {AuthContext} from "../../../hoc/AuthContext";
import axiosInstance from "../../../helpers/axiosInstance";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../../components/loader";
import Modal from "../../../components/Modal";
import Table from "../../../hoc/Table";
import {numberWithCommas} from "../../../helpers/helpers";
import Moment from "react-moment";
import {useNavigate, useParams} from "react-router-dom";

const AdminStatement = () => {
    const [showEdit,setShowEdit] = useState(false);
    const [showDel,setShowDel] = useState(false);
    const [loading,setLoading] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [editAcc,setEditAcc] = useState(false);
    const [searchText,setSearchText] = useState('');
   const navigate = useNavigate();
    const userInfo = useContext(AuthContext);
    // const user = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
    const [user_,setUser_] = useState({
        firstName:'',
        lastName:"",
        dob:"",
        wallet:{
            totalBalance:0,
            ethBalance:0,
            btcBalance:0,
            xrpBalance:0,
            usdtBalance:0,
            bnbBalance:0
        }
    });
    const [current,setCurrent] = useState({
        amount:0,
        owner:""
    });
    const {id} = useParams();

    function changeAcc(e) {
        e.preventDefault();
        setLoading(true)
        axiosInstance.post(`/sup/misc/update/`,{...user_.wallet},{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
                setTimeout(()=>{
                    setEditAcc(false)
                },1000)
            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })
    }
    const [transactions,setTransactions] = React.useState([]);
    function searchQuery(e) {
        e.preventDefault();
        axiosInstance.post(`/sup/trx/search/?q=${searchText}`,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                console.log(res.data)
                setTransactions(res.data.data);
            })
            .catch(err=>{
                console.log(err)
            })
    }
    const getAll = React.useCallback(function () {
        axiosInstance.get(`/sup/trx/get/all/${id}`,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setTransactions(res.data.data);
            })
            .catch(err=>{
                console.log(err)
            })


    },[userInfo.token,setTransactions,id])
    const getUser = React.useCallback(function () {
        axiosInstance.get(`/sup/acc/user/${id}`,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                // console.log(res.data)
                setUser_(res.data.data);
            })
            .catch(err=>{
                navigate('/client')
            })


    },[userInfo.token,setUser_,id,navigate])
    function updateTrx(e) {
        e.preventDefault();
        setLoading(true)
        axiosInstance.post(`/sup/trx/update/`,current,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
                setTimeout(()=>{
                    setShowEdit(false)
                },1000)
            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })
    }
    function deleteTrx() {
        setLoading(true)
        axiosInstance.delete(`/sup/trx/delete/${current._id}`,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
                setTimeout(()=>{
                    setShowDel(false)
                },1000)
            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })
    }
    useEffect(()=>{
        if (searchText.length===0){
            getAll();
        }
        return ()=>{}
    },[getAll,refresh,searchText])
    useEffect(()=>{
     getUser();
        return ()=>{}
    },[getUser])
    return (
        <AdminLayout>
            {
                loading?<Loader/>:null
            }
            <ToastContainer/>
            <h3 className="h4">Users Statement of account</h3>
            {
                showDel?<Modal closeModal={setShowDel}>
                    <h5 className="h5">Are you sure you want to delete?</h5>
                    <button className="btn btn-red mgT2" onClick={deleteTrx}>Delete</button>
                </Modal>:null
            }
            {
                editAcc?
                    <Modal closeModal={setEditAcc}>
                        <h5 className="h5">Edit Account</h5>
                        <form onSubmit={changeAcc} className="mgT2">
                            <div className="form-input-box">
                                <input type="text" placeholder={'total balance'} value={user_.wallet.totalBalance||""} onChange={(e)=>{
                                    const _old = {...user_} ;_old.wallet.totalBalance = e.target.value;setUser_(_old)}}/>
                            </div>
                            <div className="form-input-box">
                                <input type="text" placeholder={'ethereum balance'}  value={user_.wallet.ethBalance||""} onChange={(e)=>{
                                    const _old = {...user_} ;_old.wallet.ethBalance = e.target.value;
                                    setUser_(_old)}}/>
                            </div>
                            <div className="form-input-box">
                                <input type="text" placeholder={'btc balance'}  value={user_.wallet.btcBalance||""} onChange={(e)=>{
                                    const _old = {...user_} ;_old.wallet.btcBalance = e.target.value;
                                    setUser_(_old)}}/>
                            </div>
                            <div className="form-input-box">
                                <input type="text" placeholder={'xrp balance'}  value={user_.wallet.xrpBalance||""} onChange={(e)=>{
                                    const _old = {...user_} ;_old.wallet.xrpBalance = e.target.value;
                                    setUser_(_old)}}/>
                            </div>
                            <div className="form-input-box">
                                <input type="text" placeholder={'usdt balance'}  value={user_.wallet.usdtBalance||""} onChange={(e)=>{
                                    const _old = {...user_} ;_old.wallet.usdtBalance = e.target.value;
                                    setUser_(_old)}}/>
                            </div>
                            <div className="form-input-box">
                                <input type="text" placeholder={'BNB balance'}  value={user_.wallet.bnbBalance||""} onChange={(e)=>{
                                    const _old = {...user_} ;_old.wallet.bnbBalance = e.target.value;
                                    setUser_(_old)}}/>
                            </div>
                            <button className="btn btn-blue mgT2">Save</button>
                        </form>
                    </Modal>:null
            }
            <div className="mgT2">
                <div className="outlined-card">

                    <div className="grid">
                        <div className="grid-item2_4">
                            <p className="h7 grey-text">Full Name</p>
                            <h5 className="h5">{user_.firstName} {user_.lastName}</h5>
                            <p className="h7 grey-text">Email</p>
                            <h5 className="h5">{user_?.email}</h5>
                            <p className="h7 grey-text mgT2">Date of Birth</p>
                            <h5 className="h5">
                                <Moment format={"DD MM YYYY"}>
                                    {user_.dob}
                                </Moment>
                        </h5>
                        </div>
                        <div className="grid-item1_4">
                            <p className="h7 grey-text">Address</p>
                            <h5 className="h5">{user_?.address} {user_?.state}</h5>
                            <p className="h7 grey-text">State</p>
                            <h5 className="h5"> {user_?.state}</h5>
                            <p className="h7 grey-text">City</p>
                            <h5 className="h5">{user_?.city}</h5>
                        </div>
                        <div className="grid-item1_4">
                            <p className="h7 grey-text">Wallet Address</p>
                            <h5 className="h6">{user_?.walletAddress}</h5>
                            <p className="h7 grey-text">Zip Code</p>
                            <h5 className="h5"> {user_?.zipCode}</h5>
                            <p className="h7 grey-text">Phone</p>
                            <h5 className="h5">{user_?.phone}</h5>
                        </div>
                    </div>
                    <hr className="hr mgV2"/>
                    <div className="grid">
                        <div className="grid-item1_4 altCard">
                            <h6 className="grey-text h7">Account Bal.</h6>
                            <h5 className="h5 light_color">${numberWithCommas(user_.wallet.totalBalance)}</h5>
                        </div>
                    </div>
                    <div className="grid mgT2">

                        <div className="grid-item1_4 altCard">
                            <h6 className="grey-text h7">BTC Bal.</h6>
                            <h5 className="h5 light_color">{user_?.wallet?.btcBalance}</h5>
                        </div>
                        <div className="grid-item1_4 altCard">
                            <h6 className="grey-text h7">Eth Bal.</h6>
                            <h5 className="h5 light_color">{user_?.wallet?.ethBalance}</h5>
                        </div>
                        <div className="grid-item1_4 altCard">
                            <h6 className="grey-text h7">XRP Bal.</h6>
                            <h5 className="h5 light_color">{user_?.wallet?.xrpBalance}</h5>
                        </div>
                        <div className="grid-item1_4 altCard">
                            <h6 className="grey-text h7">USDT Bal.</h6>
                            <h5 className="h5 light_color">{user_?.wallet?.usdtBalance}</h5>
                        </div>
                        <div className="grid-item1_4 altCard">
                            <h6 className="grey-text h7">BNB Bal.</h6>
                            <h5 className="h5 light_color">{user_?.wallet?.bnbBalance}</h5>
                        </div>
                    </div>

                    { user_?.kyc?.passportPhoto?
                        <div className={'mgT2'}>
                            <hr className="hr mgV2"/>
                            <h6 className="h6">KYC info</h6>
                            <div className="altCard grid- mgT2">

                                {
                                    user_.kyc?.passportPhoto?.map(e=>{
                                        return <div className="grid-item2_4" key={e}>
                                            <img src={e} alt="" className={"fluid_img"}/>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                       :null}
                    <div className="mgT2">
                        <button onClick={()=>setEditAcc(true)} className="btn btn-blue">Edit Account</button>
                    </div>
                </div>
            </div>
            {
                showEdit?<Modal closeModal={setShowEdit}>
                    <h5 className="h5">Edit</h5>
                    <form onSubmit={updateTrx} className="mgT2">
                        <div className="form-input-box">
                            <input type="text" placeholder={'Amount'} value={current.amount} onChange={(e)=>{
                                let _cur = {...current};

                                _cur.amount = e.target.value;

                                setCurrent(_cur);

                            }
                            }/>
                        </div>
                        <div className="form-input-box">
                            <input type="text" placeholder={'owner'} value={current.owner.firstName + " " + current.owner.lastName}/>
                        </div>
                        <div className="form-input-box">
                            <select value={current.status} onChange={(e)=>{
                                let _cur = {...current};

                                _cur.status = e.target.value;

                                setCurrent(_cur);

                            }
                            }>
                                <option >pending</option>
                                <option >Failed</option>
                                <option >success</option>
                            </select>
                        </div>
                        <button className="btn btn-green">Save</button>
                    </form>
                </Modal>:null
            }
            <div className="mgT2 outlined-card">
                <Table
                    searchText={searchText}
                    onTextChange={setSearchText}
                    searchQuery={searchQuery}
                    filters={true}
                    type='dark-table' headers={['TXN-Type','Amount','title',
                    'Reference No','owner','Status','Date','Action']}
                    body={
                        <>
                            {transactions.length>0?
                                transactions.map(tr=>{
                                    return   (
                                        <tr key={tr._id} >

                                            <td>
                                                {tr.trxType}
                                            </td>

                                            <td>
                                                ${numberWithCommas(tr?.amount||0)}
                                            </td>

                                            <td>
                                                {tr.title}
                                            </td>
                                            <td>
                                                {tr?.ref_id?tr.ref_id:""}
                                            </td>
                                            <td>{tr?.owner?.firstName} {tr?.owner?.lastName}</td>
                                            <td>
                                                  <span className={tr.status==='pending'?"blue-text":tr.status==='success'?'green-text':"redText"}>
                                                      {/*?'green-text':'emMd'*/}
                                                      {tr.status}
                                                      {/*{tr.status}*/}
                                                  </span>
                                            </td>

                                            <td>
                                                <Moment format={'DD MM YYYY HH:mm:ss'}>{tr.createdAt}</Moment>
                                            </td>
                                            <td>
                                                <button onClick={()=>{
                                                    setCurrent(tr);
                                                    setShowEdit(true)
                                                }} className="btn btn-blue">Edit</button>
                                                <button className="btn btn-red mgL2" onClick={()=>{
                                                    setCurrent(tr);
                                                    setShowDel(true)}
                                                }>Delete</button>
                                            </td>

                                        </tr>)}):
                                <tr><td colSpan='7'></td></tr>
                            }
                        </>
                    }



                />
            </div>
        </AdminLayout>
    );
};

export default AdminStatement;