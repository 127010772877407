import React from 'react';

import {BiCompass, BiBullseye,BiStats} from 'react-icons/bi';
const AboutHome= () => {
    return (
        <div className='about-section mgT10 container'>
            <div className="grid">
              <div className="grid-item1_3 centerText">
                    <BiCompass size={48} color={'white'}/>
                   <h6 className="h5 light_color">Better Firm</h6>
                  <p className="h6 mgT2 grey-text">We aim to achieve excellence over the long term through building a great firm, with a culture that encourages our people to achieve their highest financial potential ,By intentionally building a great firm, we are holding ourselves personally accountable and internalising our purpose.
                  </p>
              </div>
              <div className="grid-item1_3 centerText">
                    <BiBullseye size={48} color={'white'}/>
                   <h6 className="h5 light_color">Better Investing</h6>
                  <p className="h6 mgT2 grey-text">Long-term investment excellence is our primary focus and is non-negotiable. Providing our clients with an investment offering that allows them to achieve their financial goals is the concrete manifestation of our purpose. We are quite literally investing for a better tomorrow.</p>
              </div>
              <div className="grid-item1_3 centerText">
                    <BiStats size={48} color={'white'}/>
                   <h6 className="h5 light_color">Better World</h6>
                  <p className="h6 mgT2 grey-text">We seek to make a difference to the world’s greatest challenges by investing our clients’ capital in a responsible manner towards the most responsible users of that capital. Being a fully engaged participant in society and actively preserving our natural environment is both our opportunity and promise.</p>
              </div>
            </div>


         </div>
    );
};

export default AboutHome;