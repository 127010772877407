import React, {useCallback, useEffect, useState} from 'react';
import DashboardLayout from "../../layout/dashboardLayout";
import {FaBitcoin, FaEthereum} from "react-icons/fa";
import {SiBinance, SiRipple} from "react-icons/si";
import {Link} from "react-router-dom";
import AxiosInstance from "../../helpers/axiosInstance";
import {AuthContext} from "../../hoc/AuthContext";
import {Misc} from "../../helpers/misc";
import { numberWithCommas } from '../../helpers/helpers';
import Usdt from '../../assets/tether-usdt-logo.png'
const ClientHome = () => {
    // useRole();
    return (
        <DashboardLayout>
            <Misc/>
            <h5 className="h5">Overview</h5>
            <CurrencyCards/>
            <OtherCards/>
        </DashboardLayout>
    );
};


function OtherCards() {
    const userInfo = React.useContext(AuthContext);
    const [wallet,setWallet] = useState({
        address:"",
        bnbBalance: 0,
        btcBalance: 0,
        ethBalance: 0,
        totalBalance: 0,
        usdtBalance: 0,
        xrpBalance: 0

    })
    const getWallet = useCallback(()=>{
        const user_ = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
        AxiosInstance.get(`/acc/walletInfo/${user_.wallet}`,{},{headers:{
                authorization: userInfo.token
            }})
            .then(res=>{
                console.log(res.data);
                setWallet(res.data.data)
            })
            .catch(err=>{
                console.log(err.response.data.msg)
            })
    },[userInfo.token,userInfo.user])

    useEffect(()=>{
        getWallet();
        return ()=>{}
    },[getWallet])
    return <div className={'mgT4 grid'}>
        <div className="grid-item2_4 altCard">
            <p className="h7 green-text">Total Balance</p>
            <h5 className="h4 light_color mgT2">${numberWithCommas(wallet?.totalBalance||0)}</h5>
        </div>
        <div className="grid-item2_4 ">
                <Link to={'/client/withdrawal'} className={'btn btn-blue no-deco'}>Withdraw</Link>
                <Link to={'/client/wallet'} className={'btn btn-green no-deco mgL2'}>Inter transfer</Link>

        </div>
        
    </div>
}
export function CurrencyCards() {
    const userInfo = React.useContext(AuthContext);
    const [crypto,setCrypto] = useState({
        tether:{
            usd:0
        },
        bitcoin:{
            usd:0
        },
        ripple:{
            usd:0
        },
        binancecoin:{
            usd:0
        },
        ethereum:{
            usd:0
        }
    });
    const [wallet,setWallet] = useState({
        address:"",
        bnbBalance: 0,
        btcBalance: 0,
        ethBalance: 0,
        totalBalance: 0,
        usdtBalance: 0,
        xrpBalance: 0

    })
    const getWallet = useCallback(()=>{
        const user_ = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
        AxiosInstance.get(`/acc/walletInfo/${user_.wallet}`,{},{headers:{
                authorization: userInfo.token
            }})
            .then(res=>{
                console.log(res.data);
                setWallet(res.data.data)
                setCrypto(res.data.crypto)
            })
            .catch(err=>{
                console.log(err.response.data.msg)
            })
    },[userInfo.token,userInfo.user])

    useEffect(()=>{
        getWallet();
        return ()=>{}
    },[getWallet])
    return <div className={'grid mgT4'}>
        <div className="grid-item1_4 outlined-card centerText">
            <FaBitcoin size={48} color={'orange'}/>
            <h5 className="h5 mgT2" style={{color:"orange"}}>BTC</h5>
            <p className="h4 mgT4">{numberWithCommas(wallet?.btcBalance||0)}</p>
            <p className="h6 mgT1">${numberWithCommas((wallet?.btcBalance * parseFloat(crypto?.bitcoin.usd) )||0)}</p>

            <h6 className="h7 mgT2">current rate</h6>
             <p className="h7 grey-text mgT2">${numberWithCommas(parseFloat(crypto?.bitcoin.usd)||0)}</p>
        </div>
        <div className="grid-item1_4 outlined-card centerText">
            <FaEthereum size={48}/>
            <h5 className="h5 mgT2">ETH</h5>
            <p className="h4 mgT4">{numberWithCommas(wallet?.ethBalance||0)}</p>
            <p className="h6 mgT1">${numberWithCommas((wallet?.ethBalance * parseFloat(crypto?.ethereum.usd) )||0)}</p>

            <h6 className="h7 mgT2">current rate</h6>
            <p className="h7 grey-text mgT2">${numberWithCommas(parseFloat(crypto?.ethereum.usd)||0)}</p>
        </div>
        <div className="grid-item1_4 outlined-card centerText">
            <SiBinance size={48} color={'orange'}/>
            <h5 className="h5 mgT2 " style={{color:"orange"}}>BNB</h5>
            <p className="h4 mgT4">{numberWithCommas(wallet?.bnbBalance||0)}</p>
            <p className="h6 mgT1">${numberWithCommas((wallet?.bnbBalance * parseFloat(crypto?.binancecoin.usd) )||0)}</p>

            <h6 className="h7 mgT2">current rate</h6>
             <p className="h7 grey-text mgT2">${numberWithCommas(parseFloat(crypto?.binancecoin.usd)||0)}</p>
        </div>
        <div className="grid-item1_4 outlined-card centerText">
            <SiRipple size={48}/>
            <h5 className="h5 mgT2">XRP</h5>
            <p className="h4 mgT4">{numberWithCommas(wallet?.xrpBalance||0)}</p>
            <p className="h6 mgT1">${numberWithCommas((wallet?.xrpBalance * parseFloat(crypto?.ripple.usd) )||0)}</p>

            <h6 className="h7 mgT2">current rate</h6>
             <p className="h7 grey-text mgT2">${numberWithCommas(parseFloat(crypto?.ripple.usd)||0)}</p>
        </div>
        <div className="grid-item1_4 outlined-card centerText">
            <img src={Usdt} alt="" className={'logo'}/>
            <h5 className="h5 mgT2">USDT</h5>
            <p className="h4 mgT4">{numberWithCommas(wallet?.usdtBalance||0)}</p>
            <p className="h6 mgT1">${numberWithCommas((wallet?.usdtBalance * parseFloat(crypto?.tether.usd) )||0)}</p>

            <h6 className="h7 mgT2">current rate</h6>
             <p className="h7 grey-text mgT2">${numberWithCommas(parseFloat(crypto?.tether.usd)||0)}</p>
        </div>

    </div>

}
export default ClientHome;