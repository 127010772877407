import React, {useState} from 'react';
import AdminLayout from "../../layout/AdminLayout";
import axiosInstance from "../../helpers/axiosInstance";
import {AuthContext} from "../../hoc/AuthContext";
import Table from "../../hoc/Table";
import {numberWithCommas} from "../../helpers/helpers";
import Moment from "react-moment";
import Modal from "../../components/Modal";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../components/loader";
const AdminTransactions = () => {
    const [showEdit,setShowEdit] = useState(false);
    const [showDel,setShowDel] = useState(false);
    const [loading,setLoading] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [searchText,setSearchText] = useState('');
    const [current,setCurrent] = useState({
        amount:0,
        owner:"",
        createdAt: ""
    });
    const userInfo = React.useContext(AuthContext);
    const [page,setPage] = React.useState(0)
    const [transactions,setTransactions] = React.useState([]);
    function searchQuery(e) {
        e.preventDefault();
        axiosInstance.post(`/sup/trx/search/?q=${searchText}`,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                console.log(res.data)
                setTransactions(res.data.data);
            })
            .catch(err=>{
                console.log(err)
            })
    }
    function verifyTrx(id) {
        axiosInstance.post(`/sup/trx/verify/${id}`,{
            headers:{
                authorization:localStorage.getItem("G7_TK"),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setRefresh(!refresh)
                toast.success(res.data.msg)
            })
            .catch(err=>{

                toast.success(err.response.data.msg)
            })
    }
    const getAll = React.useCallback(function () {
     axiosInstance.get(`/sup/trx/get/all/`,{
         headers:{
             authorization:localStorage.getItem("G7_TK"),
             'Accept': 'application/json',
             'Content-Type': 'application/json'
         }
     })
            .then(res=>{
                setTransactions(res.data.data);
            })
         .catch(err=>{
             console.log(err)
         })


    },[setTransactions])
    function updateTrx(e) {
        e.preventDefault();
        setLoading(true)
        axiosInstance.post(`/sup/trx/update/`,current,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
                setTimeout(()=>{
                    setShowEdit(false)
                },1000)
            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })
    }
    function deleteTrx() {
        setLoading(true)
        axiosInstance.delete(`/sup/trx/delete/${current._id}`,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
                setTimeout(()=>{
                    setShowDel(false)
                },1000)
            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })
    }
    function changePage(e) {

        if(e<0){
            setPage(0);
        }
        setPage(e);
    }
    React.useEffect(()=>{
        if (searchText.length===0){
            getAll();
        }
        return ()=>{}
    },[getAll,refresh,searchText])
    return (
        <AdminLayout>
            {
                loading?<Loader/>:null
            }
            <ToastContainer/>
            <h3 className="h4">Users Transactions</h3>
            {
                showDel?<Modal closeModal={setShowDel}>
                    <h5 className="h5">Are you sure you want to delete?</h5>
                    <button className="btn btn-red mgT2" onClick={deleteTrx}>Delete</button>
                </Modal>:null
            }
                   {
                showEdit?<Modal closeModal={setShowEdit}>
                    <h5 className="h5">Edit</h5>
                    <form onSubmit={updateTrx} className="mgT2">
                        <div className="form-input-box">
                            <input type="text" placeholder={'Amount'} value={current.amount} onChange={(e)=>{
                                let _cur = {...current};

                                _cur.amount = e.target.value;

                                setCurrent(_cur);

                            }
                            }/>
                        </div>
                        <div className="form-input-box">
                            <input type="text" placeholder={'owner'} value={current.owner.firstName + " " + current.owner.lastName}/>
                        </div>
                        <div className="form-input-box">
                            <select value={current.status} onChange={(e)=>{
                            let _cur = {...current};

                            _cur.status = e.target.value;

                            setCurrent(_cur);

                            }
                            }>
                                <option >pending</option>
                                <option >Failed</option>
                                <option >success</option>
                            </select>
                        </div>
                        <div className="form-input-box">
                            <input type="text" placeholder={'Date'} value={current.createdAt} onChange={(e)=>{
                                let _cur = {...current};

                                _cur.createdAt = e.target.value;

                                setCurrent(_cur);

                            }
                            }/>
                        </div>
                        <button className="btn btn-green">Save</button>
                    </form>
                </Modal>:null
            }
               <div className="mgT2 outlined-card">
                   <Table
                       searchText={searchText}
                       onTextChange={setSearchText}
                       searchQuery={searchQuery}
                       filters={true}
                       type='dark-table' headers={['TXN-Type','Amount','title',
                       'Reference No','owner','Status','Date','Action']}
                       body={
                           <>
                               {transactions.length>0?
                                   transactions.map(tr=>{
                                       return   (
                                           <tr key={tr._id} >

                                               <td>
                                                   {tr.trxType}
                                               </td>

                                               <td>
                                                 ${numberWithCommas(tr?.amount||0)}
                                               </td>

                                               <td>
                                                   {tr.title}
                                               </td>
                                               <td>
                                                   {tr?.ref_id?tr.ref_id:""}
                                               </td>
                                                <td>{tr?.owner?.firstName} {tr?.owner?.lastName}</td>
                                               <td>
                                                  <span className={tr.status==='pending'?"blue-text":tr.status==='success'?'green-text':"redText"}>
                                                      {/*?'green-text':'emMd'*/}
                                                      {tr.status}
                                                       {/*{tr.status}*/}
                                                  </span>
                                               </td>

                                               <td>
                                                   {tr.createdAt}
                                               </td>
                                               <td>
                                                   <button onClick={()=>{
                                                       setCurrent(tr);
                                                       setShowEdit(true)
                                                   }} className="btn btn-blue">Edit</button>
                                                   {tr?.trxType==="Deposit" || tr?.trxType==="Withdrawal"?<button onClick={()=>{
                                                       setCurrent(tr);
                                                   if (tr.status==="pending"){
                                                       verifyTrx(tr._id)
                                                   }
                                                   }} className="btn btn-orange">{tr.status==="pending"?"Verify":"verified"}</button>:null}
                                                   <button className="btn btn-red mgL2" onClick={()=>{
                                                       setCurrent(tr);
                                                       setShowDel(true)}
                                                   }>Delete</button>
                                               </td>

                                           </tr>)}):
                                       <tr><td colSpan='7'></td></tr>
                            }
                           </>
                       }



                   />
               </div>
            {/*<div className="paginator">*/}
            {/*    <ul>*/}
            {/*        <li onClick={page!==0?()=>changePage((page - 1)):null} className={page===0?'inactiveP':null}>Prev</li>*/}
            {/*        <li>&nbsp;</li>*/}
            {/*        <li onClick={transactions.length!==0?()=>changePage((page + 1)):null} className={transactions.length === 0|| transactions.length<4?'inactiveP':null} >Next</li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
        </AdminLayout>
    );
};

export default AdminTransactions;