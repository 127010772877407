import React, {useState, useContext, useCallback, useEffect} from 'react';
import DashboardLayout from "../../../layout/dashboardLayout";
import Tab from "../../../components/tab";
import AxiosInstance from "../../../helpers/axiosInstance";
import {AuthContext} from "../../../hoc/AuthContext";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../../components/loader";

const Kyc = () => {
    const [tabs] = useState([<Personal/>,<DocsUpload/>])
    const [index,setIndex] = useState(0)
    return (
        <DashboardLayout>
            {/*<h5 className="h5">Please provide this docs </h5>*/}
            <ToastContainer/>
     <div className="outlined-card mgT2">
         <Tab tabs={["Personal Details","Identity/ KYC"]} position={"left"}  onChange={setIndex} activeIndex={index} tabsBody={<>
             {
                 tabs[index]
             }
         </>}/>
     </div>
        </DashboardLayout>
    );
};



function DocsUpload() {
    // const [ID,setID] = useState(new File([], "", undefined))
    const [passportPhoto,setPassportPhoto] = useState([new File([], "", null)])
    const [passportUrls,setPassportUrls] = useState([])
    const [currentType,setType] = useState("Drivers License")
    const [imgs,showImgs] = useState([])
    const [loading,setLoading] = useState(false);
    const userInfo = useContext(AuthContext);
    const user = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
    function getBase64(file) {

        return  window.URL.createObjectURL(file)

    }
    
    function uploadImage(e) {
        e.preventDefault();
        let data = new FormData()
        // console.log(passportPhoto);
        for(let i =0;i<passportPhoto.length;i++){
            data.append("pass",passportPhoto[i]);
        }
        setLoading(true);
        AxiosInstance.post(`/acc/kyc/${user._id}`,data,{
            headers:{
                authorization: user.token
            }
        })
            .then(res=> {
                setLoading(false);
                toast.success(res.data.msg)
                showImgs(res.data.imgs)
            })
            .catch(err=>{
                setLoading(false);
                toast.success(err.response.data.msg)
            })
    }
    function selectImage(e,type,setState,cat) {


        const files = e.target.files;
        // console.log(files);
        const list = []
       if (files.length>0 && files){

               setState(files);

           for (let i = 0;i<files.length;i++){
               list.push(getBase64(files[i]));
           }
           setPassportUrls(list)
       }

    }

    const getKyc = useCallback(()=>{

        AxiosInstance.get(`/acc/kyc/${user._id}`,
            {
                headers:{
                    authorization: user.token
                }
            })
            .then((res)=>{
                // console.log(res.data.data.passportPhoto)
                showImgs(res.data.data.passportPhoto)
            })
            .catch(err=>{})
        }
    ,[user.token,user._id,showImgs])
    useEffect(()=>{
        getKyc()
    },[getKyc])
    return  <div className="grid">
        {
            loading?<Loader/>:null
        }

        <form onSubmit={uploadImage} className="grid-item2_4">
            <label className={'h7 grey-text'}>Choose ID</label>
              <div className="form-input-box" >
                  <select onChange={e=>setType(e.target.value)}>
                      <option>Drivers License</option>
                      <option>ID card</option>
                      <option>Passport</option>
                  </select>
              </div>

            <label className={'h7 grey-text'}>Front and back of {currentType}</label>
                <div className="form-img mgT2">
                    <input  multiple type="file" style={{display:"none"}} id={"passport"} onChange={(e)=>selectImage(e,"",setPassportPhoto,"passport")}/>

                        <label htmlFor="passport" className={"h6"}>{
                            passportUrls.length>0?
                                passportUrls.map(e=><div key={e} className={'altCard'}>

                                    <img alt={passportPhoto.name} className={'fluid_img'} src={e}/>
                                </div>)
                                :<span>Choose Photos</span>
                        }
                            {imgs.length>0 && passportUrls.length<=0?
                                imgs.map(e=><div key={e} className={'altCard'}>
                                    <img alt={""} className={'fluid_img'} src={e}/>
                                </div>):null

                            }
                        </label>
                </div>
                <button className="btn btn-green mgT4">Submit</button>
        </form>
    </div>
}
function Personal() {
    const [loading,setLoading] = useState(false)
    const userInfo = useContext(AuthContext);
    const user = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
    const [details,setDetails] = useState({...user} ||
        {firstName:"",lastName:"",address:"",city:"",state:"",gender:""});

    const getUser = useCallback(()=>{
        AxiosInstance.get(`/acc/get/${user._id}`,{
            headers:{
                authorization: user.token
            }
        })
            .then(res=>{
               let {firstName,lastName,address,city,state,gender} = res.data.data
                setDetails({firstName,lastName,address,city,state,gender})
            })
            .catch(err=>{

            })
    },[user._id,user.token])
    function updateUser(e) {
        e.preventDefault();
        const data = {...details}
        setLoading(true);
        AxiosInstance.post(`/acc/update/${user._id}`,data,{
            headers:{
                authorization: user.token
            }
        })
            .then(res=> {
                setLoading(false);
                toast.success(res.data.msg)
            })
            .catch(err=>{
                setLoading(false);
                toast.success(err.response.data.msg)
            })
    }

    useEffect(()=>{
        getUser()
    },[getUser])
    return  <form onSubmit={updateUser} className="grid">
        {
            loading?<Loader/>:null
        }
        <div className="grid-item2_4">
            <label className="h7 grey-text">First Name</label>
            <div className="form-input-box">
                    <input type="text" placeholder={'First Name'} value={details.firstName||""} onChange={e=>{
                      let det = {...details};
                      det.firstName = e.target.value;
                      setDetails(det);
                    }
                    }/>
                </div>
            <label className="h7 grey-text" >Last Name</label>
            <div className="form-input-box">
                    <input type="text" placeholder={'Last Name'} value={details.lastName||""} onChange={e=>{
                        let det = {...details};
                        det.lastName = e.target.value;
                        setDetails(det);
                    }
                    }/>
                </div>
            <label className="h7 grey-text">Full Address</label>
            <div className="form-input-box">
                <input type="text" placeholder={'Address'} value={details.address||""} onChange={e=>{
                    let det = {...details};
                    det.address = e.target.value;
                    setDetails(det);
                }
                }/>
            </div>

        </div>
        <div className="grid-item2_4">

            <label className="h7 grey-text">Gender</label>
            <div className="form-input-box">
                <select value={details.gender||""} onChange={e=>{
                    let det = {...details};
                    det.gender = e.target.value;
                    setDetails(det);
                }
                }>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Queer</option>
                    <option>Rather Not Say</option>
                </select>
            </div>

            <label className="h7 grey-text">State</label>
            <div className="form-input-box">
                <input type="text" placeholder={'State'} value={details.state||""} onChange={e=>{
                    let det = {...details};
                    det.state = e.target.value;
                    setDetails(det);
                }
                }/>
            </div>
            <label className="h7 grey-text">City</label>
            <div className="form-input-box">
                <input type="text" placeholder={'City'} value={details.city||""} onChange={e=>{
                    let det = {...details};
                    det.city = e.target.value;
                    setDetails(det);
                }
                }/>
            </div>
            <button className="btn btn-blue mgT2">Save</button>
        </div>
    </form>
}
export default Kyc;