import React from 'react';
import axios from "../../helpers/axiosInstance";
import {Link} from "react-router-dom";
import logo from "../../assets/logo.png";
import Loader from "../../components/loader";
import {toast, ToastContainer} from "react-toastify";
const ResetPassword = () => {
    const [email,setEmail] = React.useState('');
    const [loading,setLoading] = React.useState(false);
   function resetPassword(e){
       setLoading(true);
        e.preventDefault();
         let data = {
             email
         }
         axios.post('/auth/reset/',data)
             .then(res => {
                setLoading(false);
                toast.success(res.data.msg)
             })
             .catch(err=> {
                 setLoading(false);

                 toast.error(err.response.data.msg)
             })
    }



       return (
           <div className='authBox'>
               {
                   loading?<Loader/>:null
               }
               <div className="authBox-card">
<ToastContainer/>
                   <form onSubmit={resetPassword} className="form">
                       <Link to='/' className='no-deco'>
                           <div className="disp_flex align_center">
                               <h2 className='h4'>G7 <span className={'green'}>Capital</span></h2>
                           </div>
                       </Link>
                       <h4 className="h4 mgT2">Reset Password</h4>
                       <p className="h5 blue-text mgT1">Enter the email used to <em className="">Register</em></p>
                       <div className="mgT4">
                           <div className="form-input-box">
                               <input  value={email} className='form-input' onChange={e=>setEmail(e.target.value)} placeholder='email'/>
                           </div>

                       </div>
                       <button className="btn btn-blue" style={{width:"100%"}}>Reset</button>
                         </form>
               </div>
           </div>
       );
   // }
};

export default ResetPassword;