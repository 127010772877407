import React from 'react';
import hero2 from "../../../assets/architectural-design-architecture-buildings-936722.jpg";
import HomeNavigation from "../../../components/HomeNavigation";
import {Link} from "react-router-dom";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";

const Offshore = () => {
    useScrollToTop();
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`}}>

                <div className=" width35 text-center mg2">
                    <h1 className="h2 light_color">G7 <span className="green">Capital</span> Offshore Investment</h1>

                </div>
            </header>
            <div className="mgT8">
                <div className={'container centerText'}>
                    <h1 className="h3">G7 <span className="green">Capital</span> Offshore Investment</h1>
                    <p className="h5 center-container-60 mgT4">Start a basic Plan, You can invest in unit trusts for most of your financial goals, from saving for longer-term needs to meeting your shorter-term objectives.</p>

                    <Link to={'/login'} className={'btn btn-green mgT4'}>Get Started</Link>
                </div>
            </div>
            <div className="mgT8 " style={{backgroundColor:'#f7f7f7'}}>
                <div className="container grid">

                    <div className="grid-item2_4">
                        <h5 className="h4 width50">What are Offshore Investments?</h5>
                        <p className="h5 grey-text mgT4">Investing offshore allows you to spread your investment risk across different economies and regions. It also gives you access to industries and companies that may not be available locally.
                            Offshore investments may perform better at times than local investments, and vice versa, depending on various factors, such as global economic conditions and exchange.</p>
                    </div>

                    <div className="grid-item2_4">
                        <h5 className="h4">Benefits Of Investing Offshore</h5>
                       <ul className={'mgT4'}>
                           <li className="h5 grey-text">
                               More investment opportunities: When you diversify internationally, you get access to a much wider range of investment opportunities to grow your money across countries, industries, companies and currencies.
                           </li>
                           <li className="h5 grey-text">
                               Minimised risk of capital loss: Spreading your investments across markets and currencies, minimises the impact of currency depreciation or political and market events on your wealth.
                           </li>
                           <li className="h5 grey-text">
                               Meet future international goals: Investing offshore may make it easier to fund any international liabilities and help you meet your international goals.
                           </li>
                       </ul>
                    </div>


                </div>
            </div>
            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default Offshore;