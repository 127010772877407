import React from 'react';
import hero2 from "../../../assets/culture.jpg";
import HomeNavigation from "../../../components/HomeNavigation";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import {Link} from "react-router-dom";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";

const TrustFund = () => {
    useScrollToTop()
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`}}>

                <div className=" width35 text-center mg2">
                    <h1 className="h2 light_color">G7 <span className="green">Capital</span> Trust Fund</h1>

                </div>
            </header>
            <div className="mgT8">
                <div className={'container'}>
                    <h6 className="h4 centerText">G7capital Trust Fund</h6>
                    <div className="greyCard mgT2">
                        <p className="h6 mgT2">
                            Despite all we got under the radar , we are all bound to mistakes I guess that’s why we are humans And that is something beyond our control , ie natural disaster for landed investment,government policies that the bear might just cost a huge liquidity not expected.
                             The world is full of impossibility they say , we have over 2 billion dollars locked in a wallet on the blockchain You could ask your account manager for proof or contact the support a link regarding this.

                           </p>
                    </div>
                </div>
                <div className={'container centerText'}>

                    <h1 className="h3">G7 <span className="green">Capital</span> Trust Fund</h1>
                    <p className="h5 center-container-60 mgT4"> Start a basic Plan, You can invest in unit trusts for most of your financial goals, from saving for longer-term needs to meeting your shorter-term objectives.</p>

                    <Link to={'/login'} className={'btn btn-green mgT4'}>Get Started</Link>
                </div>
            </div>
            <div className="mgT8 " style={{backgroundColor:'#f7f7f7'}}>
                <div className="container grid">

                    <div className="grid-item2_4">
                        <h5 className="h4 width50">What are unit trusts?</h5>
                        <p className="h5 mgT4 grey-text">A unit trust is a type of investment that provides easy and affordable access to financial markets. Your money is combined with the money of other investors and our investment managers use the pool of money to buy underlying investments, such as equities, bonds, cash and property, depending on the unit trust objective. The unit trust is split into equal portions called ‘units’ that are allocated to you according to the amount of money you invest and the price of the units on the day you buy them.</p>
                    </div>

                    <div className="grid-item2_4">
                        <h5 className="h4 width50">Unit trusts have a number of benefits:</h5>
                        <p className="h5 mgT4 grey-text">• You can benefit from our investment management expertise by investing $2000 a month or more in our unit trusts.

                            You buy units in the unit trust of your choice; you decide when and how many units to buy and you own the units until you decide to sell them.
                            You can access your money at any time and make changes to your investment whenever you need to, no transaction fees and no penalties.</p>
                    </div>


                </div>
            </div>
            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default TrustFund;