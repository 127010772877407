import React, {useEffect, useState} from 'react';
import '../../../sass/components/carousel.scss';
import slide1 from '../../../assets/cash_man.jpg'
import slide2 from '../../../assets/stocks.jpg'
import slide3 from '../../../assets/stock_opt.jpg';
import slide4 from '../../../assets/gold.jpg';
import {motion} from "framer-motion";
import {BiChevronLeft,BiChevronRight} from "react-icons/bi";
import {Link} from "react-router-dom";

const Products = () => {
    const [index,setIndex] = useState(0);
    const [pause_,setPause] = useState(false);
    const [items] = useState([
        <Slide1/>,
        <Slide2/>,
        <Slide3/>,
        <Slide4/>

    ]);
    function pause() {
        setPause(true)
    }
    function play(){
        setPause(false)
    }
    function nextBtn() {
        let _newIndex= (index + 1)%items.length;
        setIndex(_newIndex)
    }
    function prevBtn() {
        let _newIndex= (index - 1 + items.length)%items.length;
        setIndex(_newIndex)
    }
    useEffect(()=>{
       let timer = setInterval(()=>{
           if (pause_){

           }else{
               nextBtn();
           }
       },5000) ;

       return ()=>{
           clearInterval(timer)
       }
    })
    return (
        <div className={'products-section '}>
           <div className="container">
               <h4 className="h3">Our Products</h4>
               <div className="carousel-body">
                <span className={'carousel-btn-prev'} onClick={prevBtn}>
                    <BiChevronLeft size={28}/>
                </span>
                   {
                       items.length>0?items.map((e,i)=>{
                           return <motion.div onHoverEnd={play} onHoverStart={pause} initial={{x:-10}} animate={{x:0}} transition={[0.13,0.23,0.65,0.95]} className={index===i?'carousel-body-item':'carousel-body-item-inactive'} key={i}>{e}</motion.div>
                       }):null
                   }
                   <span className={'carousel-btn-next'} onClick={nextBtn}>
                   <BiChevronRight size={28}/>
                </span>
               </div>
               <div className="carousel-dots">
                   {
                       items.length>0?items.map((e,i)=>{
                           return <span onClick={()=>{
                               setIndex(i)}
                           } key={i + e} className={index===i?"carousel-dots-item-active":"carousel-dots-item"}>&nbsp;</span>
                       }):null
                   }
               </div>
           </div>
            
        </div>
    );
};

function Slide1() {
    return <div className={'grid'}>
        <div className="grid-item1_4">
            <h6 className="h4">Cash Management</h6>
            <p className="h5 mgT2">Earn 17.30% APY* on your uninvested cash and get more flexibility with your brokerage account. </p>
            <Link to={'/cash-management'} className="btn btn-blue mgT4">Discover more{">"}</Link>
        </div>
        <div className="grid-item2_4">
            <img src={slide1} alt={'sldie'} className={'fluid_img'}/>
        </div>
    </div>
}

function Slide2() {
    return <div className={'grid'}>
        <div className="grid-item1_4">
            <h6 className="h4">Stocks</h6>
            <p className="h5 mgT2">Get mobile access to the markets. Invest commission-free in individual companies or bundles of investments (ETFs).
            </p>
            <Link to={'/stocks'} className="btn btn-blue mgT4">Discover more</Link>
        </div>
        <div className="grid-item2_4">
            <img src={slide2} alt={'sldie'} className={'fluid_img'}/>
        </div>
    </div>
}

function Slide3() {
    return <div className={'grid'}>
        <div className="grid-item1_4">
            <h6 className="h4">Options</h6>
            <p className="h5 mgT2">Be bullish on stocks you believe in and bearish on the ones you don’t. It’s your call.</p>
            <Link to={'/options'} className="btn btn-blue mgT4">Discover more</Link>
        </div>
        <div className="grid-item2_4">
            <img src={slide3} alt={'sldie'} className={'fluid_img'}/>
        </div>
    </div>
}


function Slide4() {
    return <div className={'grid'}>
        <div className="grid-item1_4">
            <h6 className="h4">Gold</h6>
            <p className="h5 mgT2">Access research reports trade on margin at a 12.5% annual rate and make bigger deposits with quicker access to funds—all starting $500 per month.</p>
            <Link to={'/gold'} className="btn btn-blue mgT4">Discover more</Link>
        </div>
        <div className="grid-item2_4">
            <img src={slide4} alt={'sldie'} className={'fluid_img'}/>
        </div>
    </div>
}

export default Products;