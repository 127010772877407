import React from 'react';
import hero2 from "../../../assets/relations.jpg";
import HomeNavigation from "../../../components/HomeNavigation";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import swap from "../../../assets/token2.gif";
import swap2 from "../../../assets/swap.png";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";

const Swap = () => {
useScrollToTop();
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`,height:'70vh'}}>

                <div className=" width35 text-center mg2">
                    <p className="h5"><span className="green">G7 Capital</span> </p>
                    <h1 className="h2 light_color">
                        SWAP</h1>

                </div>
            </header>
            <div className="container ">
                <div className="grid">
                    <div className="grid-item2_4">
                        <h4 className="h3">Introduction</h4>
                        <p className="mgT2 h5 grey-text">Swap refers to exchanging one crypto asset for another, like swapping your BTC into ETH — Swapping crypto can be interesting for a number of reasons, including making a profit, or diversifying your portfolio Ever wondered how many cryptocurrencies existed? A few hundred? Maybe a thousand?… Well, try over 7,000 according to CoinMarketCap. With so many choices, you might at some point want to give new cryptocurrencies a try. But how can you go about it? Well good news! You can easily give another cryptocurrency a try by making a swap. Swapping refers to exchanging a cryptocurrency that you already have into a different one. So how does it work? Let’s say you have some Ethereum, but you really want to get some Bitcoin instead. There are certain services that can actually help you with this. Thanks to Swap service providers, you can exchange your Ethereum and receive Bitcoin of roughly the same value. To simplify: You’d send your 10 Ethereum to a Swap service provider, who will change it into Bitcoin for you. You’ll have swapped your 10 ETH for 0.34 BTC.</p>
                    </div>
                    <div className="grid-item2_4">
                        <img alt={''} src={swap} className="fluid_img"/>
                    </div>
                </div>
            </div>
            <div className="container">

                <div className="grid">
                    <div className="grid-item2_4">
                        <img alt={''} src={swap2} className="fluid_img"/>
                    </div>
                    <div className="grid-item2_4">
                        <h5 className="h3">WHAT IS CRYPTO SWAPPING AND HOW DO I DO IT?</h5>
                        <p className="h5 mgT2 ">Swap refers to exchanging one crypto asset for another, like swapping your BTC into ETH</p>
                        <ul className="mgT2" style={{listStyle:"none"}}>

                            <li className={' h5 grey-text'}>  — Swapping crypto can be interesting for a number of reasons, including making a profit, or diversifying your portfolio</li>

                            <li className={' h5 grey-text'}>  — The most common method of swapping crypto is using exchanges, though you wouldn’t be in full control of your crypto</li>
                            <li className={' h5 grey-text'}>  — Ledger’s Swap option allows you to exchange crypto while retaining total ownership.</li>
                        </ul>

                        <p className="mgT2 h5 grey-text">Trading crypto assets is a pretty common practice in the crypto world. Let’s have a look at what swapping means and why it’s useful for crypto owners</p>

                    </div>
                </div>
               </div>
            <div className="container" style={{backgroundColor:"#f7f7f7"}}>
                <h5 className="h4">WHAT DOES SWAPPING CRYPTO MEAN?</h5>
                <p className="h6 mgT2 grey-text">Ever wondered how many cryptocurrencies existed? A few hundred? Maybe a thousand?… Well, try over 7,000 according to CoinMarketCap. With so many choices, you might at some point want to give new cryptocurrencies a try. But how can you go about it? Well good news! You can easily give another cryptocurrency a try by making a swap. Swapping refers to exchanging a cryptocurrency that you already have into a different one. So how does it work? Let’s say you have some Ethereum, but you really want to get some Bitcoin instead. There are certain services that can actually help you with this. Thanks to Swap service providers, you can exchange your Ethereum and receive Bitcoin of roughly the same value. To simplify: You’d send your 10 Ethereum to a Swap service provider, who will change it into Bitcoin for you. You’ll have swapped your 10 ETH for 0.34 BTC.</p>

            </div>
            <div className="container centerText" >

                <h5 className="h4 mgT4">WHY WOULD I WANT TO SWAP?</h5>
                <p className="mgT h6 grey-text width-60"  style={
                    {
                        margin:'2rem auto'
                    }
                }>Ok, you now understand what swapping cryptocurrencies means. But why would you want to do it? What reasons could someone have for wanting to get rid of their crypto assets for another kind? Well, let’s dive into it.</p>
                <div className="mgT4 grid">

                    <div className="grid-item1_3 centerText">
                        <h5 className="h4">PROFITING</h5>
                        <p className="h5 mgT2 grey-text">Ah yes, making money. Who doesn’t like it? Indeed, you could make quite the profit by trading cryptocurrencies, since they can change in price very quickly. If you time the market correctly, and have a bit of luck, you could earn quite a lot by swapping your crypto at the right moment.</p>

                    </div>

                    <div className="grid-item1_3 centerText">
                        <h5 className="h4">PASSIVE INCOME</h5>
                        <p className="h5 mgT2 grey-text">How do you like the sound of getting money without doing anything? Certain crypto assets support staking, meaning you can gain more crypto without any further action being required on your side. You could swap your crypto to give this a try please contact us on our official email and we will be happy to walk you down the path of swapping your cryptocurrencies</p>

                    </div>

                    <div className="grid-item1_3">
                        <h5 className="h4">DIVERSIFYING YOUR PORTFOLIO</h5>
                        <p className="h5  mgT2 grey-text">Not everyone likes those quick price changes though. Diversification is usually seen as a pretty good weapon against risk. Having a bit of many different cryptocurrencies could help reduce the impact of price drops.</p>

                    </div>

                </div>
                <button className="btn btn-blue mgT4">Click Here to Exchange Your Crypto</button>
            </div>
            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default Swap;