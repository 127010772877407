import React from 'react';
import AdminLayout from "../../../layout/AdminLayout";
import Table from "../../../hoc/Table";
const MobileDeposit = () => {
    return (
        <AdminLayout>

            <h4 className="h4">Mobile Deposit Management</h4>
            <div className="mgT2">
                <Table
                    filters={true}
                    type='dark-table'
                    headers={['Account','Amount','Cheque','Cheque ID',
                        'Status','Date','Action']}
                    body={
                        <>

                            <tr key={Math.random()}>

                                <td>1234567890</td>
                                <td>$45</td>
                                <td>

                                </td>
                                <td>fhsbfhsf</td>
                                <td>#adjfngiephgjrwhp/k</td>
                                <td>31th Aug 2022</td>
                                <td className={'display_flex'}>
                                    {/*<button className="btn btn-green">Re</button>*/}
                                    {/*<button className="btn-red btn mgL2">Delete</button>*/}
                                </td>
                            </tr>
                        </>
                    }



                />
            </div>v
        </AdminLayout>
    );
};

export default MobileDeposit;