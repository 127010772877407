import React,{useEffect} from 'react';
import Hero from "./hero";
import Loader from "../../../components/loader";
import {motion,useViewportScroll,useTransform,useAnimation} from "framer-motion";
import {useInView} from 'react-intersection-observer';
import AboutHome from "./HomeAbout";
import WhyHome from "./WhyHome";
import FooterC from "../../../hoc/FooterC";
import Advantage from "./Advantage";
// import SupportForm from "./SupportForm";
import About_img from "../../../assets/about_img.jpg";
import AboutSubTabs from "./AboutSubTabs";
import Products from "./Products";
import NewsAndMarket from "./NewsAndMarket";
import useScrollToTop from "../../../hoc/scrollToTop";
import CryptoChart from "./CryptoChart";
import SupportMsg from "../../../components/SupportMsg";


const Home = () => {
    useScrollToTop();

    const [loading,setLoading] = React.useState(true);
    React.useEffect(()=>{
       setLoading(false)
    },[])

    return (
        <motion.div>
            {loading?<Loader/>:
            <div>
                <Hero/>
                <AboutHome/>
                <SupportMsg/>
                <div className="container">
                    <div className="mgT10 ">

                        <div className="grid">

                            <div className="grid-item1_3">
                                <img src={About_img} alt='about' className="fluid_img"/>
                            </div>
                            <div className="grid-item2_4 ">
                                <h3 className="h3 ">About Us </h3>

                                <h5 className="h4 mgT4 ">Our company</h5>
                                <p className="mgT1 h5 grey-text">provides investment products and services to institutions, advisory clients & individuals. Our clients include pension funds, central banks, sovereign wealth funds, insurers, foundations, financial advisers, and individual investors.

                                    </p>
                                <p className="mgT1 h5 grey-text">Today, G7 Capital continues to bring a different perspective on investing. Whether it’s the rise of the East or the rise of the machines, we aim to make the best of it. Best for our clients, best for our company, and best for the world in which we live.

                                    </p>

                                <AboutSubTabs/>
                            </div>

                        </div>
                    </div>
                </div>
                <WhyHome/>
                    <Products/>
                <Advantage/>
                <CryptoChart/>
                <NewsAndMarket/>


                <FooterC/>
                {/*<InfiniteTestimony/>*/}
            </div>
            }

        </motion.div>
    );
};

export default Home;