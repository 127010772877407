import React from 'react';
import hero2 from "../../../assets/hero8.jpg";
import Navigation from "../../../components/navigation";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
// import telegram from "../../assets/telegram.png";
import whatsapp from "../../../assets/whatsapp.png";
import SupportForm from "./SupportForm";
// import {Link} from "react-router-dom";

const Support = () => {
    return (
        <div>
            <header className='altHero' style={{backgroundImage:`url(${hero2})`, height:'45vh'}}>
                <Navigation/>
                <div className="overlayCard width35 text-center mg2">
                    <h4 className="h4">Support</h4>
                    <p className="h5 mgT2">Send Us a Message</p>
                </div>


            </header>
            <div className="container">
                <div className="grid">
                    <SupportForm/>
                    <div className="grid-item2_4 mgL2">
                        <div className=" overlayCard">
                            <h5 className="h4">Our Office</h5>
                            <p className="mgT2 h5">Mercy building, 2nd Floor, Purcell estate, road town, Tortola, British virgin islands

                            </p>
                            <p className="mgT2 h5">Phone: +44 7476 696749</p>
                            <p className="mgT1 h5 disp_flex align_center">
                                {/*<img src={email} alt='' className='about_img'/>*/}

                                <span>support@g7.com</span></p>
                            <p className="mgT1 h5 disp_flex align_center">
                                {/*<img src={facebook} alt='' className='about_img'/>*/}

                                <span> <a href='https://www.facebook.com/groups/848630276049019/?ref=share' target='_blank' rel='noreferrer' className='link'>Cedefima </a></span>
                            </p>
                            {/*<p className="mgT1 h5 disp_flex align_center">*/}
                            {/*    <img src={telegram} alt='' className='about_img'/>*/}

                            {/*    <span>support@cedefima.com</span></p>*/}

                            <p className="mgT1 h5 disp_flex align_center">
                                <img src={whatsapp} alt='' className='about_img'/>

                                <span>support@cedefima.com</span></p>


                        </div>
                    </div>
                </div>
            </div>

            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default Support;