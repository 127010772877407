import React from 'react';
import hero from "../../../assets/hero7.jpg";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";

import FaqSection from "./Faq-section";
import HomeNavigation from "../../../components/HomeNavigation";

const Faqs = () => {
    return (
        <div>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero})`, height:'70vh'}}>
                <HomeNavigation/>
                <div className=" width35 text-center mg2">
                    <h4 className="h4 light_color">FAQs</h4>
                    <p className="h5 mgT2 light_color">Frequently Asked Questions</p>
                </div>
            </header>
            <div className="mgV4 container">
                <h4 className="h4">Frequently Asked Questions</h4>
                <p className="h6 mgT1">Have any Questions?</p>
                <FaqSection/>
            </div>

            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default Faqs;