import React, {useContext} from 'react';
import {AuthContext} from "./AuthContext";
import {Navigate} from "react-router-dom";
import {useRole} from "../helpers/helpers";

const PrivateRoute = ({children}) => {
    const user = useContext(AuthContext);
    useRole();
    return user.token ?
        children:<Navigate to={'/'} replace/>
};

export default PrivateRoute;