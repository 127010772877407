import React, {useCallback, useEffect, useState} from 'react';
import AxiosInstance from "../../../helpers/axiosInstance";
import {useParams,useNavigate} from "react-router-dom";
import Moment from "react-moment";
// import useScrollToTop from "../../../helpers/";
import {motion,useScroll} from "framer-motion";

import HomeNavigation from "../../../components/HomeNavigation";
import useScrollToTop from "../../../hoc/scrollToTop";

const Article = () => {
    const params = useParams()
    const { scrollYProgress } = useScroll();
    useScrollToTop();
    const navigate = useNavigate();
    const [post,setPost] = useState({
        title:"",
        image:"",
        description:""
    });
    const getLatest = useCallback(()=>{
        AxiosInstance.get(`/sup/blog/p/${params.id}`)
            .then(res=>{
                // console.log(res.data.data)
                setPost(res.data.data)
            })
            .catch(err=>{
                navigate('/blog')
                // console.log(err)

            })
    },[setPost,params.id]);
    useEffect(()=>{
        getLatest()
    },[getLatest]);
    const transition = {
        duration:.6,
        ease: [0.6,0.13,0.23,0.96]
    }
    return (
        <motion.div initial={{opacity:0.2,y:-100}} animate={{opacity:1,y:0}} transition={transition}>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${post?.image})`,height:'70vh'}}>

                <div className=" text-center mg2">
                    <h1 className="h5 light_color">
                        {post.title}</h1>

                </div>
            </header>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <div className="greyBox container mgT8">
                {
                    post.hasOwnProperty('title')?<>
                        <div className="mgT2">
                            <span className="h6 greenText bold">{post?.owner?.firstName}</span> <span
                            className={'h7 mgL2 greyText'}><Moment format={'MM DD, YYYY'}>{post.createdAt}</Moment></span>
                        </div>
                        <div className="mgT4 h6 textJust" dangerouslySetInnerHTML={{__html:post?.description.length>0?post.description:"<span></span>"}}/>
                    </>:null
                }
            </div>


        </motion.div>
    );
};

export default Article;