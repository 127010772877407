import React from 'react';
import hero2 from "../../../assets/stocks.jpg";
import HomeNavigation from "../../../components/HomeNavigation";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import pimg2 from "../../../assets/tk1.jpg";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";

const Crypto = () => {
    useScrollToTop();
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`,height:'70vh'}}>

                <div className=" width35 text-center mg2">
                    <h1 className="h2 light_color">G7 <span className="green">Capital</span> Crypto Investment</h1>

                </div>
            </header>
            <div className="mgT8 " >
                <div className="container grid">

                    <div className="grid-item2_4">
                        <h5 className="h4 ">Crypto</h5>
                        <p className="h5 mgT4">G7 capital makes the mining and earning of cryptocurrrencies easy & possible for anyone, anywhere, and reduce dependency on traditional financial institutions with a long term partnership with major mining pool Via BTC, slush pool
                            These are done Commission-free ,24/7 with our user-friendly platform giving you a proper track of your finances.</p>

                    <hr className={'hr mgV2'}/>
                        <p className="h5 mgT4">Manage your cryptos with confidence. Your coins and personal information are protected.

                        </p>

                    </div>
                    <div className="grid-item2_4">
                        <img src={pimg2} className={'fluid_img'} alt={''}/>
                    </div>


                </div>
            </div>

            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default Crypto;