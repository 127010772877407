import React, {useState} from 'react';
import DashboardLayout from "../../../layout/dashboardLayout";
import Tab from "../../../components/tab";


const Settings = () => {
    const [index,setIndex] = useState(0);
    const [tabs] = useState([<AccountSettings/>,<AdvancedSettings/>])
    return (
        <DashboardLayout>
            <h3 className="h4">Settings</h3>
            <div className="mgT4 outlined-card">
                <Tab position={'left'} tabs={['Account','Advanced']} activeIndex={index} onChange={setIndex} tabsBody={<>
                    {tabs[index]}
                </>}/>
            </div>
        </DashboardLayout>
    );
};

function AdvancedSettings() {
    return <div >
        <h5 className="h6">Advanced Settings</h5>
       <div className="mgT4">
           <div className="form-input-box">
               <input type="text" placeholder={'password'}/>
           </div>
           <div className="form-input-box">
               <input type="text" placeholder={'Retype-password'}/>
           </div>
           <button className="btn btn-blue">Save</button>
       </div>
    </div>
}
function AccountSettings() {
    return <div  className={'mgT2'}>
       <h5 className="h5">Account Settings</h5>
       <div className={'mgT2'}>
           <div className="form-input-box">
               <input type="text" placeholder={'full Name'}/>
           </div>
           <div className="form-input-box">
               <input type="text" placeholder={'Country'}/>
           </div>
           <button className="btn btn-blue">Save</button>
       </div>
    </div>
}

export default Settings;