import React from 'react';
import {BiPlus,BiMinus,BiClipboard} from "react-icons/bi";
import {motion} from 'framer-motion';
const FrequentQ = ({question,answer}) => {
    const [visible,setVisible] = React.useState(false);
    return (
        <motion.div animate={{scale:1}} transition={[0.15,0.23,0.65,0.75]} >
                <motion.div  className="frequently-section-card">
                    <div onClick={()=>setVisible(!visible)} className="frequently-section-card-top">
                        <div className="display_flex just_start">
                            <BiClipboard className='md_icon'  />
                            <h6 className="h5 mgL2">{question}</h6>
                        </div>
                        {visible? <motion.span transition={[0.15,0.23,0.65,0.75]}><BiMinus className='md_icon' /></motion.span >:<motion.span transition={[0.15,0.23,0.65,0.75]}><BiPlus className='md_icon' /></motion.span>}
                    </div>
                    {
                        visible? <motion.div initial={{y:20}} animate={{y:0}} transition={[0.15,0.23,0.65,0.75]} className="frequently-section-card-body">
                            <p className="h5">{answer}</p>
                        </motion.div>:null
                    }
                </motion.div>

        </motion.div>
    );
};

export default FrequentQ;