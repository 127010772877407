import React from 'react';
import '../sass/components/navigations.scss';
import Logo from '../logo.svg'
import {Link} from "react-router-dom";
const HomeNavigation = () => {
    // const [scrollHeight,setHeight] = React.useState(0);
    // const [scrollPosY,setScrollY] = React.useState(0);
    // // const [dropdown,setDropdown] = React.useState(false);
    // const [currentColor,setCurrentColor] = React.useState('transparent');
    // const topBar = React.useRef(null);
    // React.useEffect(()=>{
    //     setHeight(topBar.current.scrollHeight);
    //     function scrollChange (){
    //         setScrollY(window.scrollY);
    //         if (scrollPosY >= scrollHeight) {
    //             setCurrentColor('#1b1b1b');
    //         }
    //         else {
    //             setCurrentColor('#000000')
    //         }
    //     }
    //     window.addEventListener('scroll',scrollChange);
    //     return ()=>{
    //         window.removeEventListener('scroll',scrollChange)
    //     }
    // },[setHeight,setCurrentColor,scrollPosY,scrollHeight,currentColor])

    return (
        <>
            {/*<TopBar/>*/}
            <div
                // ref={topBar} style={{backgroundColor: currentColor}}
                className={'topNavigation'}>
                <figure>
                    <Link to={'/'} className={'no-deco mobLogo align-center'}>
                        <img src={Logo} className={'logo'} alt=""/>
                        <span className=" mgL2 h5 light_color">G7 <span className={'green'}>Capital</span></span>
                    </Link>
                </figure>
                <input id={'menu-check'} type={'checkbox'}/>
                <label htmlFor={'menu-check'} className={'menu'}>
                    <span className={'menu-bar'}>&nbsp;</span>
                </label>
                <ul className="topNavigation-list">

                    <li className="topNavigation-list-item">
                        <Link to={'/'} className={'topNavigation-list-item-link'}>Home</Link>
                    </li>
                    <li className="topNavigation-list-item">
                        <Link to={'/about'} className={'topNavigation-list-item-link'}>Who We are</Link>
                        <ul className="topNavigation-option-list">
                            <li><Link to={'/about'}>About</Link></li>
                            <li><Link to={'/blog'}>blog</Link></li>
                            <li><Link to={'/privacy_policy'}>Privacy Policy</Link></li>
                            <li><Link to={'/terms_conditions'}>Terms & Conditions</Link></li>
                            <li><Link to={'/faqs'}>FAQs</Link></li>

                        </ul>
                    </li>
                    <li className="topNavigation-list-item"><span className={'topNavigation-list-item-link'}>What We Sell


                </span>
                        <ul className="topNavigation-option-list">
                            <li> <Link to={'/cash-management'}>Cash management</Link></li>
                            <li> <Link to={'/crypto'}>Crypto currencies</Link></li>
                            <li> <Link to={'/stocks'}>Stocks</Link></li>
                            <li><Link to={'/options'}>Options</Link> </li>
                            <li><Link to={'/tesla'}>Tesla</Link></li>
                            <li><Link to={'/atm'}>ATM</Link></li>

                            <li><Link to={'/tokenized-investments'}>Tokenized Investments</Link></li>
                            <li><Link to={'/how-to-invest'}>How to Invest</Link></li>
                        </ul>
                    </li>
                    <li className="topNavigation-list-item"><span className={'topNavigation-list-item-link'}>Service</span>
                        <ul className="topNavigation-option-list">
                            <li> <Link to={'/trust-fund'}>Trust Fund</Link></li>
                            <li><Link to={'/offshore-investment'}>Offshore Investment</Link> </li>
                            <li><Link to={'/tax-free'}>Tax Free Investment</Link></li>
                        </ul>
                    </li>
                    <li className="topNavigation-list-item"><Link to={'/buy-token'} className="topNavigation-list-item-link">Buy G7 Token</Link></li>
                    <li className="topNavigation-list-item"><Link to={'/investor-relations'} className="topNavigation-list-item-link">Investor Relations</Link></li>
                    <li className="topNavigation-list-item"><Link to={'/iswap'} className="topNavigation-list-item-link">Coin Exchange</Link></li>
                    <li className="topNavigation-list-item"><Link to={'/login'} className="topNavigation-list-item-link">Login</Link></li>
                </ul>
            </div>
        </>
    );
};

export default HomeNavigation;