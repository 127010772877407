import axiosInstance from "./axiosInstance";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from "../hoc/AuthContext";
import { Link} from "react-router-dom";

export const Misc = () => {
    const [show_, setShow] = useState(false);
    const u_ = useContext(AuthContext);

    const checkStatus = useCallback(() => {
        const user = JSON.parse(u_.user) || JSON.parse(localStorage.getItem("G7_li"));
        axiosInstance.get(`/acc/get/user/${user._id}`)
            .then(res => {
                if (res.data.user === "pending") {
                    setShow(true);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, [u_]);

    useEffect(() => {
        checkStatus();
    }, [checkStatus]);

    return (
        <>
            {show_ &&
                <div className={'altCard mgB2'}>
                    <p className="h7 light_color">Thank you, we just need a few more details to get your account set up. Once you’ve completed Identity/KYC tabs, you should be ready to start investing. You can also complete your Tax and Bank info now, otherwise leave these for later. Our support team will gladly assist at support@g7capital.org if there are any issues with verification of your identity.</p>
                    <Link to={'/client/kyc'} className={'btn btn-green mgT2'}>Complete KYC</Link>
                </div>
            }
        </>
    );
}
