import React from 'react';
import Navigation from "../../../components/navigation";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import ScrollToTop from "../../../hoc/scrollToTop";
const ReferralPage = () => {
    ScrollToTop();
    return (
        <div>
            <Navigation/>
             <div className="container ">
                <div className="card mgT4">
                    <div className="grid-item2_4">
                        <h4 className="h4">Our referral bonus</h4>

                       <p className="h5 mgT2">
                           Our first of its kind referral bonus package is designed to reward our referees without stress. Our referral package does not influence your transactions or earning in any way so you are not at all mandated to refer, however If you do refer then a coupon is automatically issued to you once your referral registers and completes first contract trade exchange. After your referred completes the first exchange your coupon becomes valid and you can now proceed to use the coupon code to reduce your transaction fee when next you want to carry out a new trade exchange contract. To get this discount, insert your coupon code in the space provided before paying or your insurance fee and a discount of $10 will be applied to your insurance fee. This discount is only available after you have carried out three successful trade exchange transactions but you can accumulate coupon code early by referring and then start using them individually later, to save massively. Each issued coupon carry a $10 discount so the more you refer the more discounts you get enabling you to automatically save and indirectly earn more.
                       </p>
                    </div>
                    <div className="grid-item2_4">
                        <h5 className="h5 mgV2">Characteristics of our referral method</h5>
                        <div className="pre text-left">
                            <ol>
                                <li>	Only members who have already carried out their first exchange trade can refer new members.</li>
                                <li>	Referral coupon discount can only be used after the third stage of insurance.</li>
                                <li>	Coupons are only valid after the referred member has completed first trade exchange contract. </li>
                                <li>	A coupon code has a one-time usage validity after which it expires.</li>
                                <li>	Coupon code is not transferable or saleable so only you are allowed to use it.</li>
                                <li>	A coupon removes $10 from insurance fee when applied during contract fee payment.</li>
                                <li>	To get discount insert code in the coupon code box provided at insurance fee payment page.</li>
                                <li>	If coupon is valid then you are eligible to pay only $40 insurance fee for the transaction.</li>
                            </ol>
                        </div>
                    </div>
                </div>
             </div>
            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default ReferralPage;