import React, {useState, useEffect, useCallback} from 'react';
import axios from "axios";
import {Link, useSearchParams, useNavigate} from 'react-router-dom';
import authImg from '../../assets/auth.jpg'
import Loader from "../../components/loader";
import AxiosInstance from "../../helpers/axiosInstance";
import {toast, ToastContainer} from "react-toastify";
const Register = () => {
    const [searchParams]  = useSearchParams()
    const [country,setCountry] = React.useState( '');
    const [countries,setCountries] = React.useState([]);
    const [params] = useState( Object.fromEntries([...searchParams])||{})
    const [index,setIndex] = React.useState(0);
    console.log(params)
    const [formData,setFormData] = useState({
        firstName:"",
        lastName:"",
        password:"",
        email:"",
        pin:"",
        zipCode:"",
        accountType:"",
        gender:"",
        dob:"",
        address:"",
        city:"",
        state:"",
        referral: params.referral


    });

    //stage one names
    //stage2 password and pin
    ///stage 3 phone& date of birth Gender & aCCOUNT TYpe
    //checking, savings & fixed deposit

    //last stage address, zip code, country, state, city
    const [loading,setLoading] = React.useState(false);
    const [phases] = useState([<Phase1/>,<Phase2/>,<Phase3/>,<Phase4 params={params}/>])
    const navigate = useNavigate();
    function registerUser(){

        // e.preventDefault();
        setLoading(true)
        const data = {...formData}
        data.referral = params.referral;
        setFormData(data)
        console.log(formData,params)
        AxiosInstance.post('/auth/reg',formData)
            .then(res=>{
                toast.success(res.data.msg);
                setLoading(false)
                setTimeout(()=>{
                    navigate('/login')
                },2500)
            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })

    }

    const getInitCountry = React.useCallback(()=>{
        const countriesUrl ='https://www.universal-tutorial.com/api/countries/';

        const genUniversalToken = 'Z9FvIK4-lhGzkE9LNOAONYZpmcrn4Tt5JEyP3z27_qd-lHTqXAyRAn0eaYEfYbrZyK0';
        axios.get("https://www.universal-tutorial.com/api/getaccesstoken",{
            headers:{
                "Accept": "application/json",
                "api-token": genUniversalToken,
                "user-email": "doomrumrum@gmail.com"
            }
        }).then(res=>{
            const tk = res.data.auth_token;
            axios.get(countriesUrl,{
                headers: {
                    'Authorization':'Bearer ' + tk,
                    "Accept": "application/json"
                }
            })
                .then(res=>{
                    console.log(res.data)
                    setCountries(res.data);
                    setCountry(res.data[229].country_name)
                    // console.log(res.data)
                })
                .catch(err=> {

                })
        }).catch(err=>{

        })
         
    },[setCountries])


    useEffect(()=>{
        getInitCountry();
    },[getInitCountry]);
    function handleFormData(data){
        const formData_ = formData;
        for (let i in data){
            formData_[i] = data[i];
        }

        setFormData(formData_);
    }
    function Phase1() {

        const [email,setEmail] = React.useState('');
        const [firstName,setFistName] = React.useState('');
        const [lastName,setLastName] = React.useState('');
        return <div>
            {/*<div className="form-img">*/}
            {/*    <input type="file" style={{display:"none"}} id={"avatar"}/>*/}
            {/*    <label htmlFor="avatar" className={"h6"}>Choose Profile Photo</label>*/}
            {/*</div>*/}
            <div className="form-input-box mgT2 ">
                <input value={firstName} onChange={e=>setFistName(e.target.value)} placeholder='First name'/>
            </div>
            <div className="form-input-box ">
                <input value={lastName} onChange={e=>setLastName(e.target.value)} placeholder='Other Names'/>
            </div>
            <div className="form-input-box">
                <input value={email} onChange={e=>setEmail(e.target.value)} placeholder='email'/>
            </div>
            <button className="btn btn-blue mgT2" style={{width:"100%"}} onClick={index===3?registerUser:()=>{
                handleFormData({
                    lastName,
                    firstName,
                    email
                });
                console.log(formData)
                setIndex(index + 1)
            }}>{index===3?"Register":"Continue"}</button>

        </div>
    }
    function Phase2() {
        const [password,setPassword] = React.useState('');
        const [pin,setPin] = React.useState('');
        return <div>
            <div className="form-input-box mgT2 ">
                <input value={password} onChange={e=>setPassword(e.target.value)} placeholder='Password'/>
            </div>
            <div className="form-input-box ">
                <input value={pin} onChange={e=>setPin(e.target.value)} placeholder='Pin '/>
            </div>
            <button className="btn btn-blue mgT2" style={{width:"100%"}} onClick={index===3?registerUser:()=>{
             if (password.length>=6&& pin.length===4){
                 handleFormData({
                     password,pin
                 });
                 setIndex(2)
             }else{

             }
            }}>{index===3?"Register":"Continue"}</button>
        </div>
    }
    function Phase3() {
        const [phone,setPhone] = React.useState('');
        const [gender,setGender] = React.useState('Rather not say');
        const [dob,setDob] = React.useState(Date);
        return <div>

            <div className="form-input-box mgT2 ">
                <input value={phone} onChange={e=>setPhone(e.target.value)} placeholder='Phone'/>
            </div>
            <div className="form-input-box mgT2 ">
                <input value={dob} type={'date'} onChange={e=>setDob(e.target.value)} placeholder='Date of Birth'/>
            </div>

            <div className="form-input-box ">
                <select value={gender} onChange={e=>setGender(e.target.value)}>
                    <option >Male</option>
                    <option >Female</option>
                    <option >Rather not say</option>
                </select>
            </div>
            <button className="btn btn-blue mgT2" style={{width:"100%"}} onClick={index===3?registerUser:()=>{
                if (dob.length>=0&& phone.length>8){
                    handleFormData({
                        dob,phone,gender
                    });
                    setIndex(3)
                }else{

                }
            }}>{index===3?"Register":"Continue"}</button>
        </div>
    }
    function Phase4() {

        const [state,setState_province] = React.useState('');
        const [city,setCity] = React.useState('');
        const [address,setAddress] = React.useState('');
        const [zipCode,setZipCode] = React.useState('');
        return <div>
            <ToastContainer/>
            <div className="form-input-box mgT2 ">
                <input value={address || ""} onChange={e=>setAddress(e.target.value)} placeholder='Address'/>
            </div>
            <div className="form-input-box ">
                <input value={zipCode || ""} onChange={e=>setZipCode(e.target.value)} placeholder='Zip code'/>
            </div>
            <div className="form-input-box">
                <input value={state || ""} onChange={e=>setState_province(e.target.value)} placeholder='State'/>
            </div>
            <div className="form-input-box">
                <input value={city || ""} onChange={e=>setCity(e.target.value)} placeholder='City'/>
            </div>


            <button className="btn btn-blue mgT2" style={{width:"100%"}} onClick={()=>{
                if (zipCode.length>=0 && city.length>0&& state.length>0 && address.length>0){
                    handleFormData({
                        zipCode,
                        state,
                        city,
                        address

                    });
                    registerUser();

                }else{

                }
            }}>{index===3?"Register":"Continue"}</button>

        </div>
    }
        return (
            <div className='authBox' style={{backgroundImage:`linear-gradient(#0003,#0006),url(${authImg})`}}>


                <ToastContainer/>
                {
                    loading?<Loader/>:null
                }
                <div className="authBox-card">
                    <Link to='/' className='no-deco centerText'>
                        <div className="disp_flex align_center justify-centre">
                            <h2 className='h4'>G7 <span className={'green'}>Capital</span></h2>
                        </div>
                    </Link>

                    <div className="centerText">
                        <h6 className="h5 mgT2">Create Account</h6>
                        <p className="h7 grey-text">Please register your account with us to take the benefits of our Online Banking facilities.</p>
                        {
                           params && params?.referral?.length?<p className={'h5 greenText'}>Referral code: {params.referral}</p>:null
                        }
                    </div>
                    <hr className="hr mgV2"/>
                    <div className="mgT2">
                        <div>
                            {phases[index]}
                            <div className="auth_circle-box mgT2">
                                {
                                    phases.map((e,i)=> <span onClick={()=>setIndex(i)} key={e + i} className={index===i?"auth_circle-active":"auth_circle"}>&nbsp;</span>)
                                }

                            </div>
                        </div>

                      <p className={'h6 mgT2'}>Already got an account? <Link to={'/login'} className={'link'}>Login</Link></p>
                    </div>





                </div>
            </div>
        )
};

export default Register;