import React from 'react';
import Card from "../../../components/Card";
import axiosInstance from "../../../helpers/axiosInstance";

const SupportForm = () => {
    const [name,setName] = React.useState('')
    const [msg,setMsg] = React.useState('')
    const [email,setEmail] = React.useState('')
    const [subject,setSubject] = React.useState('')
    const [result,setResult] = React.useState('')
    function submitForm(e) {
        e.preventDefault();
        let data = {
            msg,subject,email,name
        }
        axiosInstance.post('/supportMsg',data)
            .then(res=>{
                setResult(res.data.status);
            })
            .catch(err=>{
                console.log(err)
            })

    }
    React.useEffect(()=>{
        if (result?.length>2){
            setTimeout(()=>{
                setResult('')
            },3500)
        }
    },[result])

    return (
        <Card className="grid-item2_4">
            {result.length>2?
             <div className='card'>
                 <h5 className="green-text h5">{result}</h5>
             </div>:null
            }
            <form onSubmit={submitForm}  className="form">

                <p className="h3 mgT1">Ready to get Started?</p>
                <div className="mgT2">
                    <div className="form-input-box">
                    <input value={subject} onChange={(e)=>setSubject(e.target.value)}  className='form-input' placeholder='Subject *'/>
                </div>
                    <div className="form-input-box">
                        <input  value={name} onChange={(e)=>setName(e.target.value)}  className='form-input'  placeholder='Enter Name *'/>
                    </div>
                    <div className="form-input-box">
                        <input  value={email} onChange={(e)=>setEmail(e.target.value)}  className='form-input' placeholder='Enter Email *'/>
                    </div>

                    <div className="form-input-box">
                        <textarea value={msg} onChange={(e)=>setMsg(e.target.value)}  className='form-input' placeholder='Message *'/>
                    </div>
                </div>
                <button className="btn-blue btn">Send Message</button>
            </form>
        </Card>
    );
};

export default SupportForm;