import axiosInstance from "./axiosInstance";
import React, { useCallback, useContext, useEffect } from 'react';
import { AuthContext } from "../hoc/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";


export const useRole = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const u_ = useContext(AuthContext);

    const checkRole = useCallback(() => {
        const user = JSON.parse(u_.user) || JSON.parse(localStorage.getItem("G7_li"));
        axiosInstance.get(`/acc/get/user/${user._id}`)
            .then(res => {
                if (res.data.role === 1 && location.pathname.startsWith('/client')) {
                    navigate('/admin', { replace: true });
                }
                if (res.data.role === 0 && location.pathname.startsWith('/admin')) {
                    navigate('/client');
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, [u_.user, navigate, location.pathname]);

    useEffect(() => {
        checkRole();
    }, [checkRole]);
};

export const numberWithCommas = (x) => {
    return Number(x) > 0 ? parseFloat(x).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : x;
};

const visaPattern = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const mastPattern = /^(?:5[1-5][0-9]{14})$/;
const amexPattern = /^(?:3[47][0-9]{13})$/;
const discPattern = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;

export const validateCreditCardNumber = ({ ccNum }) => {
    const isVisa = visaPattern.test(ccNum);
    const isMast = mastPattern.test(ccNum);
    const isAmex = amexPattern.test(ccNum);
    const isDisc = discPattern.test(ccNum);

    if (isVisa || isMast || isAmex || isDisc) {
        if (isVisa) return "visa";
        if (isMast) return "master";
        if (isAmex) return "amex";
        if (isDisc) return "disc";
    } else {
        return "none";
    }
};
