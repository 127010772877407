import React from 'react';
import FooterC from "../../../hoc/FooterC";
import ScrollToTop from "../../../hoc/scrollToTop";
import HomeNavigation from "../../../components/HomeNavigation";
import hero2 from "../../../assets/hero5.jpg";
import TopBar from "../../../components/topBar";

const Terms = () => {

    ScrollToTop();
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header  className='altHero' style={{backgroundImage:`linear-gradient(#0006,#0008),url(${hero2})`,height:"70vh"}}>

                 <h5 className="h4 light_color mgT4">Terms & Conditions</h5>
            </header>

            <div className="center-container mgT4 mgB4">
                <h4 className="h4">Introduction</h4>
                <div className="greyCard mgT4">
                    <p className="h6">This Agreement is entered into by and these Terms & Conditions (hereinafter referred to as the “Agreement”) shall regulate the relationship between g7capital and the user (a natural or legal entity) (hereinafter referred to as the “Client”) of www.g7capital.org (hereinafter referred as the “Website”).</p>

                 <ul>

                     <li className="h6">The Client confirms that he/she has read, understood and accepted all information, conditions and terms set out on our website which are open to be reviewed and can be examined by the public and which include important legal Information.</li>
                     <li className="h6">By accepting this Agreement, the Client agrees and irrevocably accepts the terms and conditions contained in this Agreement, its annexes and/or appendices as well as other documentation/information published on the Website, including without limitation to the Privacy Policy, Payment Policy, Withdrawal & Refund Policy, Code of Conduct, Order Execution Policy and Anti-Money Laundering Policy. The Client accepts this Agreement by registering an Account on the Website and making payment for an Investment package via cryptocurrency. By accepting the Agreement, and subject to the Company’s final approval, the client enters into a legal and binding agreement with the Company.</li>
                     <li className="h6">The terms of this Agreement shall be considered accepted unconditionally by the Client upon the Company’s receipt of an advance payment made by the Client. As soon as the Company receives the Client’s payment, every operation made by the Client on the Investment Platform shall be subject to the terms of this Agreement and other documentation/information on the Website.</li>
                     <li className="h6">The Client hereby acknowledges that each and any Operation, activity, transaction, order and/or communication performed by him/her on the Investment Platform, including without limitation through the Account, and the Website, shall be governed by and/or must be executed in accordance to, the terms and conditions of this Agreement and other documentation/information on the Website.</li>
                     <li className="h6">By accepting this current agreement, the Client confirms that he/she is able to receive information, including amendments to the present Agreement either via email or through the Website.</li>
                     <li className="h6">
                         All terms and and conditions contained herein, including without limitation, shall at all times be applicable to the Legal Entity and the latter shall conform with such terms and conditions, obligations and rights at all times.</li>
                 </ul>

                    <h4 className="h4 mgT4">Terms</h4>
                <div className="greyCard mgT4">
                 <ul>

                     <li className="h6">Account– means unique personified account registered in the name of the Client and which contains all of the Client’s transactions/ operations on the Investment Platform (as defined below) of the Company.</li>
                     <li className="h6">Ask– means the higher price in a quote. The price the Client may buy at.</li>
                     <li className="h6">Bid– means the lower price in a quote. The price the Client may sell at.</li>
                     <li className="h6">Classic Options– means CFDs on stock options.</li>
                     <li className="h6">CFD (contract for difference)– means a tradeable contract entered into between the Client and the Company, who exchange the difference in the value of an Investment, as specified on the Investment Platform at the time of opening a Transaction, and the value of that Investment at the contract’s end.</li>
                     <li className="h6">Digital Option Contract– means a type of derivative instrument where the Client earns a payout if they correctly predict the price movement of the underlying asset at the time of the option’s expiry. The prediction can be made as to whether the value of the underlying asset will fall above or below the strike price at time of expiration. Should the option expire at the selected strike price, it will be considered to expire out-of-the money and will result in the loss of the invested amount.</li>
                     <li className="h6">Execution– means the execution of Client order(s) by the Company acting as the Client’s counterparty as per the terms of the present agreement.</li>
                     <li className="h6">A client that is a legal entity can register with the Company not through any other means but through the registration button below each plan , the starter plan run an IPV4 network. All terms and conditions contained herein, including without limitation to 1 to 5 above, shall at all times be applicable to the Legal Entity and the latter shall conform with such terms and conditions, obligations and rights at all times.</li>
                     <li className="h6">KYC documents– means the documents to be provided by the Client, including without limitation to the a copy of the passport or ID and utility bill of the Client, in case it is a natural person and/or certificates showing the management and ownership going all the way up to the ultimate beneficial owner, in case it is a legal entity, and any other documents the Company may request upon its sole discretion.</li>
                     <li className="h6">Market Maker– means a company which provides BID and ASK prices for financial instruments.</li>
                     <li className="h6">Operations– means actions performed at the Client’s Account, following an order placed by the Client,, connected with but not limited to crediting of funds, return of funds, opening and closing of Investment transactions/positions and/or that relate to financial instruments.</li>
                     <li className="h6">Prices– means the prices offered to the Client for each transaction which may be changed without prior notice. Where this is relevant, the “Prices” given through the Investment Platform.</li>
                 </ul>
                </div>
                    <h4 className="h4 mgT4">Subject of the agreement</h4>
                    <div className="greyCard mgT4">
                        <ul>
                            <li className="h6">The subject of the Agreement shall be the provision of Financial services to the Client by the Company under the Agreement and through the Investment Platform.</li>
                            <li className="h6">The Company shall carry out all transactions as provided in this Agreement on an execution-only basis, neither managing the account nor advising the Client. The Company is entitled to execute transactions requested by the Client as provided in this Agreement even if the transaction is not beneficial for the Client. The Company is under no obligation, unless otherwise agreed in this Agreement and/or other documentation/information on the Website,to monitor or advise the Client on the status of any transaction, to make margin calls, or to close out any of the Client’s open positions. Unless otherwise specifically agreed, the Company is not obligated to make an attempt to execute the Client’s order using quotes more favorable than those offered through the Investment Platform.</li>
                            <li className="h6">The Investment and Ancillary Services which the Company should provide under the terms of the Agreement are stated below, and the Company will provide them in its capacity as a market maker under the terms of this Agreement. The Services that the Company provides in relation to one or more Financial Instruments are the following (the list below shall not be regarded as exhaustive).</li>
                        </ul>
                    </div>
                </div>
            </div>
            <FooterC/>
        </div>
    );
};

export default Terms;