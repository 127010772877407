import React, {useState} from 'react';
import AdminLayout from "../../layout/AdminLayout";
import {BiArrowToBottom, BiArrowToLeft, BiArrowToTop, BiCreditCard, BiUser} from "react-icons/bi";
import axiosInstance from "../../helpers/axiosInstance";
import {AuthContext} from "../../hoc/AuthContext";
import Modal from "../../components/Modal";
import AxiosInstance from "../../helpers/axiosInstance";
import {numberWithCommas, useRole} from "../../helpers/helpers";
// import wallet from "../client/subPages/wallet";
import {toast, ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";


const AdminHome = () => {
    // useRole()
 const [showWalletMod,setShowWalletMod] = useState(false);
 const [wallAdd,setWallAdd] = useState('');
 const [wallType,setWallType] = useState('BTC');

    const userInfo = React.useContext(AuthContext);
    const [usersData,setUsersData] = React.useState({
        active :0,
        inactive:0,
        total: 0,
        withdrawal:0,
        deposit:0
    });
    const getUserData = React.useCallback(() => {
        axiosInstance.get('/sup/acc/get/all/usersInfo',{},{
            headers:{
                authorization:userInfo.token
            }
        })
            .then( res=>{
                // console.log(res.data);
               setUsersData(res.data);
            })
            .catch(err=>console.log(err))
    },[userInfo]);


    React.useEffect(()=>{
        getUserData();
        return ()=>{}

    },[getUserData]);

    function createNewAddress() {
        AxiosInstance.post('/sup/misc/create/walletAddress',{wallAdd,wallType})
            .then(res=>{
                toast.success(res.data.msg);
                setWallAdd('');
                setTimeout(()=>{
                    setShowWalletMod(false)
                },1000)


            })
            .catch(err=>{
                toast.error(err.response.data)
            })
    }
    return (
        <AdminLayout>
                <h4 className='h4'>Overview</h4>
            <ToastContainer/>
                <div className="mgT4">
                   <div className="grid">
                       <div className="grid-item1_3 overlayCard display_flex">
                           <div className="blueCard display_flex align-center justify-centre">
                               <BiUser size={28}/>
                           </div>
                           <div className="mgL2">
                               <p className="h6">Total Users Account</p>
                               <h4 className="h4 mgT2">{usersData.total}</h4>
                           </div>
                       </div>
                       <div className="grid-item1_3 overlayCard display_flex">
                           <div className="greenCard display_flex align-center justify-centre">
                               <BiUser size={28}/>
                           </div>
                           <div className="mgL2">
                               <p className="h6">Active Accounts</p>
                               <h4 className="h4 mgT2">{usersData.active}</h4>
                           </div>
                       </div>
                       <div className="grid-item1_3 overlayCard display_flex">
                           <div className="redCard display_flex align-center justify-centre">
                               <BiUser size={28}/>
                           </div>
                           <div className="mgL2">
                               <p className="h6">Inactive Accounts</p>
                               <h4 className="h4 mgT2">{usersData.inactive}</h4>
                           </div>
                       </div>

                   </div>
                </div>
            <div className="grid- mgT4">
                {/*<div className="grid-item1_3 admin-Insurance-card text-center">*/}
                {/*    <BiChart className='md_icon' color='white'/>*/}
                {/*    <h3 className="h2">${total}</h3>*/}
                {/*</div>*/}
                <div className="grid-item1_4 overlayCard display_flex">
                    <div className="orangeCard display_flex align-center justify-centre">
                        <BiArrowToBottom size={28}/>
                    </div>
                  <div className="mgL2 mgT2">
                      <p className="h6">Debit Transactions</p>
                      <h4 className="h4 mgT2">${numberWithCommas(usersData?.withdrawal)}</h4>
                  </div>
                </div>
                <div className="grid-item1_4 overlayCard display_flex">
                    <div className="greenCard display_flex align-center justify-centre">
                        <BiArrowToTop size={28}/>
                    </div><div className="mgL2 mgT2">

                    <p className="h6">Credit Transactions</p>
                    <h4 className="h4 mgT2">${numberWithCommas(usersData.deposit)}</h4>
                </div>
                </div>
                <Link to={'/admin/card-management'} className="grid-item1_4 overlayCard display_flex no-deco">
                    <div className="greenCard display_flex align-center justify-centre">
                        <BiCreditCard size={28}/>
                    </div><div className="mgL2 mgT2">

                    <p className="h6">View Cards</p>
                </div>
                </Link>
            </div>
            {
                showWalletMod?<Modal closeModal={setShowWalletMod}>

                    <h5 className="h5">Create new Wallet address</h5>

                    <label  className="h7 grey-text">Wallet Address</label>
                    <div className="form-input-box">
                        <input value={wallAdd} onChange={e=>setWallAdd(e.target.value)} type="text" placeholder={'wallet address'}/>
                    </div>
                    <label  className="h7 grey-text">Wallet Type</label>
                    <div className="form-input-box">
                        <select value={wallType} onChange={e=>setWallType(e.target.value)}>
                            <option>BTC</option>
                            <option>ETH</option>
                            <option>XRP</option>
                            <option>USDT</option>
                            <option>BNB</option>
                        </select>
                    </div>
                    <button onClick={createNewAddress} className="btn btn-blue">Add new</button>
                </Modal>:null
            }
            <button className="btn btn-blue mgT8" onClick={()=>setShowWalletMod(true)}>Add Wallet Address</button>
        </AdminLayout>
    );
};

export default AdminHome;