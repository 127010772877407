import React from 'react';
import DashboardLayout from "../../../layout/dashboardLayout";
import {WithdrawFunds} from "./wallet";

const Withdrawal = () => {
    return (
        <DashboardLayout>
            <h2 className='h4'>Withdrawal</h2>
            <div className={'mgT4 outlined-card'}>
                <WithdrawFunds/>
            </div>
        </DashboardLayout>
    );
};

export default Withdrawal;