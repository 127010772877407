import React from 'react';
import DashboardLayout from "../../../layout/dashboardLayout";

const AllArticles = () => {
    return (
        <DashboardLayout>
            
        </DashboardLayout>
    );
};

export default AllArticles;