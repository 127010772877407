import React from 'react';
import AdminLayout from "../../../layout/AdminLayout";
import Table from "../../../hoc/Table";
import moment from "moment";
import Moment from 'react-moment';

const LoanManagement = () => {
    function formatDate(value) {
        return  <Moment format='DD/MM/YYYY'>{value}</Moment>
    }
    return (
        <AdminLayout>
            <h4 className="h4">Loan Management</h4>
            <div className="mgT2">
                <Table
                    filters={true}
                    type='dark-table'
                    headers={['Account','Email','Date',
                       "Name","Amount","Duration","Reason","Loan ID", 'Status','Action']}
                    body={
                        <>

                            <tr key={Math.random()}>

                                <td>1234567890</td>
                                <td>Hello@ggmail.com</td>
                                <td>{formatDate(Date.now)}</td>
                                <td>
                                    <input type="text" placeholder={'response'}/>
                                </td>
                                <td></td>
                                <td>1 week</td>
                                <td></td>
                                <td>Delivered</td>
                                <td>#sfksanbdjbgjdb</td>
                                <td className={'display_flex'}>
                                    <button className="btn btn-green">Reply</button>
                                    <button className="btn-red btn mgL2">Delete</button>
                                </td>
                            </tr>
                        </>
                    }



                />
            </div>
        </AdminLayout>
    );
};

export default LoanManagement;