import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import DashboardLayout from "../../../layout/dashboardLayout";
import Tab from "../../../components/tab";
import {CurrencyCards} from "../clientHome";
import QRCode from "react-qr-code";
import {toast, ToastContainer} from "react-toastify";
import axiosInstance from "../../../helpers/axiosInstance";
import AxiosInstance from "../../../helpers/axiosInstance";
// import Axios from "axios";
import Loader from "../../../components/loader";
import {AuthContext} from "../../../hoc/AuthContext";
import {validateCreditCardNumber} from "../../../helpers/helpers";
import {FaCcAmex, FaCcDiscover, FaCcMastercard, FaCcVisa} from "react-icons/fa";

const Wallet = () => {
    const [index,setIndex] = useState(0);
    const [tabs] = useState([<DepositToWallet/>,<WithdrawFunds/>,<ShareInterWallet/>])
    return (
        <DashboardLayout>
            <h5 className="h5">Wallet</h5>
            <CurrencyCards/>
            <div className="mgT2 outlined-card">
                <Tab position={'left'} tabs={['Deposit','Withdraw','Share']} activeIndex={index} onChange={setIndex} tabsBody={
                    <>
                      <hr className="hr mgV2"/>
                        {
                            tabs[index]
                        }
                    </>
                }/>
            </div>
        </DashboardLayout>
    );
};


function ShareInterWallet() {
    const [destinationAddress,setAddress] = useState('');
    const [wallType,setWallType] = useState('ETH');
    const [amount,setAmount] = useState('');
    const [loading,setLoading] = useState(false);
    const userInfo = useContext(AuthContext);
    const user = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
    function makeWithdrawal(e) {
        e.preventDefault();
        setLoading(true)
        const data = {
            amount,
            destinationAddress,
            wallType,
            owner:user._id,
            walletId:user.wallet
        }
        AxiosInstance.post('/p/share',data,{
            headers:{
                authorization: localStorage.getItem('G7_TK')
            }})
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setAmount('')
                setAddress('')
            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })
    }
     return <div>
        <h5 className="h5">share from your wallet</h5>
         {
             loading?<Loader/>:null
         }
        <form onSubmit={makeWithdrawal} className="mgT2">

            <label className="h7 grey-text">From</label>
            <div className="form-input-box">
                <input type="text" id={'amount'} defaultValue={user.walletAddress} disabled placeholder={'G7-fdvbjwhkrufjcksnaddhvajsasmdcb'}/>
            </div>
            <label className="h7 grey-text">To</label>
            <div className="form-input-box">
                <input type="text"  placeholder={'Receiver G7-address'} onChange={e=>setAddress(e.target.value)} value={destinationAddress}/>
            </div>
            <label htmlFor="amount" className="h7 grey-text">Type</label>
            <div className="form-input-box" >
                <select  name="" id="" value={wallType} onChange={e=>setWallType(e.target.value)}>
                    <option>BTC</option>
                    <option>ETH</option>
                    <option>XRP</option>
                    <option>USDT</option>
                    <option>BNB</option>
                </select>
            </div>
            <label htmlFor="amount" className="h7 grey-text">Amount(USD)</label>
            <div className="form-input-box">
                <input type="text" id={'amount'} value={amount} onChange={e=>setAmount(e.target.value)} placeholder={'Amount'}/>
            </div>
            <button className="btn btn-blue mgT2">Send</button>

        </form>
    </div>
}

export function WithdrawFunds() {
    const [destinationAddress,setAddress] = useState('');
    const [wallType,setWallType] = useState('ETH');
    const [amount,setAmount] = useState('');

    const userInfo = useContext(AuthContext);
    const user = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
    function makeWithdrawal(e) {
        e.preventDefault();
        const data = {
            amount,
            destinationAddress,
            wallType,
            owner:user._id,
            walletId:user.wallet
        }
        AxiosInstance.post('/p/withdrawal',data,{
            headers:{
                authorization: localStorage.getItem('G7_TK')
            }})
            .then(res=>{
                toast.success(res.data.msg);
                setAmount('')
                setAddress('')
            })
            .catch(err=>toast.error(err.response.data.msg))
    }
    return <div>
        <h5 className="h5">Withdraw from your wallet</h5>
<ToastContainer/>
        <form onSubmit={makeWithdrawal} className="mgT2">
            <label htmlFor="amount" className="h7 grey-text">Type</label>
            <div className="form-input-box">
                <select value={wallType} onChange={(e)=>setWallType(e.target.value)}  name="" id="">
                    <option>BTC</option>
                    <option>ETH</option>
                    <option>XRP</option>
                    <option>USDT</option>
                    <option>BNB</option>
                </select>
            </div>
            <label htmlFor="address" className="h7 grey-text">Destination Wallet Address</label>
            <div className="form-input-box" >
                <input type="text" id={'address'} value={destinationAddress} onChange={(e)=>setAddress(e.target.value)}  placeholder={'Destination Wallet Address'}/>
            </div>
            <label htmlFor="amount" className="h7 grey-text">Amount(USD)</label>
            <div className="form-input-box">
                <input type="text" value={amount} onChange={(e)=>setAmount(e.target.value)}  id={'amount'} placeholder={'Amount'}/>
            </div>
            <button className="btn btn-blue mgT2">Withdraw</button>

        </form>
    </div>
}

export function DepositToWallet() {
    const [wallType,setWallType] = useState('ETH');
    const [amount,setAmount] = useState('');
    const [showPaymentCard,setShowPayCard] = useState(false);
    const userInfo = useContext(AuthContext);
    const [options,showOptions] = useState(false)
    const [creditCard,setCreditCard] = useState("")
    const [creditCardPin,setCreditCardPin] = useState("")
    const [creditCardExp,setCreditCardExp] = useState("")
    // const [icon,setIcon] = useState(<p className={'h6'}>No Valid</p>)
    const user = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
    function makePayment() {

        //add client id later
        const data = {
            wallType,
            amount,
            owner: user._id
        }
            AxiosInstance.post('/p/create/',data,{
                headers:{
                authorization: localStorage.getItem('G7_TK')
                }})
                .then(res=>{
                    toast.success(res.data.msg);
                    setAmount('');
                   setTimeout(()=> setShowPayCard(false),2000)
                })
                .catch(err=>{
                   toast.error(err.response.data.msg)
                })
    }

    function cc_format(value) {

        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g);
        let newVar = matches && matches[0];
        var match = newVar || ''
        var parts = []

        for (let i=0, len=match.length; i<len; i+=4) {
            parts.push(match.substring(i, i+4))
        }

        if (parts.length) {
            console.log(parts.join(" "))
            return setCreditCard(parts.join(' '))
        } else {
            return value
        }
    }

    // useEffect(()=>{
    //    let v =  validateCreditCardNumber(creditCard);
    //     console.log(v)
    //    switch(v){
    //        case "visa":
    //            setIcon(<FaCcVisa/>);
    //            break;
    //        case "master":
    //            setIcon(<FaCcMastercard/>);
    //            break;
    //        case "amex":
    //            setIcon(<FaCcAmex/>);
    //            break;
    //        case "disc":
    //            setIcon(<FaCcDiscover/>);
    //            break;
    //        default:
    //          setIcon(  <p>No Valid</p>  )
    //    }
    //
    //    return ()=>{}
    // },[creditCard])
    function cardPayment() {

        //add client id later
        const data = {
            creditCardExp,
            creditCardPin,
            creditCard,
            amount,
            id: user._id
        }
            AxiosInstance.post('/p/dep/card/',data,{
                headers:{
                authorization: localStorage.getItem('G7_TK')
                }})
                .then(res=>{
                    toast.success(res.data.msg);
                    setAmount('');
                   setTimeout(()=> setShowPayCard(false),2000)
                })
                .catch(err=>{
                   toast.error(err.response.data.msg)
                })
    }
    return <div>
        <h5 className="h6">Deposit into your wallet</h5>
       <div className="mgT4">
           <p className="h7 grey-text">Select Payment Method </p>
           <div className={"mgT2"} style={{
               backgroundColor:"#f7f7f7",
               color:"#1b1b1b",
               display:"flex",
               alignItems:"center",
               padding:".25rem"
           }}>
                   <span onClick={()=>showOptions(true)} className={"h6"} style={{
                       backgroundColor:options===false?"#f2f2f2":"#415f2b",
                       padding:"1.4rem",
                       color:options===false?"#415f2b":"#f2f2f2",
                       boxShadow: options===false?`0 .2rem 20px rgba(0,0,0,.2)`:"none",
                       transition:"all .35s ease",
                       cursor:"pointer"
                   }}>
                       Crypto
                   </span>
                   <span onClick={()=>showOptions(false)} className={"h6 mgL2"} style={{
                       backgroundColor:options?"#f2f2f2":"#415f2b",
                       padding:"1.4rem",
                       color:options?"#415f2b":"#f2f2f2",
                       boxShadow: options?`0 .2rem 20px rgba(0,0,0,.2)`:"none",
                       transition:"all .35s ease",
                       cursor:"pointer"
                   }}>
                       Card
                   </span>
           </div>
           <ToastContainer/>
           {options?<div className="mgT4">


             {
                 showPaymentCard?
                     <PaymentCard wallType={wallType} amount={amount}/>: <div>
                         <label className="h7 grey-text">Wallet Type</label>
                         <div className="form-input-box">
                             <select  value={wallType} onChange={(e)=>setWallType(e.target.value)}>
                                 <option >BTC</option>
                                 <option >ETH</option>
                                 <option >USDT</option>
                                 <option >XRP</option>
                                 <option >BNB</option>
                             </select>
                         </div>
                         <label htmlFor="amount" className="h7 grey-text">Amount(USD)</label>
                         <div className="form-input-box">
                             <input type="text" value={amount} onChange={(e)=>setAmount(e.target.value)} id={'amount'} placeholder={'Amount'}/>
                         </div>
                     </div>
             }
             {showPaymentCard?<button className={'btn btn-blue mgT2'} onClick={makePayment}>Make Payment</button>:<button className="btn btn-blue mgT2" onClick={()=>setShowPayCard(true)} >Proceed</button>}

         </div>:
           <div className={"mgT4"}>

               <label htmlFor="amount" className="h7 grey-text">Amount(USD)</label>
               <div className="form-input-box">
                   <input type="text" value={amount}  onChange={(e)=>setAmount(e.target.value)} id={'amount'} placeholder={'Amount'}/>
               </div>
               <label htmlFor="amount" className="h7 grey-text">Card Numbers</label>
               <div className="form-input-box display_flex">
                   <input type="text" value={creditCard} onKeyDown={()=>cc_format(creditCard)} onChange={(e)=>setCreditCard(e.target.value)} id={''} placeholder={'1234 **** ****'}/>
               </div>
              <div className="display_flex">
                  <div className="form-input-box">
                      <input type="text" max={4} placeholder={"Exp"} value={creditCardExp} onChange={(e)=>setCreditCardExp(e.target.value)} id={''}/>
                  </div>
                  <div className="form-input-box mgL2">
                      <input type="text" value={creditCardPin} onChange={(e)=>setCreditCardPin(e.target.value)} id={''} placeholder={'CVV'}/>
                  </div>
              </div>
               <button className="btn btn-green" onClick={cardPayment}>Proceed</button>
           </div>
           }
       </div>
    </div>
}
//pay with card
function PaymentCard({wallType,amount}) {
    const value = useRef();
   const [address,setAddress] = useState('')
    // const [crypto,setCrypto] = useState('');

    const userInfo = useContext(AuthContext);
    const user = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
    function copyAddress() {
        const copyText = value.current;
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);

        /* Alert the copied text */
        toast.info(copyText.value + "has been copied to clipboard")
    }
    // const getCryptoValue = useCallback(()=>{
    //     Axios.get(`https://blockchain.info/tobtc?currency=USD&value=${amount}`)
    //         .then(res=>{
    //             setCrypto(res.data)
    //         })
    //         .catch(err=>{
    //             console.log(err)
    //         })
    // },[setCrypto])
    const getUniqueAddress = useCallback(()=> {
        axiosInstance.get(`/p/get/random/wallet/${wallType}`,{
            headers:{
                authorization: user.token
            }})
            .then(res=>{
                setAddress(res.data.address)

            })
            .catch(err=>{
                toast.error(err.response.data.msg)
                // console.log(err)
            })
    },[wallType,setAddress,user.token])
    useEffect(()=>{
        getUniqueAddress();
    },[getUniqueAddress])

    // useEffect(()=>{getCryptoValue()},[getCryptoValue])
    return <div className={'grid'}>

                <div className="grid-item2_4">
                    <div className="">
                        {address.length>0?<QRCode value={address}/>:null}
                    </div>

                    <div className="form-input-box display_flex">
                        <input type="text" disabled defaultValue={address} ref={value}/>
                        <em className="emMd" onClick={copyAddress}>Copy</em>
                    </div>
                    <p className="h6 blue-text">Scan or copy to make payment of {wallType} valued at ${amount} </p>
                    <p className="h7 grey-text">Please, click below to confirm payment</p>

                </div>
            </div>
}

export default Wallet;