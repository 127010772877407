import React from 'react';

const AboutSubTabs = () => {

    const [tabIndex,setTabIndex] = React.useState(0)
    const tab = [
        {
        title:"our mission",text:"To continuously provide our clients with a secured, insured, stable, and profit oriented platform that empowers them with outstanding leverage that guarantees success and maximum profit in crypto acquisition regardless of their financial status, gender, investment knowledge, race, religion or geographical location."
            } ,

        {
            title: "our vision",
            text: "To revolutionalize the crypto investment cyberspace by providing crypto enthusiasts with state-of-the-art investment model that strengthen crypto adoption and prevalence as we continuously provide its community with ease of access and investment security that enhances the acquisition, exchange and usage of their beloved financial asset."
        }];
    function changeTab(e) {
     const index = tab.findIndex(i=>tab.indexOf(i)===e);
     setTabIndex(index)
    }
    return (
        <div className='subTabs'>

            <div className="subTabs-HBox mgT4">
                {
                    tab.map((tb,i)=>{
                        return <h4 key={tb.title} onClick={()=>changeTab(i)} className={i===tabIndex?"subTabs-header":"subTabs-headerInverted"}>{tb.title}</h4>
                    })
                }

                <p className="h5 grey-text mgT4 subTabs-body"> {tab[tabIndex].text}</p>
            </div>


        </div>
    );
};

export default AboutSubTabs;