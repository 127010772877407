import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
const Modal = (props) => {
    return (
        <div className={`modal`}>
            <div className="modal_top">
                <div onClick={()=>props.closeModal(false)} className="modal_top-close">
                    <AiFillCloseCircle/>
                </div>
            </div>
           <div className={`modal_body  ${props.className} `} >
               {props.children}
           </div>
        </div>
    );
};

export default Modal;