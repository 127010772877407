import React,{useContext} from 'react';
import DashboardLayout from "../../../layout/dashboardLayout";
// import QRCode from 'qrcode.react'
import TransactionCard from "../dashComponents/TransactionCard";
import {AiOutlineMessage} from "react-icons/ai";
import {AuthContext} from "../../../hoc/AuthContext";
import axiosInstance from "../../../helpers/axiosInstance";
const AllUserTransaction = () => {
    const [transactions,setTransactions] = React.useState([]);
    const [pg,setPg] = React.useState(1);
    const [totPages,setTotPages] = React.useState(1);

    const userInfo = useContext(AuthContext);
    const user = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
    function createPagination() {
        const list_ = [];
        for (let i = 1;i<=totPages;i++ ){
            list_.push(i)
        }

        return list_.map((e,i)=><li key={i} onClick={()=>setPg(e)}>{e}</li>)
    }
    const getTransactions = React.useCallback(()=>{
        axiosInstance.get(`/trx/get/all/${user._id}/?pg=${pg}`,{headers:{
                authorization: userInfo.token
            }})
            .then((res)=>{
                setTotPages(res.data.data.totalPages)
                setTransactions(res.data.data.docs)
            })
            .catch(err=>{
                console.log(err);
            })
    },[setTransactions,userInfo.token,pg,user._id])
    React.useEffect(()=>{
        getTransactions();
    },[getTransactions])
    return (
        <DashboardLayout>
            <h2 className='h4'>Transactions</h2>

            {/*<p className="h6 mgT1">Transaction of Last 6 months</p>*/}
            <div className="History-section width75 mgV4">
                {transactions?.length>0?
                    transactions.map((tr,i)=>{

                        return <TransactionCard key={i} transaction={tr} />
                    })
                    :<div className='error_icon-holder'>
                        <AiOutlineMessage className='error_icon'/>
                        <p className="h6 grey-text mgT4">You have no transactions Yet</p>
                    </div>

                }
               <div className="paginator">
                <ul className="paginator_list">
                    {
                        createPagination()
                    }
                </ul>
               </div>
            </div>
        </DashboardLayout>
    );
};

export default  AllUserTransaction;