import React, { useState, useEffect, useCallback } from 'react';
import AdminLayout from "../../../layout/AdminLayout";
import UserCard from "./userCard";
import Table from "../../../hoc/Table";
import axios from "../../../helpers/axiosInstance";
import {AuthContext} from "../../../hoc/AuthContext";
import axiosInstance from "../../../helpers/axiosInstance";
import {ToastContainer} from "react-toastify";
// import {useHistory} from 'react-router-dom';

const Users = () => {
    const [users,setUsers] = useState([]);
    const userInfo = React.useContext(AuthContext);
    const [currentPage,setCurrentPage] = useState(0);
    // const history = useHistory();
    const getUsers = useCallback(() => {
        axios.get(`/sup/acc/get/all-users`, {
            headers: {
                authorization: userInfo.token
            }
        })
        .then(res => {
            setUsers(res.data.users);
        })
        .catch(err => {
            console.error('Error fetching users:', err);
        });
    }, [userInfo.token]);



    useEffect(() => {
        getUsers();
    }, [getUsers]);

    function deleteUser(data, func) {
        axios.delete(`/sup/acc/del/user/${data._id}`, {
            headers: {
                authorization: userInfo.token
            }
        })
        .then(res => {
            if (users.length > 0) {  // Check if users array exists
                const updatedUsers = users.filter(user => user._id !== data._id);
                setUsers(updatedUsers);
              } else {
                console.warn("'users' array not found, cannot update list.");
              }
            func(false);
            setCurrentPage(currentPage);
        })
        .catch(err => {
            console.log(err);
        });
    }    
  
    function suspendUser(data) {
        axiosInstance.post(`/admin/suspendUser/${data._id}`,{},{
            headers:{
                authorization: userInfo.token
            }
        })
            .then(res=>{
                //reset the suspend value of data
                const index  = users.findIndex(u=>{
                    return u._id===data._id;
                });
                console.log(index);
                let oldUsers = [...users];
                oldUsers[index].suspended = !data.suspended;
                setUsers(oldUsers);
                console.log(oldUsers)
                setCurrentPage(currentPage);
                // data.suspended = res.data.user_status
            })
            .catch(err=>{
                console.log(err);
            })
    }


    function changePage(e) {

        if(e<0){
            setCurrentPage(0);
        }
        setCurrentPage(e);
    }


    return (
        <AdminLayout>
                <h4 className="h4">Users</h4>
                 <div className="mgT2 outlined-card">

                    <ToastContainer/>
                     <Table filters={true} type='dark-table' title={'users-list'} headers={['','#','Email','First Name', 'Surname','Reg Date','Wallet Address','address']} body={
                         <>
                             {

                                 users.length>0?

                                     users.map((user,i)=>{
                                         return <UserCard key={user._id} suspendUser={suspendUser} deleteUser={deleteUser} currentPage={currentPage}  data={user} id={i} list={users} setList={setUsers} />
                                     })
                                     : <tr>
                                         <td className='centerText' colSpan="7">
                                             Sorry Nothing Found
                                         </td>

                                     </tr>
                             }

                             </>
                     }/>
                 </div>
               <div className="paginator">
                   <ul>
                       <li onClick={currentPage!==0?()=>changePage((currentPage - 1)):null} className={currentPage===0?'inactiveP':null}>Prev</li>
                        <li>&nbsp;</li>
                       <li onClick={users.length!==0 || users.length>3 ?()=>changePage((currentPage + 1)):null} className={users.length===0 || users.length<4?'inactiveP':null} >Next</li>
                   </ul>
               </div>

        </AdminLayout>
    );
};

export default Users;