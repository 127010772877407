import React from 'react';
import FooterC from "../../../hoc/FooterC";
import hero2 from '../../../assets/hero5.jpg'
import we1 from '../../../assets/g7 house.jpg'
// import we2 from '../../../assets/we2.jpg'
import we3 from '../../../assets/we3.jpg'
import we4 from '../../../assets/we4.webp'
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import AboutHome from "../home/HomeAbout";
import FaqSection from "../home/Faq-section";
import HomeNavigation from "../../../components/HomeNavigation";
import {Link} from "react-router-dom";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";
const AboutPage = () => {
    useScrollToTop();
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
              <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`}}>

                  <div className=" width35 text-center mg2">
                      <h4 className="h1 light_color">WHO WE ARE</h4>
                  </div>
              </header>
            <div className="mgT4">

                <div className={'container grid'}>
                    <div className="grid-item2_4">

                        <h1 className="h3 width75">An Investment Firm established by Financial Experts </h1>
                        <p className="h6 mgT2 text_just">G7 capital is not just an investment,crypto or financial institution but a philanthropic movement with its simple aim,to help people reach financial freedom.
                            The idea was first begotten in the late 19’s by two realtors who bought and sold properties across Europe
                            Due to some bad financial decisions ran out of cash and when taxes came were bankrupt and had to improvise,acting as a broker and getting paid depending on the profits made from the market
                        </p>
                        <p className="h6 mgT1 text_just">“Risk is well managed when it’s other people’s money”
                            As clients increased the business had a body but was not yet a company until some crypto whales and financial analysts bought the idea in 2008
                            And the name G7 capital was first mentioned.
                            Our Aim
                            G capital  starting first in United States before spreading across Europe down to Africa(south Africa) aims at making a positive difference to both our clients and countries
                            since 2008 have expanded our scope and ambition globally.
                        </p>

                    </div>
                    <div className="grid-item2_4">

                                 <img src={we1} alt="" className={'fluid_img'}/>
                    </div>
                </div>
            </div>
            <AboutHome/>
            <div className="container grid">
                <div className="grid-item2_4">
                    <h5 className="h3">Listening to your business needs</h5>
                    <p className="h6 mgT2">
                        Where you invest your money matters. Avoid the loss of money which happens daily on the internet by investing with a company trusted by over 400,000 Investors.
                    </p>
                    <h5 className="h3 mgT2">Solutions tailored to your needs</h5>
                    <p className="h6 mgT2">
                        You earn daily when you invest with G7 Capital gaining high Returns on Investment. Not only do we guarantee high returns on your investments, we also guarantee the safety of your Funds/Investments.  </p>
                </div>
                <div className="grid-item2_4">
                    <img src={we3} alt="" className={'fluid_img'}/>
                </div>
            </div>
            <div className="container grid">
                <div className="grid-item2_4">
                    <img src={we4} alt="" className={'fluid_img'}/>
                </div>
                <div className="grid-item2_4">
                    <h5 className="h3">Our Mission</h5>
                    <p className="h6 mgT2">
                        To continuously provide our clients with a secured, insured, stable, and profit oriented platform that empowers them with outstanding leverage that guarantees success and maximum profit in crypto acquisition regardless of their financial status, gender, investment knowledge, race, religion or geographical location.  </p>
                    <h5 className="h3 mgT2">Our Vision</h5>
                    <p className="h6 mgT2">
                        To revolutionalize the crypto investment cyberspace by providing crypto enthusiasts with state-of-the-art investment model that strengthen crypto adoption and prevalence as we continuously provide its community with ease of access and investment security that enhances the acquisition, exchange and usage of their beloved financial asset.</p>
                </div>
            </div>
            <div className="mgT8 " style={{backgroundColor:'#f7f7f7'}}>
                <div className="container grid">
                    <div className="grid-item2_4">
                        <p className="h5 green">G7 Capital</p>
                        <h5 className="h2 mgT4">Our Purpose</h5>
                        <p className="h6 mgT4">
                           G7 Capital has always been a purposeful business. We began with the aim of making a positive difference to both our clients and the country in which we started and since ’91 have expanded our scope and ambition globally.</p>
                        <Link to={'/our-purpose'} className={'btn btn-green mgT2'}>Discover More</Link>
                    </div>
                    <div className="grid-item2_4">
                        <p className="h5 green">G7 Capital</p>
                        <h5 className="h2 mgT4">Our Culture</h5>
                        <p className="h6 mgT4">
                            The culture of G7 Capital reflects who we are as people. It is our shared values and norms that make us. We believe the strength of our culture is a source of competitive advantage, ensuring a healthy environment for debate and exchange of views. </p>
                        <Link to={'/our-culture'} className={'btn btn-green mgT2'}>Discover More</Link>
                    </div>
                </div>
            </div>
            <div className="mgV4 container">
                <h4 className="h4">Frequently Asked Questions</h4>
                <p className="h6 mgT1">Have any Questions?</p>
            <FaqSection/>
            </div>
             <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default AboutPage;