import React from 'react';
import FrequentQ from "./FrequentQ";
import '../../../sass/pages/otherPages.scss';
const FaqSection = () => {
    return (
        <div>
            <div className="grid mgT4">
                <div className="grid-item2_4">
                    <div className="frequently-section ">
                        <FrequentQ question='How do I get started? '
                                   answer='Navigate to our registration page and provide us with  accurate information about you, our system will automatically send you an email to verify your emails viability as it’s the main means of contacting you about transaction. Follow the link to complete your
                                      registration and log in after wards. Be sure to check if our email arrived in your junk box.'/>
                        <FrequentQ question='How do I open a new contract? ' answer='In your private office, click on the open new contract button, copy our central portfolios wallet address, proceed to your wallet and deposit $50 worth of bitcoin, copy the transaction hash and paste it in the proof of payment box provided. We will verify your payment and confirm your account within 2 hours to enable you open a new contract.'/>

                        <FrequentQ question='How much initial capital do I need to get started? ' answer='At cedefima, only a one time initial capital of $150 BTC is all you will ever need to acquire as much crypto as you wish.  Get started and we will secure and grow it for you.'/>





                    </div>
                </div>
                <div className="grid-item2_4">
                    <div className="frequently-section  ">
                        <FrequentQ question='What’s my insurance trading here? '
                                   answer='Your initial capital is insured in three indemnification stages that utilize a stable central portfolio to protect your trading capital till you have successfully transacted enough to replace your capital with your return on investment.'/>
                        <FrequentQ question='Does my insurance expire? '
                                                                                 answer='Yes, once we have successfully protected a new client initial capital to the tertiary stage of the insurance program, his initial investors’ protection protocol expires. This allows us to reduce our insurance liabilities and deduct our commission.   '/>
                        <FrequentQ question='Won’t I incur loss afterwards? ' answer='No you won’t, our insurance program is an extra layer of protection for our new investors’ funds, however our investors’ compliance policy in the form of a non refundable upfront contract margin fee ensures that all contracts are dully honored.'/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FaqSection;
