import React from 'react';
import hero2 from "../../../assets/architectural-design-architecture-buildings-936722.jpg";
import HomeNavigation from "../../../components/HomeNavigation";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";

const HowToInvest = () => {
    useScrollToTop();
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`}}>

                <div className=" width35 text-center mg2">
                    <h1 className="h2 light_color">How to Invest</h1>
                    <p className="h5 grey-text-alt mgT2">Investing can be a great way to help grow your money. Because interest rates are still at historic lows, money parked in a savings account probably won’t grow much over time.</p>

                </div>
            </header>

            <div className="mgT8 " >
                <div className="container ">
                    <h5 className="h3 width50">When should I start investing?</h5>
                    <div className="grid">
                        <div className="grid-item2_4">

                            <p className="h5 grey-text mgT4">If you invest now, you’ll have a better chance to realizing a return on your investment. According to the Social Security Administration, Social Security benefits will only cover about 33% of the cost of the average American’s retirement. The rest will have to be filled in by personal savings and return on investments.

                               </p>
                            <p className="h5 grey-text mgT4"> There’s more to invest for than retirement. Investing can also help you buy a home, travel, start a dream project or even pay your bills in the future. If you invest in the stock market, you’ll have a better chance of watching your investment grow over the long term. And if you invest in bonds, you can benefit from a steady stream of income.</p>
                        </div>

                        <div className="grid-item2_4">

                            <p className="h5 grey-text mgT4">Investing is a long-term venture. Short-term profits are elusive – and often illusory. The longer investment horizon you’re willing to cultivate, the better chance you will have to realize extended annualized returns on your investments.

                                </p>
                            <p className="h5 grey-text mgT4">When you’re at different stages of your life, you will likely have different investment goals. When you’re young and have most of your earnings years ahead, you may want to build up capital to safeguard your future. Later, if you get married and have children, you may prioritize supporting your family as well as planning for your children’s college educations. As you get older, you’ll likely focus on financing your retirement. When mapping out your investment plans, consider which primary goals you want to focus on at your current age.</p>
                        </div>
                    </div>



                </div>
            </div>
            <div className="mgT8 " style={{backgroundColor:'#f7f7f7'}} >
                <div className="container ">

                    <div className="grid">
                        <div className="grid-item2_4">
                            <h5 className="h3 ">How do I build an investment strategy?</h5>
                            <p className="h5 grey-text mgT4">Just as you can’t build a house without a blueprint, you should formulate a strategy before you start investing. First, set aside some money to invest in your future. Begin investing now and educate yourself so you can take the calculated risks necessary to get a desirable return on your investment.</p>
                        </div>

                        <div className="grid-item2_4">

                            <h6 className="h5 green-text mgT4">Here are some questions to consider:</h6>
                            <ul className="mgT4">
                                <li className="h5 grey-text">
                                    How much money am I willing to invest?
                                </li>
                                <li className="h5 grey-text">
                                    What kinds of investment vehicles would work best for me?
                                </li>
                                <li className="h5 grey-text"> What kind of asset allocation should I choose? How should I balance my investments and protect against risk?
                                </li>

                                <li className="h5 grey-text"> What sectors are the most attractive right now?
                                </li>
                                <li className="h5 grey-text">
                                    Am I particularly interested in investing in a specific geographic area?
                                </li>
                                <li className="h5 grey-text">
                                    Am I interested in socially responsible investing?
                                </li>
                                <li className="h5 grey-text">
                                    What’s my risk tolerance? If I incur losses, when I should change direction?
                                </li>

                            </ul>
                        </div>
                    </div>



                </div>
            </div>
            <div className="mgT8">
                <div className="container">
                    <h5 className="h3">What are some popular investment options?</h5>
                    <div className="grid mgT4">
                        <div className="grid-item2_4 ">
                            <p className="h5 grey-text">
                                Popular investment options today include stocks, bonds, mutual funds and ETFs, which are all registered with the U.S. Securities and Exchange Commission (SEC), Johannesburg stock Exchange (JSE) and London stock exchange (LSE).
                            </p>
                            <ul className="mgT4">
                                <li className="grey-text h5">
                                    Stocks are shares in the ownership of a company. Also known as equities, they’re heavily regulated by the government and most can be bought and sold on stock exchanges.
                                </li>
                                <li className="grey-text h5">
                                    Bonds are based on debt, and they’re created when an investor loans money to a company or governmental entity to finance projects and operations. They’re known as fixed incomeinstruments because typically they pay out a regular (fixed) amount (income) to investors.
                                </li>
                            </ul>
                        </div>
                        <div className="grid-item2_4">
                            <ul className="">
                                <li className="grey-text h5">
                                    Mutual funds are investment funds that take money from many investors and put it into stocks, bonds, money-market funds or other securities or assets. When you buy a mutual fund share, you own a piece of the fund’s investment portfolio.
                                </li>
                                <li className="grey-text h5">
                                    Exchange traded funds (ETFs), like mutual funds, are invested in stocks, bonds, money-market funds or other securities or assets, but investors don’t own direct shares of these funds. Only authorized participants (financial institutions who double as broker-dealers) own direct shares of these investment funds. But these authorized participants in turn offer shares to investors that can be bought on a stock exchange.
                                </li>
                            </ul>
                            <p className="grey-text mgT4 h5">
                                To learn about the net asset and issuance flows of mutual funds and ETFs, visit the Investment Company Institute.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mgT8 " style={{backgroundColor:'#f7f7f7'}}>
                <div className="container grid">

                    <div className="grid-item1_3">
                        <h5 className="h4 ">How much do I need to start investing?</h5>
                        <p className="h5 mgT4 grey-text">You can invest in an ETF for less than $100, while mutual funds often ask you to invest at least $1,000. A share of stock can range in price from a few dollars to several thousand dollars. Mutual funds and ETFs can be wise long-term investments; since they both invest in many companies, risk is spread out and you’re exposed to a wider range of asset allocation.</p>
                    </div>

                    <div className="grid-item1_3">

                        <h5 className="h4 ">How to invest with G7 Capital</h5>
                        <p className="h5 mgT4 grey-text">At G7 Capital, we partner with financial professionals across the World to ensure they have the proper tools and materials to assist you in building a financial plan and investment portfolio suited to your goals. We strongly encourage you to work with a financial professional.</p>
                    </div>
                    <div className="grid-item1_3">

                        <h5 className="h4 ">How to open a G7 Capital account</h5>
                        <ul className="mgT4">
                            <li className="grey-text h5">
                                Select the appropriate application to open your G7 Capital account, such as opening an investment account. If you don’t see the form or application you need, please contact us.
                            </li>
                            <li className="grey-text h5">
                                Complete your application, review it with your financial professional,
                                Make a deposit using a preferred deposit method by your financial professional
                                Once we establish your account, we’ll send you a confirmation statement detailing your account number and confirming your investments with us.
                            </li>
                        </ul>
                    </div>


                </div>
            </div>
            <div className="mgT8">
                <div className="container">
                    <h5 className="h3 width50">How to consider investing decisions</h5>
                    <div className="grid mgT4">
                        <div className="grid-item2_4 ">
                            <p className="h5 grey-text">
                                When you’re considering your options for investing with Brexits, either through a financial professional or direct access, it’s important to consider the benefits associated with each option. Review this investing checklist before you make your final decision:
                            </p>
                            <ul className="mgT4">
                                <li className="grey-text h5">
                                    Do I have an investment strategy?
                                </li>
                                <li className="grey-text h5">
                                    Do I have an investment budget?
                                </li>
                            </ul>
                        </div>
                        <div className="grid-item2_4">
                            <ul className="">
                                <li className="grey-text h5">
                                    Have I weighed my available options? Have I considered the full range of investment solutions?
                                    Do I understand both the risks and rewards associated with a particular investment?
                                    What’s my risk tolerance? How much am I willing to lose?
                                </li>
                                <li className="grey-text h5">
                                    Have I read the prospectuses of the products I’m interested in investing in, so I understand all of the ins and outs?
                                </li>
                                <li className="grey-text h5">
                                    Have I discussed any ideas or concerns I have about investing or about a certain product with an investment professional? Did I take notes during the meeting for future reference?
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default HowToInvest;