import {useEffect, useState} from "react";

export function CountDown({time}) {
    const [currentTime,setCurrentTime] = useState("")
    useEffect(()=>{
        var x = setInterval(()=>{
            var countDownDate = new Date(time).getTime()
            var now = new Date().getTime();
            var distance = countDownDate - now
            var days = Math.floor(distance/(1000*60*60*24));
            var hours = Math.floor((distance%(1000*60*60*24))/(1000 * 60 * 60));
            var minutes = Math.floor((distance%(1000 * 60 * 60))/(1000 * 60))
            var secs = Math.floor((distance%(1000 * 60 ))/(1000))

            if(distance<0){
                clearInterval(x);
            }else{
                setCurrentTime(` ${days}d:  ${hours}h:  ${minutes}m:  ${secs}s`)
            }
        },1000)

        return ()=>clearInterval(x)
    },[time])
    return currentTime



}