import React, {useState} from 'react';
import Modal from "../../../components/Modal";

import axiosInstance from "../../../helpers/axiosInstance";
import {AuthContext} from "../../../hoc/AuthContext";
import {toast} from "react-toastify";
import Loader from "../../../components/loader";
import Moment from "react-moment";
import {Link} from "react-router-dom";


const UserCard = ({data,list,setList,id,currentPage,deleteUser,suspendUser}) => {
    const userInfo = React.useContext(AuthContext);
    const [viewModal,setViewModal] = React.useState(false);
    const [viewDeleteModal,setViewDeleteModal] = React.useState(false);
    const [email,setEmail] = React.useState(data.email|| " ")
    const [phone,setPhone] = React.useState(data.phone|| " ")
    const [address,setAddress] = React.useState(data.address|| " ")
    const [state_dis,setState_dis] = React.useState(data.state || "")
    const [loading,setLoading] = useState(false);
    const [status,setStatus] = useState(data.status !== "pending")
    function editUser(e) {

        e.preventDefault();
        const formData ={
            email,
            phone,
            address,
            state:state_dis
        }
        setLoading(true)
        axiosInstance
            .post(`/sup/acc/update/user/${data._id}`,{
           ...formData},{
            headers:{
                authorization: userInfo.token
            }
        })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setTimeout(()=>{
                    setViewModal(false)
                },1000)
            })
            .catch(err=>{
                setLoading(false)
                toast.success(err.response.data.msg);
            })
    }
    function verify() {
        axiosInstance
            .post(`/sup/acc/verify/acc/${data._id}`,{},{
                headers:{
                    authorization: userInfo.token
                }
            })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                if (res.data.status==="pending"){
                    setStatus(false)
                }else{
                    setStatus(true)
                }
            })
            .catch(err=>{
                setLoading(false)
                toast.success(err.response.data.msg);
            })
    }

    return (
            <tr>
                <td>
                    {viewModal?
                    <Modal closeModal={setViewModal}>
                        {loading?<Loader/>:null}
                    <h3 className="h4">Edit User</h3>
                    <form onSubmit={editUser} className="form profile_form fullWidth flex_1">
                        <div className="form-input-box">
                            <input value={email} onChange={(e)=>setEmail(e.target.value)} placeholder={'email'}/>
                        </div>
                        <div className="form-input-box">
                            <input value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder={'Phone'}/>
                        </div>
                        <div className="form-input-box">
                            <input value={address} onChange={(e)=>setAddress(e.target.value)}  placeholder={'address'}/>
                        </div>
                        <div className="form-input-box">
                            <input value={state_dis?state_dis:""} onChange={(e)=>setState_dis(e.target.value)}   placeholder={'State'}/>
                        </div>

                        <button className="btn btn-green mgT2">Save</button>
                    </form>
                </Modal>:null
            }{
                viewDeleteModal?
                    <Modal closeModal={setViewDeleteModal}>
                   <h3 className="h4">Are You sure you want to delete?</h3>
                    <p className='h5 mgT1'>Once you delete it cannot be recovered</p>
                         <button onClick={()=>deleteUser( data,setViewDeleteModal)} className='btn btn-red mgT1'>Delete User</button>
                </Modal>:null
            }
            </td>
              <td className="h6">{(currentPage * 4) + id + 1}
              </td><td className="h6">{data?.email}</td>
              <td className="h6">{data?.firstName}</td>
              <td className="h6">{data?.lastName}</td>
              <td className="h6"><Moment format={'MM DD YYYY'}>{data?.createdAt}</Moment></td>
              <td className="h6">{data?.wallet?.address}</td>
              <td className="h6">{data?.address}</td>
                {
                    setList === undefined ? null:

                        (
                            <td className='btn-td'>
                       <span className='btn btn-green' onClick={()=>setViewModal(!viewModal)}>
                           Edit User
                       </span>
                                <span className='btn btn-red mg1' onClick={()=>setViewDeleteModal(!viewDeleteModal)}>
                           Delete
                       </span>
                                <Link className='h6 btn btn-blue' to={`/admin/acc/statement/${data._id}`}>
                           statement
                       </Link>
                                <button className="btn mgL2" onClick={verify}>{status===false?"enable":"disable"}</button>
                            </td>
                        )
                }
        </tr>

    );
};

export default UserCard;

