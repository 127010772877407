import React from 'react';
import hero2 from "../../../assets/crypto.jpg";
import HomeNavigation from "../../../components/HomeNavigation";
import pimg2 from "../../../assets/architectural-design-architecture-buildings-936722.jpg";
import FooterC from "../../../hoc/FooterC";
import InfiniteTestimony from "../../../hoc/InfiniteTestimony";
import useScrollToTop from "../../../hoc/scrollToTop";
import TopBar from "../../../components/topBar";

const OptionsPage = () => {
    useScrollToTop();
    return (
        <div>
            <TopBar/>
            <HomeNavigation/>
            <header className='altHero' style={{backgroundImage:`linear-gradient(#0007,#0008),url(${hero2})`,height:'50vh'}}>

                <div className=" width35 text-center mg2">
                    <p className="h5"><span className="green">OUR PRODUCTS</span> </p>
                    <h1 className="h2 light_color">G7 <span className="green">Capital</span> Options Trading</h1>


                </div>
            </header>
            <div className="mgT8 container " >
                <div className=" grid">

                    <div className="grid-item2_4">
                        <h5 className="h4 ">Commission-Free Options Trading</h5>
                        <p className="h5 mgT2">Level up with options trading. Intuitive design, no commission fees, and no per-contract fees.

                        </p>

                    </div>
                </div>
                <hr className={'hr mgV2'}/>
            </div>
            <div className=" " >
                <div className="container grid">

                    <div className="grid-item2_4">
                        <h5 className="h4 ">Choose Your Own Venture</h5>
                        <p className="h5 mgT4">With options, you can be bullish on investments you believe in and bearish on the ones you don’t. It’s your call.</p>

                        <hr className={'hr mgV2'}/>
                        <h5 className="h4 ">Develop Your Edge</h5>
                        <p className="h5 mgT4">Advanced traders can execute more sophisticated strategies like iron condors, straddles, strangles, and more.</p>

                    </div>
                    <div className="grid-item2_4">
                        <img src={pimg2} className={'fluid_img'} alt={''}/>
                    </div>


                </div>
            </div>

            <FooterC/>
            <InfiniteTestimony/>
        </div>
    );
};

export default OptionsPage;